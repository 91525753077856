.del-pdp__faqs {
  margin: 0 auto;

  @include bp('del-breakpoint-s') {
    padding: 0 40px;
  }

  @include bp('del-breakpoint-m') {
    padding: 0 70px;
  }

  @include bp('del-breakpoint-l') {
    padding: 0;
    max-width: 920px;
  }


  &__title {
    @include del-font-medium;
    font-size: REM(20px);
    line-height: 21px;
    letter-spacing: 2px;
    margin-bottom: 25px;
    text-align: center;
    color: $del-color-text;
    @include bp('del-breakpoint-l') {
      font-size: REM(24px);
      line-height: 28px;
      letter-spacing: 5px;
      margin-bottom: 40px;
    }
  }

  &__accordion {
    background-color: $del-color-white-smoke;
    border-bottom: 2px solid $del-color-white;

    &:last-child {
      border-bottom: 0;
    }

    &__title {
      @include del-font-book;
      font-size: REM(16px);
      line-height: 18px;
      color: $del-color-black;
      width: calc(100% - 14px);
    }

    &__plus {
      width: 14px;
      margin-left: 10px;
    }

    &__content {
      padding: 0 28px 20px 28px;
    }

    &__content,
    &__content p {
      @include del-font-book;
      font-size: REM(16px);
      line-height: 22px;
      color: $del-color-black;
    }
  }

  &__cta-wrapper {
    text-align: center;
    padding-top: 20px;
  }

  &__cta {
    @include del-button;
    @include del-button--mid;
    @include del-button--secondary;
    min-width: 166px;
    font-size: REM(18px)!important;
  }
}

// simple-css-accordion customization
.del-pdp__faqs {
  .del-simple-css-accordion {
    &__title {
      background-color: $del-color-white-smoke;
      padding: 32px 28px;
    }
  }
}
