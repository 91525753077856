$wImg: 78px;
$wTitleDesktop: 200px;
$wPriceDesktop: 150px;
$wCtaDesktop: 170px;

.del-pdp__sticky {
  position: fixed;
  top: 0;
  transform: translateY(-100px);
  transition: transform .3s ease-in-out;

  &.--active {
    transform: translateY(61px);

    @include bp('del-breakpoint-m') {
      transform: translateY(93px);
    }
  }

  width: 100%;
  max-width: 1920px;
  background-color: $del-color-white;
  z-index: 3;

  border-bottom: 1px solid $del-color-grey;

  @include bp('del-breakpoint-m') {
    box-shadow: 0 4px 2px -2px $del-color-grey;
    border-bottom: 0;
  }

  &__wrapper {
    max-width: 1400px;
    margin: 0 auto;
    width: 100%;
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(2, 50%);
    @include bp('del-breakpoint-m') {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  &__image {
    display: none;
    @include bp('del-breakpoint-m') {
      order: 1;
      display: block;
      width: $wImg;
      height: $wImg;
    }
  }

  &__info{
    order: 2;
    width: $wPriceDesktop;
    display: flex;
    flex-direction: column;
    row-gap: 3px;
    height: auto;
  }

  &__title{
    font-size: 1rem;
    line-height: 19px;
    width: 100%;
    height: auto;
    overflow: hidden;
    max-height: 38px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /*! autoprefixer: off */
    -webkit-box-orient: vertical;
    /*! autoprefixer: on */
  }

  &__title,
  &__code,
  &__toggle {
    order: 2;
    @include del-font-medium;
    font-size: 1.25rem;
    color: $del-color-text;
    width: 50%;
    padding: 0 7px;
    @include bp('del-breakpoint-m') {
      font-size: 1.313rem;
      line-height: REM(24px);
      max-height: 48px;
      width: $wTitleDesktop;
    }
  }

  &__title,
  &__code{
    width: 100%;
  }

  &__code{
    font-size: 0.75rem;
    line-height: 13px;
    color: var(--brand-07);
    @include bp('del-breakpoint-m') {
      font-size: REM(14px);
      line-height: REM(16px);
    }
  }

  &__toggle {
    position: relative;
    font-size: 1rem;
    text-align: right;
    background: none;
    border: none;
    order: 3;
    padding-right: 30px;
    display: none;
    @include bp('del-breakpoint-m') {
      display: none;
    }

    &::after {
      content: '';
      background-image: url("#{$pathAssets}/images/delonghiIcons/select-down.svg");
      background-repeat: no-repeat;
      width: 21px;
      height: 15px;
      position: absolute;
      right: 0;
    }

    &.--open {
      &::after {
        background-image: url("#{$pathAssets}/images/delonghiIcons/select-up.svg");
      }
    }
  }

  &__sections {
    display: none;
    flex-direction: column;
    order: 4;
    height: 0;
    width: 100%;
    overflow: hidden;

    @include bp('del-breakpoint-m') {
      display: flex;
      order: 3;
      flex-direction: row;
      height: unset;
      width: calc(100% - #{$wImg} - #{$wTitleDesktop} - #{$wPriceDesktop} - #{$wCtaDesktop});
      padding: 0 5px;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      overflow: unset;

      &.--wide {
        width: calc(100% - #{$wImg} - #{$wTitleDesktop});
      }

    }

    &.--open {
      height: unset;
      overflow: unset;
      margin-top: 20px;
      align-items: flex-start;

      @include bp('del-breakpoint-m') {
        margin-top: 0;
        align-items: center;
      }
    }
  }

  &__link {
    @include del-font-medium;
    color: $del-color-text-60;
    border-bottom: 1px solid transparent;
    margin: 7px;
    font-size: 1rem;
    text-decoration: none;
    &.--active {
      color: $del-color-text;
      border-bottom: 1px solid $del-color-text;
    }

    @include bp('del-breakpoint-m') {
      font-size: 1.125rem;
      margin: 5px 7px;
    }

  }

  &__price {
    order: 5;
    @include del-font-book;
    color: $del-color-text;
    font-size: 0.75rem;
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    padding: 0 7px;
    width: $wPriceDesktop;
    text-align: right;
    line-height: 1.5rem;
    margin-top: 12;

    @include bp('del-breakpoint-m') {
      order: 4;
      font-size: 1.25rem;
      line-height: 1.5rem;
      display: block;
      margin: 3px 16px 0 0;
    }

    &--strike {
      display: block;
      font-size: 0.75rem;
      line-height: 1.5rem;
      color: $del-color-text-60;
      text-decoration: line-through;

      @include bp('del-breakpoint-m') {
        font-size: 0.875rem;
        line-height: 1rem;
      }
    }
  }

  &__confirmButton{
    order: 4;
    width: 100%;
    justify-content: flex-end;
    display: flex;
    grid-row: span 2;
    align-content: center;
    flex-wrap: wrap;
    @include bp('del-breakpoint-m') {
      order: 5;
      display: block;
      width: $wCtaDesktop;
    }
  }

  &__cta {
    order: 5;
    width: $wCtaDesktop;
    display: block;
    @include bp('del-breakpoint-m') {
      display: block;
    }
  }

}


// specific position when header is hidden
.del-header--hidden .del-pdp__sticky.--active {
  @include bp('del-breakpoint-m') {
    transform: translateY(0px);
  }
}
