/* Fonts mixins */
@mixin del-font-light {
  font-family: var(--fontNameRoman);
  font-weight: 300;
}

@mixin del-font-light-italic {
  font-family: var(--fontNameRoman);
  font-weight: 300;
  font-style: italic;
}

@mixin del-font-book {
  font-family: var(--fontNameRoman);
  font-weight: 400;
}

@mixin del-font-book-italic {
  font-family: var(--fontNameRoman);
  font-weight: 400;
  font-style: italic;
}

@mixin del-font-medium {
  font-family: var(--fontNameRoman);
  font-weight: 500;
}

@mixin del-font-medium-italic {
  font-family: var(--fontNameRoman);
  font-weight: 500;
  font-style: italic;
}

@mixin del-font-demi {
  font-family: var(--fontNameRoman);
  font-weight: 600;
}

@mixin del-font-demi-italic {
  font-family: var(--fontNameRoman);
  font-weight: 600;
  font-style: italic;
}

@mixin del-font-heavy {
  font-family: var(--fontNameRoman);
  font-weight: 700;
}

@mixin del-font-heavy-italic {
  font-family: var(--fontNameRoman);
  font-weight: 700;
  font-style: italic;
}

@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

@function toRem($value) {
  $stripValue: strip-unit($value);
  $remValue: (($stripValue / 16 * 90) / 100) + rem;
  @if $stripValue <= 12 {
    $remValue: ($stripValue / 16) + rem;
  }
  @return $remValue;
}

@function REM($value) {
  $stripValue: strip-unit($value);
  $remValue: ($stripValue / 16) + rem;
  @return $remValue;
}
