@mixin textgroup-subtitle--medium {
  font-size: 0.688rem;
  letter-spacing: 2.24px;
  margin: 3px 0;
}
@mixin textgroup-title--medium {
  @include del-font-medium;
  font-size: 1.75rem;
  letter-spacing: -0.42px;
  line-height: 1.813rem;

  @include bp('del-breakpoint-s') {
    font-size: 2.313rem;
    letter-spacing: -0.555px;
    line-height: 2.313rem;
  }

  @include bp('del-breakpoint-l') {
    font-size: 3.125rem;
    letter-spacing: -0.75px;
    line-height: 3.125rem;
    margin: 6px 0;
  }
}
@mixin textgroup-content--medium {
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin: 5px 0 11px;
  @include bp('del-breakpoint-l') {
    font-size: 1.313rem;
    line-height: 1.625rem;
    margin: 7px 0 14px;
  }
}

.textgroup-subtitle--medium {
  @include textgroup-subtitle--medium;
}

.textgroup-title--medium {
  @include textgroup-title--medium;
}

.textgroup-content--medium {
  @include textgroup-content--medium;
}

@mixin textgroup--medium {
  .del-textgroup {
    &__subtitle {
      @extend .textgroup-subtitle--medium;
      p {
        @extend .textgroup-subtitle--medium;
      }
    }

    &__title {
      @extend .textgroup-title--medium;
      p {
        @extend .textgroup-title--medium;
      }
    }

    &__content {
      @extend .textgroup-content--medium;
      p {
        @extend .textgroup-content--medium;
      }
    }
  }
}
