.del-icon {
  display: inline-block;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;

  &.icon-account {
    background-image: url("#{$pathAssets}/images/delonghiIcons/account.svg");
  }

  &.icon-logout {
    background-image: url("#{$pathAssets}/images/delonghiIcons/logout.svg");
  }

  &.icon-cart {
    background-image: url("#{$pathAssets}/images/delonghiIcons/cart.svg");
  }

  &.icon-menu {
    background-image: url("#{$pathAssets}/images/delonghiIcons/menu.svg");
  }

  &.icon-menu-close {
    background-image: url("#{$pathAssets}/images/delonghiIcons/menu-close.svg");
  }

  &.icon-menu-close-w {
    background-image: url("#{$pathAssets}/images/delonghiIcons/menu-close-w.svg");
  }

  &.icon-menu-close-grey {
    background-image: url("#{$pathAssets}/images/delonghiIcons/menu-close-grey.svg");
  }

  &.icon-register-product {
    background-image: url("#{$pathAssets}/images/delonghiIcons/register-product.svg");
  }

  &.icon-search {
    background-image: url("#{$pathAssets}/images/delonghiIcons/search.svg");
  }

  &.icon-wishlist {
    background-image: url("#{$pathAssets}/images/delonghiIcons/wishlist.svg");
  }

  &.icon-wishlist-active {
    background-image: url("#{$pathAssets}/images/delonghiIcons/wishlist-active.svg");
  }

  &.icon-guarantee {
    background-image: url("#{$pathAssets}/images/delonghiIcons/guarantee.svg");
  }

  &.icon-returns {
    background-image: url("#{$pathAssets}/images/delonghiIcons/returns.svg");
  }

  &.icon-secure {
    background-image: url("#{$pathAssets}/images/delonghiIcons/secure.svg");
  }

  &.icon-shipping {
    background-image: url("#{$pathAssets}/images/delonghiIcons/shipping.svg");
  }

  &.icon-filter {
    background-image: url("#{$pathAssets}/images/delonghiIcons/filter.svg");
  }

  &.icon-toggle-off {
    background-image: url("#{$pathAssets}/images/delonghiIcons/toggle-off.svg");
  }

  &.icon-toggle-on {
    background-image: url("#{$pathAssets}/images/delonghiIcons/toggle-on.svg");
  }

  &.icon-view-big {
    background-image: url("#{$pathAssets}/images/delonghiIcons/view-big.svg");
  }

  &.icon-view-small {
    background-image: url("#{$pathAssets}/images/delonghiIcons/view-small.svg");
  }

  &.icon-star-empty {
    background-image: url("#{$pathAssets}/images/delonghiIcons/star-empty.svg");
  }

  &.icon-star-full {
    background-image: url("#{$pathAssets}/images/delonghiIcons/star-full.svg");
  }

  &.icon-star-half {
    background-image: url("#{$pathAssets}/images/delonghiIcons/star-half.svg");
  }

  &.icon-A {
    background-image: url("#{$pathAssets}/images/delonghiIcons/A.svg");
  }

  &.icon-Aplus {
    background-image: url("#{$pathAssets}/images/delonghiIcons/A+.svg");
  }

  &.icon-A2plus {
    background-image: url("#{$pathAssets}/images/delonghiIcons/A++.svg");
  }

  &.icon-A3plus {
    background-image: url("#{$pathAssets}/images/delonghiIcons/A+++.svg");
  }

  &.icon-B {
    background-image: url("#{$pathAssets}/images/delonghiIcons/B.svg");
  }

  &.icon-C {
    background-image: url("#{$pathAssets}/images/delonghiIcons/C.svg");
  }

  &.icon-D {
    background-image: url("#{$pathAssets}/images/delonghiIcons/D.svg");
  }

  &.icon-calendar {
    background-image: url("#{$pathAssets}/images/delonghiIcons/calendar.svg");
  }

  &.icon-email {
    background-image: url("#{$pathAssets}/images/delonghiIcons/email.svg");
  }

  &.icon-ko {
    background-image: url("#{$pathAssets}/images/delonghiIcons/ko.svg");
  }

  &.icon-live-chat {
    background-image: url("#{$pathAssets}/images/delonghiIcons/live-chat.svg");
  }

  &.icon-ok {
    background-image: url("#{$pathAssets}/images/delonghiIcons/ok.svg");
  }

  &.icon-ok-black {
    background-image: url("#{$pathAssets}/images/delonghiIcons/ok-black.svg");
  }

  &.icon-ok-blue {
    background-image: url("#{$pathAssets}/images/delonghiIcons/ok-blue.svg");
  }

  &.icon-plus {
    background-image: url("#{$pathAssets}/images/delonghiIcons/plus.svg");
  }

  &.icon-plus-circled-w {
    background-image: url("#{$pathAssets}/images/delonghiIcons/plus-circled-w.svg");
  }

  &.icon-plus-circled-full-w {
    background-image: url("#{$pathAssets}/images/delonghiIcons/plus-circled-full-w.svg");
  }

  &.icon-minus {
    background-image: url("#{$pathAssets}/images/delonghiIcons/minus.svg");
  }

  &.icon-position {
    background-image: url("#{$pathAssets}/images/delonghiIcons/position.svg");
  }

  &.icon-pwd-no-visible {
    background-image: url("#{$pathAssets}/images/delonghiIcons/pwd-no-visible.svg");
  }

  &.icon-pwd-visible {
    background-image: url("#{$pathAssets}/images/delonghiIcons/pwd-visible.svg");
  }

  &.icon-select-up {
    background-image: url("#{$pathAssets}/images/delonghiIcons/select-up.svg");
  }

  &.icon-select-up-w {
    background-image: url("#{$pathAssets}/images/delonghiIcons/select-up-w.svg");
  }

  &.icon-select-up-grey {
    background-image: url("#{$pathAssets}/images/delonghiIcons/select-up-grey.svg");
  }

  &.icon-select-right-w {
    background-image: url("#{$pathAssets}/images/delonghiIcons/select-right-w.svg");
  }

  &.icon-select-down {
    background-image: url("#{$pathAssets}/images/delonghiIcons/select-down.svg");
  }

  &.icon-size {
    background-image: url("#{$pathAssets}/images/delonghiIcons/size.svg");
  }

  &.icon-assistance {
    background-image: url("#{$pathAssets}/images/delonghiIcons/assistance.svg");
  }

  &.icon-assistance-grey {
    background-image: url("#{$pathAssets}/images/delonghiIcons/assistance-grey.svg");
  }

  &.icon-faq {
    background-image: url("#{$pathAssets}/images/delonghiIcons/faq.svg");
  }

  &.icon-faq-grey {
    background-image: url("#{$pathAssets}/images/delonghiIcons/faq-grey.svg");
  }

  &.icon-help {
    background-image: url("#{$pathAssets}/images/delonghiIcons/help.svg");
  }

  &.icon-manuals {
    background-image: url("#{$pathAssets}/images/delonghiIcons/manuals.svg");
  }

  &.icon-help-grey {
    background-image: url("#{$pathAssets}/images/delonghiIcons/help-grey.svg");
  }

  &.icon-manuals {
    background-image: url("#{$pathAssets}/images/delonghiIcons/manuals.svg");
  }

  &.icon-manuals-grey {
    background-image: url("#{$pathAssets}/images/delonghiIcons/manuals-grey.svg");
  }

  &.icon-facebook {
    background-image: url("#{$pathAssets}/images/delonghiIcons/facebook.svg");
  }

  &.icon-instagram {
    background-image: url("#{$pathAssets}/images/delonghiIcons/instagram.svg");
  }

  &.icon-twitter {
    background-image: url("#{$pathAssets}/images/delonghiIcons/twitter.svg");
  }

  &.icon-youtube {
    background-image: url("#{$pathAssets}/images/delonghiIcons/youtube.svg");
  }

  &.icon-googleplay {
    background-image: url("#{$pathAssets}/images/delonghiIcons/googleplay.svg");
  }

  &.icon-appstore {
    background-image: url("#{$pathAssets}/images/delonghiIcons/appstore.svg");
  }

  &.icon-arrow-left {
    background-image: url("#{$pathAssets}/images/delonghiIcons/arrow-left.svg");
  }

  &.icon-delonghi {
    background-image: url("#{$pathAssets}/images/logos/logo-delonghi.png");
  }

  &.icon-oval-left-w {
    background-image: url("#{$pathAssets}/images/delonghiIcons/icon-oval-left-w.svg");
  }

  &.icon-oval-right-w {
    background-image: url("#{$pathAssets}/images/delonghiIcons/icon-oval-right-w.svg");
  }

  &.icon-download-pdf {
    background-image: url("#{$pathAssets}/images/delonghiIcons/download-pdf.svg");
  }

  &.icon-chevron-left-grey {
    background-image: url("#{$pathAssets}/images/delonghiIcons/chevron-left-grey.svg");
  }

  &.icon-chevron-right-grey {
    background-image: url("#{$pathAssets}/images/delonghiIcons/chevron-right-grey.svg");
  }
}
