.del-keyfeatures-tile {
  display: flex;
  flex-direction: column;
  gap: 23px;

  &__image {
    max-width: 100%;
    height: auto;
    aspect-ratio: 304 / 214;
    object-fit: cover;

    @include bp('del-breakpoint-s') {
      aspect-ratio: 284 / 214;
    }

    @include bp('del-breakpoint-xl') {
      aspect-ratio: 306 / 230;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 1;
    padding-inline: 0
  }

  &__title {
    @include del-font-medium;
    font-size: REM(24px);
    line-height: REM(28px);
    color: var(--brand-06);

    @include bp('del-breakpoint-s') {
      font-size: REM(26px);
    }
  }

  &__description {
    @include del-font-book;
    font-size: REM(16px);
    line-height: REM(19px);
    color: var(--brand-06);

    @include bp('del-breakpoint-m') {
      font-size: REM(18px);
    }
  }

  .del-button--primary-darkBlue {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    margin-inline: 7px;
    min-height: 48px;
    border-radius: 5px;
    @include del-font-heavy;
    font-size: REM(18px);
    line-height: REM(28px);
    @include bp('del-breakpoint-s') {
      align-self: flex-start;
      padding-inline: 47px;
    }

    @include bp('del-breakpoint-xl') {
      margin-inline: 12px;
    }
  }

  .del-button--arrow-left--darkBlue {
    text-align: left;
    @include del-font-medium;
    font-size: REM(18px);
    &::before {
      margin-right: 5px;
    }
  }
}
