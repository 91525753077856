
.del-header {
  .del-header {
    &__logo {
      flex: 0 0 auto;
      width: 128px;
      height: 41px;

      @include bp('del-breakpoint-l') {
        width: 169px;
        height: 54px;
      }

      a,
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
}
