.del-pdp__specifications {
  &__wrapper {
    background-color: $del-color-light-grey;
    padding: 40px;

    @include bp('del-breakpoint-m') {
      padding: 40px 70px;
    }

    @include bp('del-breakpoint-l') {
      padding: 80px 70px;
    }
  }

  &__title {
    @include del-font-medium;
    font-size: REM(28px);
    line-height: 21px;
    letter-spacing: 0;
    margin-bottom: 25px;
    text-align: center;
    color: $del-color-text;

    @include bp('del-breakpoint-l') {
      font-size: REM(37px);
      line-height: 37px;
      margin-bottom: 40px;
    }
  }

  &__accordion {
    &__title {
      @include del-font-book;
      font-size: REM(20px);
      color: $del-color-pure-black;
    }
  }

  &__single {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $del-color-grey;
    padding: 14px 0;

    &:last-child {
      border-bottom: 0;
    }

    &__label,
    &__value {
      @include del-font-medium;
      font-size: REM(15px);
      color: $del-color-black;
      padding-right: 10px;
    }

    &__value {
      @include del-font-book;
      padding-right: 0;
      padding-left: 10px;
      display: flex;
      align-items: center;
      text-align: right;

      .icon-ok-blue {
        width: 20px;
        height: 20px;
      }

      .del-pdp-spec-class {
        width: 62px;
        height: 28px;
      }
    }
  }

  &__links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 30px;
    padding: 0 16px;

    @include bp('del-breakpoint-s') {
      flex-direction: row;
    }

    @include bp('del-breakpoint-m') {
      width: 75%;
    }

    @include bp('del-breakpoint-l') {
      width: 60%;
    }

    a {
      @include del-font-medium;
      font-size: REM(20px);
      line-height: normal;
      color: $del-color-bright-blue;
      display: inline-block;
      margin-bottom: 20px;
      &:hover {
        opacity: 0.8;
      }
    }

    &__faq,
    &__register {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    &__faq {
      border-bottom: 1px solid $del-color-grey;
      margin-bottom: 20px;

      @include bp('del-breakpoint-s') {
        border-bottom: 0;
        border-right: 1px solid $del-color-grey;
        margin-bottom: 0;
      }

      i {
        width: 84px;
        height: 84px;
        display: block;
      }
    }

    &__register {
      &__tip {
        display: flex;
        width: 50%;
        margin-bottom: 5px;
        margin-left: 35px;

        &:last-child {
          margin-bottom: 0;
        }

        i {
          width: 20px;
          height: 15px;
          margin-right: 10px;
        }

        span {
          @include del-font-book;
          font-size: REM(16px);
          color: $del-color-black;
        }
      }
    }
  }
}

// simple-css-accordion customization
.del-pdp__specifications {
  .del-simple-css-accordion {
    border-bottom: 1px solid $del-color-grey;

    &:last-child {
      border-bottom: 0;
    }

    &__title {
      background-color: $del-color-light-grey;
      padding: 21px 23px 21px 12px;

      &::marker {
        content: '';
      }

      &::-webkit-details-marker {
        display: none;
      }

      &.dln-outline:focus {
        outline: none;

        i {
          outline: 3px solid $del-color-green;
        }
      }
    }

    &__content-desktop {
      padding: 10px 12px 20px;
      @include bp('del-breakpoint-s') {
        padding-left: 40px;
        padding-right: 40px;
      }
    }

    &__plus-desktop {
      width: 18px;
      height: 18px;
      &::before {
        width: 18px;
        top: 8px;
      }
      &::after {
        height: 18px;
        left: 8px;
      }
    }
  }
}
