.del-breadcrumb {
  width: 100%;
  margin: 0 auto;
  padding: 10px 15px;
  background-color: transparent;
  color: $del-color-text-60;
  line-height: 24px;

  ul{
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__crumb {
    display: inline-block;

    &:not(:first-child):before {
      display: inline-block;
      content: '';
      height: 26px;
      width: 1px;
      margin: 0 10px -8px;
      border-right: 1px solid $del-color-grey;
      transform: rotate(30deg);

      @include bp('del-breakpoint-s') {
        margin: 0 8px -8px;
      }
    }

    a {
      height: 21px;
      font-size: 1rem;
      font-weight: 400;
      color: $del-color-red;
      letter-spacing: 0;
      line-height: 1.313rem;
      text-decoration: none;
    }
  }
}
