.max-wrapper-size {
  max-width: 328px;
  @include bp('del-breakpoint-s') {
    max-width: 598px;
  }
  @include bp('del-breakpoint-m') {
    max-width: 664px;
  }
  @include bp('del-breakpoint-l') {
    max-width: 1066px;
  }
}

.del-shopTheLook {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1920px;
  margin-block: 75px;

  @include bp('del-breakpoint-l') {
    flex-direction: row;
  }

  &__media-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-inline: auto;
    @extend .max-wrapper-size;

    @include bp('del-breakpoint-l') {
      width: 69.94750656%;
      justify-content: flex-start;
      margin-inline: 0;
    }
  }

  &__picture {
    aspect-ratio: 328/203;
    max-width: 100%;
    height: auto;
    object-fit: cover;
    display: block;

    @include bp('del-breakpoint-s') {
      aspect-ratio: 598/370;
    }

    @include bp('del-breakpoint-m') {
      aspect-ratio: 664/411;
    }

    @include bp('del-breakpoint-l') {
      aspect-ratio: 1066/600;
    }
  }

  &__slider-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include bp('del-breakpoint-l') {
      max-width: 458px;
      margin-inline: 1.5%;
    }

    @include bp('del-breakpoint-xl') {
      margin-inline: 4.0625%; // 78/1920
    }
  }

  &__texts {
    @include textgroup-shared-texts();
    text-align: center;
    margin-top: 20px;
    margin-inline: auto;
    max-width: 323px;

    @include bp('del-breakpoint-l') {
      margin-top: 0;
    }

    .del-textgroup__texts {
      align-items: center;
    }
  }

  &__slider {
    max-width: 348px;
    margin-inline: auto;

    @include bp('del-breakpoint-s') {
      max-width: 319px;
    }

    @include bp('del-breakpoint-l') {
      max-width: 100%;
    }
  }

  .swiper-slide {
    width: 100%;
  }

  .swiper-container-initialized .swiper-button {
    visibility: visible;
  }

  .swiper-button {
    visibility: hidden;
    position: absolute;
    width: 40px;
    height: 40px;
    background-color: $del-color-white;
    border: 1px solid $del-color-text-60;
    border-radius: 50%;

    &-prev {
      left: 0;
    }

    &-next {
      right: 0;
    }
  }

  &__product-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 234px;
    margin-inline: auto;

    @include bp('del-breakpoint-l') {
      max-width: 254px;
    }

    img {
      display: block;
      max-width: 100%;
      height: auto;
      object-fit: cover;

      @include bp('del-breakpoint-s') {
        max-width: 200px;
        margin-inline: auto;
      }

      @include bp('del-breakpoint-l') {
        max-width: 100%;
      }
    }

    &__info {
      display: flex;
      flex-direction: column;
      text-align: center;
      gap: 11px;
      margin-top: 24px;
    }

    &__category {
      @include del-font-medium;
      font-size: REM(14px);
      line-height: REM(14px);
      color: $del-color-text-60;
    }

    &__name {
      @include del-font-medium;
      font-size: REM(19px);
      line-height: REM(19px);
      color: $del-color-text;
    }

    &__price {
      .--list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        list-style: none;
        gap: 4px;
        font-size: REM(18px);
        @include del-font-book;
        color: $del-color-text;

        .--mainPrice {
          width: 100%;
          padding-bottom: 5px;
        }

        .--strikePrice,
        .--percentageDiscount,
        .--labelPrice {
          font-size: REM(12px);
          color: $del-color-text-60;
          line-height: 10px;
        }

        .--strikePrice {
          text-decoration: line-through;
        }

        .--percentageDiscount::before {
          content: '(';
        }

        .--percentageDiscount::after {
          content: ')';
        }
      }
    }
  }
}
