.del-pdp__similar {
  padding: 50px 0 20px;

  &__title {
    @include del-font-medium;
    font-size: REM(28px);
    line-height: 28px;
    letter-spacing: 0;
    margin-bottom: 10px;
    text-align: center;
    @include bp('del-breakpoint-l') {
      font-size: REM(37px);
      line-height: 37px;
    }
  }

  .del-pdp__similar__swiper-container {
    padding: 50px 10px;
  }

  .swiper-button-disabled {
    display: none;
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 40px;
    height: 40px;
    font-size: 1.25rem;
    border: 1px solid $del-color-black;
    border-radius: 50%;

    &::after {
      top: 50%;
      left: 50%;
      position: absolute;
      transform: translate(-30%, -44%);
    }
  }

  .swiper-button-prev {
    &::after {
      transform: translate(-50%, -44%);
    }
  }

  .del-product-box--grid-view {
    width: 100%;
    max-width: 100%;
  }

}
