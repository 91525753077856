.del-titlebox {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding-bottom: 20px;
	color: $del-color-text;

	&.--light {
		color: $del-color-white;
	}

	@include bp('del-breakpoint-m') {
		max-width: 50%;
		margin: 0 auto;
	}

	&__title {
		width: 100%;
		margin: 8px 0;
		@include del-font-medium;
		font-size: 2.313rem;
		letter-spacing: -0.7px;
		line-height: 2.313rem;
		text-align: center;
	}

	&__description {
		width: 100%;
		margin: 8px 0;
		@include del-font-light;
		font-size: 1.125rem;
		letter-spacing: 0;
		line-height: 1.5rem;
		text-align: center;
	}
}
