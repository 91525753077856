@mixin textgroup-title--divider {
  @include del-font-medium;
  font-size: 1rem;
  letter-spacing: 3.2px;
  line-height: 1.4rem;

  @include bp('del-breakpoint-s') {
    font-size: 1.313rem;
    letter-spacing: 6.3px;
    line-height: 1.838rem;
  }

  @include bp('del-breakpoint-l') {
    font-size: 1.5rem;
    letter-spacing: 9.6px;
    line-height: 2.1rem;
  }
}

.textgroup-title--divider {
  @include textgroup-title--divider;
}

@mixin textgroup--divider {
  .del-textgroup {
    &__title {
      @extend .textgroup-title--divider;
      p {
        @extend .textgroup-title--divider;
      }
    }
  }
}
