@media only screen and (min-width: $braun-s) and (max-width: $braun-m - 1) {
  .del-gallery-spacecontainer {
    display: block;

    .bra-gallery {
      &__swiperWrapper {
        width: 100%;

        .swiper-slide {
          // max-width: calc(100vw - 60px);
          max-width: calc(50% - 32.5px) !important;
        }

        .swiper-footer {
          position: relative;

          display: flex;
          align-items: center;
          justify-content: space-around;

          width: calc(100% - 60px) !important;
          margin: 0 auto !important;
          // margin-top: 30px;
          padding-top: 32px;
          position: relative;

          .swiper-button-close {
            display: none;
          }

          .swiper-button-prev {
            order: 1;
          }

          .swiper-scrollbar {
            display: block;
            order: 2;
          }

          .swiper-button-next {
            order: 3;
          }

          .swiper-button-next,
          .swiper-button-prev {
            display: none;
          }

          // .swiper-scrollbar {
          // 	position: relative;
          // 	left: 0;
          // 	bottom: 0;
          // 	height: 3px;
          // 	width: 90%;
          // 	background: $ui-08;
          // 	margin-top: 30px;

          // 	.swiper-scrollbar-drag {
          // 		background: $brand-01;
          // 	}
          // }
        }
      }

      &.bra-gallery--grid-2 {
        .bra-gallery__swiperWrapper {
          /*
          width: calc(100% - 60px);
          */
          width: 100%;
          padding: 0;
          margin: 0 30px;

          .swiper-slide {
            max-width: calc(50% - 2.5px);
          }

          .swiper-footer {
            width: 100%;
            margin: 30px 0 0 0;
          }
        }
      }
    }

  }
}
