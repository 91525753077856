.del-recommendations-carousel {
  &__wrapper {

    .del-recommendations-carousel__swiper-container { /* no & operator in order to avoid specificity issue */
      padding-bottom: 80px;
      @include bp('del-breakpoint-s') {
        max-width: 1000px;
        padding: 0 50px;
      }
    }

    .del-recommendations-carousel__pagination.del-recommendations-carousel__pagination { /* no & operator in order to avoid specificity issue */
      bottom: 35px;
      @include bp('del-breakpoint-s') {
        display: none;
      }
    }

    .swiper-button-prev,
    .swiper-button-next {

      display:none;

      @include bp('del-breakpoint-s') {
        display: block;
      }

      width: 40px;
      height: 40px;
      font-size: 1.25rem;
      border: 1px solid $del-color-black;
      border-radius: 50%;

      &::after {
        top: 50%;
        left: 50%;
        position: absolute;
        transform: translate(-30%, -44%);
      }

      &.swiper-button-disabled {
        display: none;
      }
    }

    .swiper-button-prev {

      &::after {
        transform: translate(-50%, -44%);
      }
    }

  }

  &__title {
    @include del-font-book;
    color: $del-color-black;
    text-align: center;
    padding: 0 0 35px 0;
    font-size: 1.5rem;
    line-height: 1.75rem;
  }

  &__slide {
    text-align: center;
    padding: 0 10px;
    @include bp('del-breakpoint-s') {
      padding: 0 50px;
    }
  }

  &__date,
  &__description {
    @include del-font-book;
    color: $del-color-black;
    padding: 0 15px;
  }

  &__date {
    opacity: .7;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  &__description {
    @include del-font-book;
    font-size: 1.313rem;
    line-height: 1.75rem;
    margin: 10px 0 30px;
  }

  &__image {
    display: block;
    margin: 0 auto;
  }
}
