.del-promoPdp-banner {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;

  @include bp('del-breakpoint-s') {
    flex-direction: row;
    height: 90px;
    overflow-y: hidden;
  }

  &.--open {
    height: auto;
    overflow-y: unset;
    .del-promoPdp-banner__image,
    .del-promoPdp-banner__info__caption,
    .del-promoPdp-banner__info__cta {
      display: block;
    }

    @include bp('del-breakpoint-s') {
      .del-promoPdp-banner__info__caption {
        font-size: 1rem;
        line-height: 1.313rem;
        letter-spacing: 3.56px;
      }

      .del-promoPdp-banner__info__title {
        font-size: 2.313rem;
        line-height: 2.938rem;
      }

      .del-promoPdp-banner__info__subtitle {
        font-size: 1.5rem;
        line-height: 1.938rem;
        margin-bottom: 20px;
      }
    }

    .del-promoPdp-banner__cta {
      transform: rotate(0);
    }
  }

  &__image {
    order: 1;
    display: none;

    @include bp('del-breakpoint-s') {
      order: 0;
      display: block;
    }
  }

  &__info {
    order: 0;
    width: 100%;
    padding: 15px;
    text-align: center;

    @include bp('del-breakpoint-s') {
      order: 1;
      width: 60%;
    }

    &__caption,
    &__title,
    &__subtitle {
      color: $del-color-white;
      font-size: 0.875rem;
      margin-bottom: 10px;
    }

    &__caption {
      @include del-font-medium;
      opacity: .6;
      display: none;
    }

    &__title {
      @include del-font-heavy;
      font-size: 1rem;
      letter-spacing: -0.27px;
      margin-bottom: 5px;

      @include bp('del-breakpoint-s') {
        font-size: 1.5rem;
        line-height: 1.938rem;
        letter-spacing: -0.4px;
      }
    }

    &__subtitle {
      @include del-font-medium;

      @include bp('del-breakpoint-s') {
        font-size: 1rem;
        line-height: 1.313rem;
      }
    }

    &__cta {
      display: none;
      max-width: 200px;
      margin: 0 auto;
    }
  }

  &__cta {
    border: 0;
    position: absolute;
    background: url("#{$pathAssets}/images/delonghiIcons/select-up-w.svg") no-repeat;
    transform: rotate(180deg);
    top: 10px;
    right: 5px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}
