.del-video--mobile {
  display: block!important;
  @include bp('del-breakpoint-s') {
    display: none!important;
  }
}

.del-video--desktop {
  display: none!important;
  @include bp('del-breakpoint-s') {
    display: block!important;
  }
}
