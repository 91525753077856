[class^='del-'] {
  box-sizing: border-box;
  @include del-font-book;

  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }
}

$full-width: 'full-width';
$full-grid: 'full-grid';
$big-grid: 'big-grid';
$grid-external-gap: 10;
$wrapperWidth: (
  $full-width: 1920,
  $full-grid: 1400,
  $big-grid: 1156
) !default;

@each $key, $val in $wrapperWidth {
  .del-wrapper--#{$key}--no-gap {
    max-width: #{$val}px !important;
    margin: 0 auto;
  }
}

@each $key, $val in $wrapperWidth {
  .del-wrapper--#{$key} {
    max-width: #{$val}px!important;
    margin: 0 auto;

    @media (max-width: #{$val + $grid-external-gap}px) {
      padding: 0 #{$grid-external-gap}px;
    }
  }
}

.del-layout-pagewrapper {
  max-width: 1920px;
  margin: 61px auto 0;

  @include bp('del-breakpoint-m') {
    margin-top: 95px;

    &.noGapHeader {
      margin-top: 0;
    }
  }
}

@mixin useEllipsisText($display: block, $lines: 1, $lines-md: null) {
  display: #{$display};
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;

  @if $lines >1 {

    display: -webkit-box;
    -webkit-line-clamp:  $lines;
    /*! autoprefixer: off */
    -webkit-box-orient: vertical;
    /*! autoprefixer: on */
    white-space: normal;

    @if $lines-md {
      @include bp('el-breakpoint-m') {
        -webkit-line-clamp: $lines-md;
      }
    }
  }

  @else {
    white-space: nowrap;
  }
}

@mixin lineClamp($lines: 1) {
  overflow: hidden!important;
  display: -webkit-box!important;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical!important;
  /* autoprefixer: on */
  -webkit-line-clamp: $lines!important;
}