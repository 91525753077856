.del-footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: $del-color-blue;

  @include del-font-medium;
  color: $del-color-white;
  font-size: REM(16px);

  a:link,
  a:visited {
    text-decoration: inherit;
    color: $del-color-white;
  }

  .swiper-container,
  .swiper-wrapper {
    z-index: 0;
  }

  .del-benefit {
    display: flex;
    justify-content: center;
    background-color: $del-color-white-smoke;
    width: 100%;
    padding: 0 15px;
    color: $del-color-text;

    &__wrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;
      max-width: 1400px;
    }

    &__item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
      text-align: center;
      margin: 25px 0 72px;

      @include bp('del-breakpoint-m') {
        width: 33%;
        max-width: 33%;
        margin-bottom: 45px;
      }
    }

    &__img {
      $size: 44px;
      height: $size;
      width: $size;
    }

    &__text {
      @include del-font-medium;
      font-size: REM(18px);
      line-height: 24px;
    }

    &__desc {
      @include del-font-light;
      color: $del-color-text-80;
      font-size: REM(16px);
      line-height: 24px;
    }

    .swiper-pagination {
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: 30px;

      @include bp('del-breakpoint-m') {
        display: none;
      }

      &-bullet {
        $size: 8px;
        width: $size;
        height: $size;
        margin: 0 10px !important;
        background-color: $del-color-text-60;
        opacity: 1;

        &-active {
          $size: 12px;
          width: $size;
          height: $size;
          background-color: $del-color-red;
        }
      }
    }
  }

  .del-newsletter {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: $del-color-icon-blue;
    padding: 0 16px;

    @include bp('del-breakpoint-m') {
      font-size: REM(28px);
      line-height: 35px;
    }

    &__wrapper {
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      margin: 48px auto;

      @include bp('del-breakpoint-s') {
        margin: 54px auto;
      }

      @include bp('del-breakpoint-m') {
        margin: 63px auto;
        width: unset;
      }
    }

    &__slogan {
      display: inline-block;
      width: 100%;
      margin-bottom: 32px;
      padding: 0 31px;
      font-size: REM(24px);
      line-height: normal;

      @include bp('del-breakpoint-s') {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 43px;
        gap: 8px;
      }

      @include bp('del-breakpoint-m') {
        font-size: REM(26px);
        line-height: normal;
        align-items: flex-start;
        width: unset;
        margin: 0;
        margin-right: 50px;
        padding: 0;
      }
    }

    &__textbold {
      @include del-font-heavy;
      text-align: center;
      color: $del-color-dark-blue;
    }

    &__text {
      @include del-font-light;
      text-align: center;
      color: $del-color-dark-blue;
    }

    &__button {
      width: 100%;
      border: none;
      border-radius: 3px;
      background-color: $del-color-red;
      padding: 15px 50px;
      @include del-font-medium;
      color: $del-color-white;
      font-size: REM(21px);
      cursor: pointer;

      @include bp('del-breakpoint-m') {
        width: unset;
      }
    }

    .dln-popup {
      color: $del-color-black;
    }
  }

  .del-usefullinks {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    max-width: 1400px;
    padding: 20px 17px;

    @include bp('del-breakpoint-s') {
      padding-inline: 36px;
    }

    @include bp('del-breakpoint-m') {
      padding-inline: 70px;
    }

    @include bp('del-breakpoint-l') {
      padding: 20px 31px;
    }
  }

  .del-iconlinks {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    &__wrapper {
      display: flex;
      justify-content: center;
      align-items: stretch;
      flex-wrap: wrap;
      width: 100%;
    }

    &__link {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 50%;
      height: 120px;
      padding: 0 20px;
      margin: 20px 0;

      &:nth-of-type(odd) {
        border-right: 1px solid rgba($del-color-white, 0.1);
      }

      @include bp('del-breakpoint-m') {
        width: 25%;
        border-right: 1px solid rgba($del-color-white, 0.1);

        &:last-of-type {
          border: none;
        }
      }
    }

    &__img {
      $size: 48px;
      @include bp('del-breakpoint-m') {
        $size: 56px;
      }
      height: $size;
      width: $size;
      margin-bottom: 10px;
    }

    &__text {
      @include del-font-medium;
      line-height: 19px;
      text-align: center;
      color: $del-color-pale-blue;
      font-size: REM(14px);

      @include bp('del-breakpoint-m') {
        font-size: REM(18px);
        line-height: 22.6px;
      }
    }

    &__hr {
      width: 100%;
      height: 0;
      margin: 16px 0;
      border-top: 1px solid rgba($del-color-white, 0.1);

      &:last-child {
        display: none;
      }

      @include bp('del-breakpoint-m') {
        display: none;
      }
    }
  }

  .del-menues {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: 25px 0;

    @include bp('del-breakpoint-l') {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      border: 1px solid rgba($del-color-white, 0.1);
      border-left: none;
      border-right: none;
    }
  }

  .del-menu {
    position: relative;
    border-top: 1px solid rgba($del-color-white, 0.1);

    &:last-child {
      border-bottom: 1px solid rgba($del-color-white, 0.1);
    }

    @include bp('del-breakpoint-l') {
      width: unset;
      min-width: 200px;
      border: none;

      &:last-child {
        border: none;
      }
    }

    &__wrapper {
      position: relative;
      overflow: hidden;
    }

    &__title {
      position: relative;
      z-index: 2;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      background-color: $del-color-blue;
      padding: 15px 10px;
      text-transform: uppercase;

      @include bp('del-breakpoint-s') {
        padding-inline: 21px;
      }

      @include bp('del-breakpoint-l') {
        padding-inline: 10px;
      }

      &text {
        position: relative;
        z-index: 2;
        @include del-font-medium;
        font-size: REM(15px);
        color: rgba($del-color-white,0.6);
        line-height: 1.57;
        letter-spacing: 2px;

        @include bp('del-breakpoint-l') {
          padding-inline: 21px;
        }

        @include bp('del-breakpoint-l') {
          font-size: REM(13px);
          line-height: 1.82;
          padding-inline: 0;
        }
      }
    }

    &__plus {
      position: relative;
      z-index: 2;
      $size: 14px;
      width: $size;
      height: $size;

      @include bp('del-breakpoint-l') {
        display: none;
      }

      &:before {
        position: absolute;
        content: '';
        width: $size;
        height: 2px;
        background: rgba($del-color-white, 0.6);
        top: ($size / 2) - 1;
        transition: all 300ms ease;
      }

      &:after {
        position: absolute;
        content: '';
        width: 2px;
        height: $size;
        background: rgba($del-color-white, 0.6);
        left: ($size / 2) - 1;
        transition: all 300ms ease;
      }
    }

    &__list {
      position: absolute;
      visibility: hidden;
      z-index: 1;
      padding: 0 10px 15px;
      list-style-type: none;
      transform: translateY(-100px);
      opacity: 0.8;

      @include bp('del-breakpoint-s') {
        padding-inline: 21px;
      }

      @include bp('del-breakpoint-l') {
        position: relative;
        visibility: visible;
        transform: none;
        transition: all 300ms ease;
        padding-inline: 10px;
      }

      & li a {
        @include del-font-medium;
        font-size: REM(16px);
        color: $del-color-white;
        opacity: 0.8;
        line-height: 44.6px;

        @include bp('del-breakpoint-l') {
          font-size: REM(14px);
          line-height: 2.47;
        }
      }
    }

    input[type='checkbox'] {
      display: none;

      &:checked + .del-menu__wrapper .del-menu__list {
        position: relative;
        visibility: visible;
        transform: none;
        transition: all 300ms ease;
      }

      &:checked + .del-menu__wrapper .del-menu__plus:after {
        -ms-transform: rotate(90deg); /* IE 9 */
        -webkit-transform: rotate(90deg); /* Safari prior 9.0 */
        transform: rotate(90deg); /* Standard syntax */
      }
    }
  }

  .del-legal {
    padding: 20px 0;

    &__toprow {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;

      @include bp('del-breakpoint-l') {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
      }
    }

    &__links {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      padding-bottom: 22px;
      border-bottom: 1px solid rgba($del-color-white, 0.1);

      @include bp('del-breakpoint-l') {
        padding: 0;
        border: 0;
      }

      a {
        @include del-font-medium;
        font-size: REM(12px);
        color: $del-color-white;
        opacity: 0.7;
        line-height: 1.67;
      }
    }

    &__pipe {
      width: 20px;
      text-align: center;
    }

    &__copyright {
      display: flex;
      justify-content: center;
      @include del-font-book;
      color: rgba($del-color-white, 0.7);
      font-size: REM(12px);
      line-height: 16px;
      gap: 22px;

      @include bp('del-breakpoint-l') {
        align-items: center;
        justify-content: space-between;
        gap: 10px;
      }
    }

    &__bottomrow {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      margin-top: 37px;
      margin-bottom: 25px;

      @include bp('del-breakpoint-l') {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
      }
    }
  }

  .del-business {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    @include bp('del-breakpoint-l') {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: unset;
    }

    &__logo {
      display: none;
      width: 114px;
      height: 36px;

      @include bp('del-breakpoint-l') {
        display: block;
      }

      a,
      img {
        width: 100%;
        height: auto;
      }
    }

    &__country {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 200px;
      height: 47px;
      margin: 0 20px;
      border: none;
      border-radius: 25px;
      background-color: $del-color-icon-blue;
      padding: 0 5px;

      @include bp('del-breakpoint-l') {
        width: 160px;
        height: 36px;
      }

      &Code {
        $size: 20px;
        width: $size;
        height: $size;
        border-radius: 10px;
        box-shadow: 0 0 1px 1px black inset;
      }

      &Name {
        @include del-font-medium;
        color: $del-color-white;
        line-height: 21px;
        font-size: REM(16px);
        @include bp('del-breakpoint-l') {
          font-size: REM(13px);
        }
      }

      &Arrow {
        $size: 16px;
        width: $size;
        height: $size;
      }
    }
  }

  .del-copyright {
    white-space: nowrap;
    &:nth-child(even) {
      text-align: right;
    }
  }

  .del-social {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
    margin: 42px 0 37px 0;

    @include bp('del-breakpoint-l') {
      width: 280px;
      margin: 0;
      gap: 40px;
    }

    @include bp('del-breakpoint-xl') {
      margin-left: 140px;
    }

    &__img {
      $size: 38px;
      width: $size;
      height: $size;
    }
  }

  .del-apps {
    display: flex;
    gap: 15px;
    margin-inline: auto;

    @include bp('del-breakpoint-l') {
      margin-inline: unset;
    }

    &__img {
      aspect-ratio: 138 / 42;
      max-width: 138px;

      @include bp('del-breakpoint-l') {
        aspect-ratio: 106 / 32;
        max-width: 106px;
      }

      img {
        display: block;
        max-width: 100%;
        height: auto;
      }
    }
  }

  .del-paymethods {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;

    @include bp('del-breakpoint-s') {
      gap: 56px;
    }

    @include bp('del-breakpoint-l') {
      margin: 0;
      gap: 7px;
    }

    &__img {
      width: 56px;
      height: 33px;
    }
  }

  &__light {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1400px;
    padding: 24px 16px;

    @include bp('del-breakpoint-l') {
      justify-content: space-between;
      padding: 30px 16px;
    }

    .del-business__logo {
      display: none;
      width: 114px;
      height: 36px;

      @include bp('del-breakpoint-l') {
        display: block;
      }

      a,
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}
