@media only screen and (max-width: $braun-s - 1) {
  .del-gallery-spacecontainer {
    display: block;

    .bra-gallery__swiperWrapper {
      padding: 0;

      .swiper-slide {
        max-width: calc(100vw - 40px) !important;
      }

      .swiper-footer {
        position: relative;
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: calc(100% - 40px) !important;
        margin: 0 auto !important;

        .swiper-button-close {
          display: none;
        }

        .swiper-button-prev {
          order: 1;
        }

        .swiper-scrollbar {
          display: block;
          order: 2;
          display: block;
        }

        .swiper-button-next {
          order: 3;
        }

        .swiper-button-next,
        .swiper-button-prev {
          display: none
        }

        .swiper-scrollbar {
          position: relative;
          left: 0;
          bottom: 0;
          height: 3px;
          width: 100%;
          background: $ui-08;
          margin-top: 30px;

          .swiper-scrollbar-drag {
            background: $brand-01;
          }
        }
      }
    }
    .bra-gallery--grid-2 {
      .bra-gallery__swiperWrapper {
        padding: 0 20px;
      }
      .swiper-footer {
        width: 100% !important;
      }
    }

  }
}
