@import 'delonghi-nutribullet-vars';
$hs-border-hover-spot: $del-color-red;

@import "base-hotspot";
@import "rtl";

.dln-hotspot {
  &__single-point {
    z-index: 3;
  }

  &__info {
    &__title,
    &__content {
      font-size: 1rem;
    }
    &-desktop {
      @include bp('del-breakpoint-s') {
        z-index: 4;
      }
    }
  }
}