.del-keyfeatures {

  &__flex-grid {
    display: flex;
    flex-direction: column;
    row-gap: 60px;
    max-width: 336px;
    margin-inline: auto;

    @include bp('del-breakpoint-s') {
      flex-direction: row;
      row-gap: 64px;
      flex-wrap: wrap;
      justify-content: center;
      max-width: 632px;
    }

    @include bp('del-breakpoint-m') {
      max-width: 900px;
    }

    @include bp('del-breakpoint-xl') {
      row-gap: 74px;
      max-width: 1416px;
    }
  }

  &__flex-grid_item {
    width: 100%;
    max-width: 304px;
    margin-inline: auto;

    @include bp('del-breakpoint-s') {
      margin-inline: 16px;
      max-width: 284px;
    }

    @include bp('del-breakpoint-m') {
      margin-inline: 8px;
    }

    @include bp('del-breakpoint-xl') {
      margin-inline: 24px;
      max-width: 306px;
    }
  }
}
