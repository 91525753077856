@mixin textgroup-subtitle--generic {
  @include del-font-demi;
  font-size: 0.625rem;
  letter-spacing: 1.04px;
  line-height: 0.52rem;
  margin: 2px 0;

  @include bp('del-breakpoint-s') {
    font-size: 0.813rem;
    letter-spacing: 1.28px;
    line-height: 0.64rem;
  }
}
@mixin textgroup-content--generic {
  font-size: 1.313rem;
  line-height: 1.706rem;
  margin: 4px 0 12px;
  @include bp('del-breakpoint-s') {
    font-size: 1.5rem;
    line-height: 2.025rem;
    margin: 7px 0 16px;
  }
  @include bp('del-breakpoint-l') {
    font-size: 1.75rem;
    line-height: 2.45rem;
    margin: 7px 0 21px;
  }
}

.textgroup-subtitle--generic {
  @include textgroup-subtitle--generic;
}

.textgroup-content--generic {
  @include textgroup-content--generic;
}

@mixin textgroup--generic {
  .del-textgroup {
    &__subtitle {
      @extend .textgroup-subtitle--generic;
      p {
        @extend .textgroup-subtitle--generic;
      }
    }

    &__content {
      @extend .textgroup-content--generic;
      p {
        @extend .textgroup-content--generic;
      }
    }
  }
}
