.del-pdp__accessories {
  padding: 50px 0 20px;
  background-color: $del-color-white-smoke;

  &__title {
    @include del-font-medium;
    font-size: REM(28px);
    line-height: 28px;
    letter-spacing: 0;
    margin-bottom: 10px;
    text-align: center;

    @include bp('del-breakpoint-l') {
      font-size: REM(37px);
    }
  }

  .del-pdp__accessories__swiper-container {
    padding: 50px 10px;
    opacity: 0;
    transition: opacity .5s;
  }

  &__slide {
    padding: 0 40px;

    &.--last-child {
      border-right: 0 !important;
    }

    @include bp('del-breakpoint-s') {
      border-right: 1px solid $del-color-grey;

      &.swiper-slide-prev,
      &.swiper-slide-next {
        border-right: 0;
      }
    }

    @include bp('del-breakpoint-m') {
      &.swiper-slide-next {
        border-right: 1px solid $del-color-grey;
      }

      &.swiper-slide-next + .swiper-slide {
        border-right: 0;
      }
    }
  }

  &__cta {
    display: block;
    margin: 0 auto;
    background-color: $del-color-white;
  }

  &__pagination {
    bottom: 10px;
    left: 0;
    width: 100%;
  }

  .swiper-button-disabled {
    display: none;
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 40px;
    height: 40px;
    font-size: 1.25rem;
    border: 1px solid $del-color-black;
    border-radius: 50%;

    &::after {
      top: 50%;
      left: 50%;
      position: absolute;
      transform: translate(-30%, -44%);
    }
  }

  .swiper-button-prev {
    &::after {
      transform: translate(-50%, -44%);
    }
  }
}
