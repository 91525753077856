@import "./_braun_borrowed-stuff";

/*------------------------*/
/*  Mobile first approach */
/*------------------------*/

/*  General/initial rules */
.del-gallery-spacecontainer { // from braun: .content_section.constrain
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: calc(1440px + 60px);
  margin: 0 auto;
  justify-content: center;

  .bra-gallery__container {
    width: 100%;

    .bra-gallery {
      position: relative;
      max-width: 1440px;
      margin: 0;
      font-size: 0.938rem;
      line-height: normal;

      &__wrapper {
        display: none;
      }

      &__swiperWrapper {
        display: block;
        position: relative;
        visibility: visible;
        background: transparent;

        .swiper-slide {
          /*
          border-radius: 5px;
          */
          overflow: hidden;
        }
      }
    }
  }

  .bra-image {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    height: 0;
    width: 100%;
    padding-bottom: 100%;
    background-color: $ui-03;
    border-radius: 5px;
    overflow: hidden;
    transform: translateZ(0);

    @media (-ms-high-contrast: none) {
      position: relative;
      height: auto;
    }

    &__image {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      top: 0;
      left: 0;

      @media (-ms-high-contrast: none) {
        position: relative;
        min-width: 100%;
        min-height: 100%;
        max-height: none;
        max-height: initial;
        width: 100%;
        height: auto;

        align-self: center;
        flex-shrink: 0
      }
    }
  }
}

@import "_intro-titles.scss";
@import "_gallery-xs.scss";
@import "_gallery-s.scss";
@import "_gallery-m.scss";
