.del-pdp__reviews {
  padding: 0 10px;

  @include bp('del-breakpoint-s') {
    padding: 0 40px;
  }

  @include bp('del-breakpoint-l') {
    padding: 0 70px;
  }

  @include bp('del-breakpoint-l') {
    padding: 0 10px;
  }

  &__title {
    @include del-font-medium;
    font-size: REM(20px);
    line-height: 21px;
    letter-spacing: 2px;
    margin-bottom: 25px;
    text-align: center;
    color: $del-color-text;

    @include bp('del-breakpoint-l') {
      font-size: REM(24px);
      line-height: 28px;
      letter-spacing: 5px;
      margin-bottom: 35px;
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include bp('del-breakpoint-l') {
      flex-direction: row;
    }
  }

  &__side {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    @include bp('del-breakpoint-l') {
      width: 200px;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  &__average {
    @include del-font-book;
    display: inline-block;
    font-size: REM(67px);
    line-height: 44px;
    letter-spacing: normal;
    margin-bottom: 15px;
  }

  &__total {
    @include del-font-book;
    font-size: REM(16px);
    color: $del-color-black;
    opacity: 0.7;
    margin-bottom: 30px;
  }

  &__stars {
    margin-bottom: 10px;
    i {
      width: 28px;
      height: 28px;
    }
  }

  &__write-cta {
    @include del-button;
    @include del-button--mid;
    @include del-button--secondary;
    @include del-font-demi;
    width: 213px;
    font-size: REM(18px) !important;
  }

  &__list {
    width: 100%;

    @include bp('del-breakpoint-l') {
      width: calc(100% - 200px);
      padding: 0 30px 0 100px;
    }

    &__single {
      border-bottom: 1px solid $del-color-grey;
      padding: 24px 0 47px;
      display: flex;
      flex-direction: column;

      @include bp('del-breakpoint-l') {
        flex-direction: row;
      }

      &.--hidden {
        display: none;
      }

      &__info {
        margin-bottom: 17px;
        @include bp('del-breakpoint-l') {
          width: 180px;
        }
      }

      &__text {
        @include bp('del-breakpoint-l') {
          width: calc(100% - 180px);
        }
      }

      &__customer,
      &__customer-mobile {
        @include del-font-medium;
        font-size: REM(14px);
        line-height: 16px;
        color: $del-color-text;

        @include bp('del-breakpoint-l') {
          font-size: REM(16px);
        }
      }

      &__customer {
        display: none;
        @include bp('del-breakpoint-l') {
          display: block;
          font-size: REM(18px);
          margin-bottom: 10px;
        }
      }

      &__customer-mobile {
        @include bp('del-breakpoint-l') {
          display: none;
        }
      }

      &__stars {
        display: block;
        margin-bottom: 5px;
        i {
          width: 20px;
          height: 20px;
        }
      }

      &__date {
        @include del-font-book;
        font-size: REM(14px);
        line-height: 16px;
        color: $del-color-text;
        opacity: 0.7;
        padding-left: 20px;

        @include bp('del-breakpoint-l') {
          font-size: REM(16px);
        }
      }

      &__title {
        @include del-font-medium;
        font-size: REM(21px);
        line-height: 23px;
        color: $del-color-text;
        margin-bottom: 30px;
      }

      &__content {
        @include del-font-book;
        font-size: REM(16px);
        line-height: 19px;
        color: $del-color-pure-black;
      }
    }
  }

  &__bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    button {
      @include del-font-demi;
      font-size: 16px !important;
      line-height: 26px !important;
      min-width: 236px;
    }
  }

  &__showing-text {
    @include del-font-book;
    font-size: REM(14px);
    color: $del-color-text;
    margin: 25px 0 21px 0;
  }
}

// hack to fix an issue due to h-en services style
[class^='del-'] {
  .dln-writeReview {
    .dln-popup__head {
      flex-direction: row-reverse;
    }
  }
}
