.del-gallery-spacecontainer {

  .del-gallery-introTitles {
    display: flex;
    width: 85%;
    justify-content: space-between;
    align-items: flex-end;
    margin: 0 auto 60px;
    max-width: 1200px;
    font-size: 0.938rem;
    line-height: normal;
  }

  .del-gallery-title,
  .del-gallery-subtitle {
    display: block;
    width: 100%;
    color: $del-color-text;
  }
  .del-gallery-title {
    margin-bottom: auto;
    font-size: 1.5rem;
    line-height: 1.688rem;
    font-weight: 700;
    font-style: normal;
  }

  .del-gallery-subtitle {
    line-height: 1.188rem;
    font-size: 0.938rem;
    font-weight: 500;
    font-style: normal;
  }

  @media only screen and (max-width: $braun-s - 1) {
    .del-gallery-introTitles {
      /*
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      */
      flex-direction: column;
      margin: 0 auto 30px;
      width: calc(100% - 40px);

      /*
      .del-gallery-title {
        font-size: 1.5rem;
        line-height: 1.688rem;
      }
      */
      .del-gallery-subtitle {
        margin-top: 10px;
        /*
        margin-left: 20px;
        */
      }

      &--right-button,
      .bra-introText--right-button {
        align-items: flex-start;

        .bra-btn {
          margin-top: 30px;
        }
      }
    }
  }

  @media only screen and (min-width: $braun-s) and (max-width: $braun-m - 1) {
    .del-gallery-introTitles {
      width: calc(100% - 60px);
      margin-bottom: 40px;

      .del-gallery-title {
        font-size: 30px;
        line-height: 2.125rem;
      }
      .del-gallery-title + .del-gallery-subtitle {
        margin-top: 0;
        margin-left: 20px;
      }
    }
  }

  @media only screen and (min-width: $braun-m) {
    .del-gallery-introTitles {
      display: flex;
      width: 85%;
      max-width: 1200px;
      margin: 0 auto 60px;
      justify-content: space-between;
      align-items: flex-end;
      line-height: normal;

      .del-gallery-title {
        margin-bottom: auto;
        font-size: 2.25rem;
        line-height: 2.5rem;
      }
      .del-gallery-subtitle {
        line-height: 1.375rem;
        font-size: 1.063rem;
        font-weight: 400;
      }
      .del-gallery-title + .del-gallery-subtitle {
        margin-left: 20px
      }
    }
  }
  /*
  @media only screen and (min-width:$braun-m) and (max-width: $braun-l - 1) {
    .del-gallery-title {
      font-size: 2.25rem;
      line-height: 2.5rem;
    }
    .del-gallery-subtitle {
      font-size: 0.938rem;
      line-height: 1.375rem;
    }
  }
  */
}
