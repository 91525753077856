$wHatchImg: 150px;

.del-hatch {
  &__wrapper {
    display: flex;
    flex-direction: column;
    padding: 20px 10px 40px;

    @include bp('del-breakpoint-s') {
      padding: 20px 30px;
    }
  }

  &__title {
    @include del-font-medium;
    font-size: 2.375rem;
    color: $del-color-text;
    margin: 10px 0 20px;
    text-align: center;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 30px 0;
    border-bottom: 1px solid $del-color-grey;

    &:last-child {
      border-bottom: 0;
    }

    &__logo,
    &__price,
    &__availability {
      margin-bottom: 20px;
      text-align: center;
      @include bp('del-breakpoint-s') {
        margin-bottom: 0;
      }
    }

    &__logo {
      width: 100%;
      text-align: center;
      img {
        width: $wHatchImg;
        padding: 0 10px;
      }
      @include bp('del-breakpoint-s') {
        width: auto;
      }
    }

    &__price {
      @include del-font-book;
      font-size: 1.313rem;
      color: $del-color-text;
      letter-spacing: -0.3px;
      padding: 0 5px;
      width: 50%;

      @include bp('del-breakpoint-s') {
        width: auto;
      }
    }

    &__availability {
      @include del-font-medium;
      font-size: 1rem;
      color: $del-color-text-60;
      padding: 0 5px;
      width: 50%;

      @include bp('del-breakpoint-s') {
        width: auto;
      }
    }

    &__cta {
      width: 200px;
      margin: 0 auto;

      @include bp('del-breakpoint-s') {
        margin: 0;
      }
    }
  }
}
