.dln-delonghi {
  .dln-btn {
    line-height: 1.5;
    font-size: 1.125rem;

    &:not(.dln-btn--link) {
      @media only screen and (max-width: 599px) {
        font-size: 1.125rem;
      }
      line-height: 1;
    }
    &--stroked {
      line-height: 1.5;
      font-size: 1.125rem;
    }
    &--link {
      line-height: 1.313;
      font-size: 0.875rem;
    }
    &--social {
      & .dln-btn__lbl {
        font-size: 0.813rem;
      }
    }
  }

  .dln-inputsFile {
    &__innerLabel {
      font-size: 0.875rem;
      line-height: 1.125;
    }
  }

  & .dln-title {
    font-size: 3.125rem !important;
    line-height: 1.04 !important;
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-title {
      font-size: 2.313rem !important;
      line-height: 1.08 !important;
    }
  }
}

.dln-delonghi {
  .dln-select {
    &__lbl {
      font-size: 0.875rem;
      line-height: 1;
    }

    .dln-select--open + .dln-select__lbl,
    .dln-select--has-value + .dln-select__lbl {
      font-size: 0.75rem;
      line-height: 0.75rem;
    }
  }
}

.dln-delonghi {
  .simple-list {
    &__title {
      font-size: 0.875rem;
      line-height: 1.29rem;
    }
    &__item {
      font-size: 0.875rem;
      line-height: 1.43rem;
    }
  }
}

.dln-delonghi {
  .dln-tabs {
    &__btn {
      font-size: 1rem;
      line-height: 1.5;
    }
  }
}

.dln-delonghi {
  .dln-addBox {
    .dln-icn {
      &::before {
        font-size: 1rem;
      }
    }

    &__title {
      font-size: 1.75rem;
      line-height: 2.125;
    }
  }
}

.dln-delonghi {
  & .dln-addItem {
    &__title {
      font-size: 1rem;
    }

    &__price {
      .dln-price--oldPrice {
        font-size: 1rem;
      }

      .dln-price--discounted {
        font-size: 1rem;
      }
    }
  }
}

.dln-delonghi {
  .dln-addressBox {
    &__data {
      font-size: 0.875rem;
    }
    & .dln-btn.dln-btn--link {
      font-size: 0.875rem;
      line-height: 1.125;
    }
  }

  .dln-address--editing {
    .dln-address__section {
      &Head {
        &Title {
          font-size: 1.75rem;
          line-height: 2.125;

          @media only screen and (max-width: 599px) {
            font-size: 1.313rem;
            line-height: 1.438;
          }
        }
        &InfoText {
          font-size: 0.813rem;
          line-height: 1;
        }
      }
    }
  }
}

.dln-delonghi {
  .dln-advtBox {
    &__title {
      font-size: 1.125rem;
      line-height: 1.07;
    }
    &__desc {
      font-size: 0.875rem;
      line-height: 1.3;
    }
  }
}

.dln-delonghi {
  .dln-browse-by {
    &__link {
      line-height: 1.5;
    }
  }
}

.dln-delonghi {
  & .dln-cartItem {
    .dln-chips.dln-chips--lightGray.dln-chips--light {
      .dln-chips__content {
        span {
          font-size: 0.813rem;
        }
      }
    }

    &__price {
      .dln-price--oldPrice {
        font-size: 1rem;
      }

      .dln-price--discounted {
        font-size: 1rem;
      }
    }

    &__quantity {
      .dln-inputIncr {
        > button,
        input {
          font-size: 0.813rem;

          i {
            font-size: 0.813rem;
          }
        }
      }
    }

    &__label {
      font-size: 0.875rem;
    }

    &__accessories {
      .dln-select {
        .dln-select__value {
          font-size: 0.875rem;
        }
      }
    }

    &__title {
      font-size: 1.188rem;
    }

    &__warranty {
      .dln-price {
        font-size: 1.125rem;
      }
    }

    &__closeBtn {
      i {
        font-size: 1.5rem;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .dln-delonghi {
    & .dln-cartItem {
      &__title {
        font-size: 1rem;
      }

      &__warranty {
        .dln-price {
          font-size: 1rem;
        }
      }
    }
  }
}

.dln-delonghi {
  .dln-category-browser {
    &__category {
      .dln-categoryBox__title {
        font-size: 1.188rem;
        line-height: 1;
      }
    }
    &__wrapper {
      .dln-select {
        &__value {
          font-size: 1rem;
          line-height: 1.5;
        }
        &__arrow {
          line-height: 1.688rem;
          font-size: 1.125rem;
        }
      }
      .dln-breadCrumbSel {
        &__text {
          font-size: 1rem;
          line-height: 1.5;
        }
        &__arrow {
          line-height: 1.688rem;
          font-size: 1.125rem;
        }
      }
    }
    &__header--text {
      font-size: 1.125rem;
      line-height: 1.33;
    }
  }
}

.dln-delonghi {
  .dln-category-list {
    &__label {
      font-size: 1rem;
      line-height: 1.5;
    }

    &__title {
      font-size: 2.313rem;
      line-height: 1;
    }

    &__text {
      font-size: 1rem;
      line-height: 1.5;
    }
  }
}

.dln-delonghi {
  & .dln-checkServiceBox {
    &__content {
      & .dln-checkServiceBox__title {
        line-height: 1.125;
        font-size: 0.875rem;
      }
      & .dln-checkServiceBox__text {
        line-height: 1.125;
        font-size: 0.875rem;

        & + .dln-btn--secondary {
          line-height: 1.125;
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-country-list {
      & .dln-accordion {
        &__head {
          & .dln-accordion__title {
            line-height: 1.75rem;
          }
        }
      }
    }
  }
}

.dln-delonghi {
  & .dln-country-stripe {
    & .dln-country-stripe__buttons {
      & .dln-btn {
        font-size: 1.125rem;
      }
    }
  }
}

.dln-delonghi {
  & .dln-custServiceCard {
    &__title {
      line-height: 2.125;
      font-size: 1.75rem;
    }
    &__text {
      font-size: 1rem;
      line-height: 1.5;
    }
  }
}

.dln-delonghi {
  & .dln-dropdown {
    & .dln-loginForm {
      &__paragraph {
        line-height: 1.125;
        font-size: 0.875rem;
      }
    }

    /* user area */
    &--userArea {
      & .dln-dropdown__mainTitle {
        line-height: 1.5;
        font-size: 1.125rem;
      }
      & .dln-dropdown__liHeading {
        line-height: 1.5;
        font-size: 1.063rem;
      }
      & .dln-dropdown__li a {
        line-height: 1.5;
        font-size: 1rem;
      }
    }
  }
}

.dln-delonghi {
  .dln-filters-bar {
    &__applied-filters {
      .dln-btn {
        font-size: 1rem;
        line-height: 1;
      }
    }

    &__sort-section span {
      font-size: 1rem;
      line-height: 1.5;
    }

    &__sorted-by-label {
      font-size: 1rem;
    }

    .dln-select {
      &__value {
        font-size: 1rem;
        line-height: 1.5;
      }
      &__arrow {
        font-size: 1.125rem;
      }
    }
    @media only screen and (max-width: 599px) {
      &__mobile {
        .bra-btnView {
          .bra-icn {
            font-size: 1rem;
          }
        }
      }
    }
  }
}

.dln-delonghi {
  & .dln-loginForm {
    &__content {
      & .dln-loginForm__paragraph {
        line-height: 1.5;
        font-size: 1rem;
      }
    }
  }
}
@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-loginForm {
      &__content {
        & .dln-loginForm__paragraph {
          line-height: 1.438;
          font-size: 0.938rem;
        }
      }
      &__section {
        &--main {
          & .dln-loginForm__formContainer {
            & .dln-loginForm__paragraph {
              &--divider {
                font-size: 0.875rem;
              }
            }
          }
        }
      }
    }
  }
}

.dln-delonghi {
  & .dln-newUserForm {
    &__paragraph {
      line-height: 1.5;
      font-size: 1rem;
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-newUserForm {
      &__content {
        & .dln-newUserForm__paragraph {
          font-size: 0.938rem;
          line-height: 1.438;
        }
      }
    }
  }
}

.dln-delonghi {
  & .dln-instSteps {
    &__stepNum {
      font-size: 0.813rem;
    }
    &__stepText {
      font-size: 1rem;
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-instSteps {
      &__stepText {
        font-size: 0.938rem;
      }
    }
  }
}

.dln-delonghi {
  .dln-itemRegDetails {
    &__itemName {
      line-height: 1.188;
      font-size: 1.188rem;
    }
    &__details {
      & .dln-itemRegDetails__title {
        line-height: 1.125;
        font-size: 1.125rem;
      }
    }
    &--productRegistration {
      & .dln-itemRegDetails__paragraph {
        line-height: 1.125;
        font-size: 0.875rem;
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-itemRegDetails {
      &__itemName {
        line-height: 1;
        font-size: 1rem;
      }
      &__details {
        & .dln-itemRegDetails__title {
          line-height: 1.125;
          font-size: 1rem;
        }
      }
      &--productRegistration {
        & .dln-itemRegDetails__sku {
          font-size: 0.813rem;
        }
      }
    }
  }
}

.dln-delonghi {
  .dln-manuals-list {
    &__title {
      font-size: 0.875rem;
      line-height: 1.29rem;
    }
    &__details {
      font-size: 0.75rem;
      line-height: 1.5;
    }
  }
}

.dln-delonghi {
  .dln-miniCart {
    &__content {
      .dln-addItem {
        &__title {
          font-size: 1.188rem;
        }

        &__price {
          .dln-price {
            font-size: 1.188rem;
          }
        }
      }
    }

    &__footer {
      .dln-orderSumm {
        .dln-price {
          font-size: 1.188rem;
        }

        .dln-orderSumm__rowTitle,
        .dln-price--freeshippingtarget {
          font-size: 0.875rem;
        }
      }

      .dln-orderSumm__contentRow.dln-orderSumm__contentRow--total {
        .dln-orderSumm__rowTitle {
          font-size: 1.125rem;
        }

        span a {
          font-size: 0.875rem;
        }
      }
    }
  }
}

.dln-delonghi {
  .dln-multiSelectList {
    &__label {
      font-size: 1.125rem;
      line-height: 2.56rem;
    }
  }
}

.dln-delonghi {
  .dln-noticeBox {
    &__title {
      @media only screen and (max-width: 599px) {
        line-height: 1.438;
        font-size: 1.313rem;
      }
      line-height: 2.125;
      font-size: 1.75rem;
    }
    &__copy {
      line-height: 1.125;
      font-size: 0.875rem;
    }
    &--light {
      & .dln-noticeBox__copy {
        line-height: 1.125;
      }
    }
  }
}

.dln-delonghi {
  .dln-orderDetailHead {
    &__number {
      font-size: 1.75rem;
    }

    &__cancel {
      line-height: 3rem;
    }
  }
}

@media screen and (max-width: 599px) {
  .dln-delonghi {
    .dln-orderDetailHead {
      &__cancel {
        line-height: 2rem;
      }
    }
  }
}

.dln-delonghi {
  .dln-orderDetailInfo {
    &__title {
      font-size: 1.313rem;
    }

    &__subtitle {
      font-size: 1rem;
    }

    &__data {
      font-size: 0.875rem;
    }
  }
}

@media screen and (max-width: 599px) {
  .dln-delonghi {
    .dln-orderDetailInfo {
      &__title {
        font-size: 1.125rem;
      }
    }
  }
}

.dln-delonghi {
  .dln-orderInfoCard {
    &__number {
      font-size: 1.313rem;
    }
  }
}

@media screen and (max-width: 599px) {
  .dln-delonghi {
    .dln-orderInfoCard {
      &__number {
        font-size: 1.125rem;
      }
      &__cellData {
        font-size: 0.875rem;
      }

      &__cellTitle,
      &__viewDetail {
        font-size: 0.875rem;
      }
    }
  }
}

.dln-delonghi {
  & .dln-orderSumm {
    & .dln-orderSumm__content {
      & .dln-price {
        line-height: 1.125;
        font-size: 1.188rem;

        &--removeLink {
          line-height: 1.188;

          & > a {
            line-height: 1.125;
            font-size: 0.875rem;
          }
        }
        &--oldPrice {
          font-size: 1rem;
        }
        &--secondary {
          font-size: 0.875rem;
        }
      }
    }
    &--checkout {
      & .dln-orderSumm__title {
        font-size: 1.125rem;
      }
      & .dln-orderSumm__content {
        &Row {
          & .dln-orderSumm__lbl {
            font-size: 0.875rem;
          }
          & .dln-orderSumm__item {
            &Details {
              & > h5 {
                line-height: 1.313;
                font-size: 1.188rem;
              }
            }
          }
          & > .dln-orderSumm__rowTitle {
            line-height: 1.188;
            font-size: 0.813rem;
          }
          &--total {
            & .dln-orderSumm__rowTitle {
              line-height: 1.188;
              font-size: 1.125rem;
            }
          }
        }
      }
    }
    &--light {
      & .dln-orderSumm__content {
        & .dln-orderSumm__contentRow {
          & .dln-orderSumm__rowTitle {
            line-height: 1.125;
            font-size: 0.875rem;
          }
          & .dln-price {
            line-height: 1.125;
            font-size: 1.188rem;

            &--oldPrice {
              line-height: 1.188;
              font-size: 1rem;
            }
            &--secondary {
              line-height: 1.188;
              font-size: 1rem;
            }
            & > a {
              line-height: 1.125;
              font-size: 0.875rem;
            }
          }
          &--total {
            & .dln-orderSumm__rowTitle {
              line-height: 1.5;
              font-size: 1.125rem;
            }
          }
        }
      }

      & .dln-orderSumm__content {
        .dln-price {
          font-size: 1.125rem;
        }

        .dln-price--secondary {
          font-size: 0.875rem;
        }

        .dln-orderSumm__contentRow.dln-orderSumm__contentRow--total {
          .dln-orderSumm__rowTitle {
            a {
              font-size: 0.875rem;
            }
          }
        }
      }
    }
  }
}

.dln-delonghi {
  .dln-paginationSlide {
    &__tab {
      font-size: 1.313rem;
    }
  }
}

.dln-delonghi {
  .dln-payCardBox {
    &__cardInfo {
      font-size: 0.875rem;
      line-height: 1;
    }
    &__field {
      &Label {
        font-size: 0.813rem;
        line-height: 1;
      }
      &Data {
        font-size: 0.875rem;
        line-height: 1.125;
      }
    }
  }
}

.dln-delonghi {
  .dln-persDataRecap {
    &:not(.dln-persDataRecap--editing) {
      .dln-persDataRecap__wrap {
        .dln-inputs.dln-inputs--disabled,
        .dln-select.dln-select--disabled {
          font-size: 1rem;
          line-height: 1.875;

          .dln-select__lbl,
          .dln-inputs__lbl {
            font-size: 1rem;
            line-height: 1.875;
          }

          .dln-inputs__input,
          .dln-select__value {
            font-size: 1rem;
            line-height: 1.875;
          }
        }
      }
    }
  }
}

.dln-delonghi {
  .dln-prodManualsCard {
    &__prodName {
      font-size: 2.313rem;
      line-height: 1;
    }
    &__sku {
      font-size: 1.125rem;
      line-height: 1.33;
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    .dln-prodManualsCard {
      &__prodName {
        font-size: 1.75rem;
        line-height: 1.07;
      }
      &__sku {
        font-size: 1rem;
        line-height: 1.5;
      }
    }
  }
}

.dln-delonghi {
  .dln-profileCard {
    &__circle {
      &Text {
        font-size: 1.313rem;
        line-height: 1.438;
      }
    }
    &__text {
      font-size: 0.875rem;
      line-height: 1.125;
    }
  }
}

.dln-delonghi {
  & .dln-quickLink {
    &__title {
      font-size: 1rem;
    }
  }
}

.dln-delonghi {
  .dln-quickLinks--slider .dln-wrapper {
    .dln-quickLinks__title {
      font-size: 1.75rem;
      line-height: 1.21rem;
    }

    .dln-quickLink {
      &__title {
        font-size: 1.125rem;
        line-height: 1.11rem;
      }
      &__link {
        font-size: 0.875rem;
        line-height: 1.29rem;
      }
    }
    @media only screen and (max-width: 599px) {
      &__head {
        &Title {
          font-size: 2.313rem;
          line-height: 1.08rem;
        }
      }
      &__title,
      &__searchTitle {
        font-size: 1.313rem !important;
        line-height: 1.1rem;
      }

      .dln-quickLinks__title {
        font-size: 1.313rem;
        line-height: 1.1rem;
      }
    }
  }
}

.dln-delonghi {
  .dln-ratingStars {
    .dln-icn {
      &::before {
        font-size: 2.188rem;
      }
    }
  }
}

.dln-delonghi {
  .dln-wrapper {
    .dln-recipe-details {
      &__info {
        & .dln-recipe-details__info-button-desktop {
          & .dln-btn {
            line-height: 2.5rem;
          }
        }
      }
    }
  }
  .dln-recipe-details {
    &__title-recipe {
      font-size: 2.313rem;
      line-height: 1;
    }

    &__description {
      font-size: 1rem;
      line-height: 1.5;
    }

    &__bottom-info {
      &-label {
        font-size: 0.875rem;
        line-height: 1.3;
      }
      &-value {
        font-size: 0.875rem;
        line-height: 1.3;
      }
    }
    .dln-btn--icon {
      font-size: 0.875rem;
      line-height: 1.29rem;

      .dln-btn__lbl {
        font-size: 0.875rem;
        line-height: 1.29rem;
      }
      .bra-icn {
        font-size: 1.625rem;
      }
    }
  }
}

@media only screen and (min-width: 599px) and (max-width: 900px) {
  .dln-delonghi {
    & .dln-recipe-details {
      & .dln-wrapper {
        & .dln-recipe-details__info-button-mobile {
          & .dln-btn {
            line-height: 2.5rem;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-recipe-details {
      & .dln-wrapper {
        & .dln-recipe-details__info-button-mobile {
          & .dln-btn {
            line-height: 2.5rem;
          }
        }
      }
    }
  }
}

.dln-delonghi {
  .dln-recipe-slides {
    &__title a {
      font-size: 1.75rem;
      line-height: 1.21rem;
    }
    &__link {
      font-size: 0.875rem;
      line-height: 1.29rem;
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    .dln-recipe-slides {
      &__title a {
        font-size: 1.313rem;
        line-height: 1.1rem;
      }
    }
  }
}

.dln-table {
  &__table {
    thead {
      th {
        font-size: 1rem;
      }
    }
    tbody {
      td {
        font-size: 0;
        line-height: 0;

        .dln-table__tdValue {
          font-size: 1rem;
          line-height: 1.5;

          @media only screen and (max-width: 599px) {
            font-size: 0.813rem;
            line-height: 1;
          }
        }
        .dln-table__tdHeading {
          @media only screen and (max-width: 599px) {
            font-size: 0.813rem;
            line-height: 1;
          }
        }
      }
    }
  }
}

.dln-delonghi {
  & .dln-timeline {
    &__step {
      & .dln-timeline__label {
        & span {
          font-size: 0.875rem;
        }
      }
    }
  }
}

.dln-delonghi {
  .dln-totalSect {
    &__title {
      font-size: 1.313rem;
    }

    &__data {
      font-size: 1rem;
    }

    &__label {
      font-size: 0.875rem;
    }

    &__row--total {
      .dln-totalSect__label {
        font-size: 1.125rem;
      }

      .dln-totalSect__data {
        font-size: 1.125rem;
      }
    }
  }
}

@media screen and (max-width: 599px) {
  .dln-delonghi {
    .dln-totalSect {
      &__title {
        font-size: 1.125rem;
      }

      &__row--total {
        .dln-totalSect__label {
          font-size: 1rem;
        }
      }
    }
  }
}

.dln-delonghi {
  .dln-vertMenu {
    &__li {
      font-size: 0;
      line-height: 0;

      a {
        font-size: 1.125rem;
        line-height: 1.5;
      }
      &Heading {
        font-size: 1.125rem;
        line-height: 1.5;
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .dln-delonghi {
    .dln-vertMenu {
      &__li {
        a {
          font-size: 1.125rem;
          line-height: 1;
        }
      }
    }
  }
}

.dln-delonghi {
  .dln-wishProd {
    .dln-btnTextIcon {
      .dln-btnTextIcon__text {
        line-height: 1.5;
        font-size: 1.125rem;
      }
    }
    &__name {
      line-height: 1.188;
      font-size: 1.188rem;
    }
    &__price {
      line-height: 1.125;
      font-size: 1.188rem;
      &--discounted {
        line-height: 1.125;
        font-size: 1rem;
      }
      &Discounted {
        line-height: 1.125;
        font-size: 1.188rem;
      }
    }

    &__outOfStock {
      line-height: 1.25;
      font-size: 0.75rem;
    }
    & .dln-wishProd__name {
      @media only screen and (max-width: 599px) {
        line-height: 1;
        font-size: 1rem;
      }
      line-height: 1.188;
      font-size: 1.188rem;
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-wishProd {
      & .dln-btn.dln-btn--link {
        &::before {
          font-size: 1.5rem;
        }
      }
    }
  }
}

.dln-delonghi {
  & .dln-cart {
    & > .dln-wrapper {
      & .dln-cart__head {
        & .dln-cart__title {
          line-height: 3.25rem;
          font-size: 3.125rem;

          & .dln-cart__titleLbl {
            line-height: 1.5;
            font-size: 1.125rem;
          }
        }
      }
    }

    & .dln-chips.dln-chips--advantage {
      & i {
        font-size: 1.875rem;
      }
    }

    &__orderRecap {
      & .dln-btn {
        &.dln-btn--checkout {
          font-size: 1.313rem;
        }
        &--paypal {
          & .dln-btn__lbl {
            font-size: 0.813rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .dln-delonghi {
    & .dln-cart {
      & > .dln-wrapper {
        & .dln-cart__head {
          & .dln-cart__title {
            font-size: 1.313rem;
            line-height: 1.38rem;
            .dln-cart__titleLbl {
              font-size: 1rem;
            }
          }
        }
      }

      &__orderRecap {
        & .dln-btn {
          &--paypal {
            .dln-btn__lbl {
              font-size: 0.813rem;
            }
          }
        }
      }
    }
  }
}

.dln-delonghi {
  & .dln-cart {
    &--empty {
      .dln-cart__content .dln-chips__wrapper .dln-chips__content span {
        font-size: 0.875rem;
      }
    }
  }
}

.dln-delonghi {
  & .dln-checkout {
    & > .dln-wrapper {
      & > .dln-title {
        line-height: 3.25rem;
        font-size: 3.125rem;
      }
    }

    &__content {
      & .dln-accordion {
        &--shipping-information {
          & .dln-checkout__formData {
            & .dln-checkout__formValue {
              line-height: 1.125;
              font-size: 0.875rem;
            }
          }
          & .dln-chips--iconinside {
            & .dln-chips__content {
              & span {
                line-height: 1.125;
                font-size: 0.875rem;
              }
            }
          }
        }
        &--shipping-method {
          & .dln-checkout__dataResult {
            & .dln-checkout__formData {
              & .dln-checkout__formValue {
                line-height: 1.125;
                font-size: 0.875rem;
              }
            }
          }
        }
        &--payment {
          & .dln-checkout__dataResult {
            & .dln-checkout__formData {
              & .dln-checkout__formValue {
                line-height: 1.125;
                font-size: 0.875rem;
              }
            }
          }
          & .dln-accordion__content--submit {
            & .dln-checkout__paragraph {
              line-height: 1.125;
              font-size: 0.875rem;
            }
          }
        }
      }
    }
    &__innerColTitle {
      line-height: 1.438;
      font-size: 1.313rem;
    }
    &__noticeBox {
      & > p {
        line-height: 1.5;
        font-size: 1.125rem;
      }
    }
  }
}
.dln-delonghi {
  & .dln-checkout {
      & > .dln-wrapper {
          & > .dln-title {
              line-height: 3.25rem !important;
              font-size: 3.125rem !important;
          }
      }
   } 
}

@media only screen and (max-width: 599px) {
    .dln-delonghi {
      & .dln-checkout {
        &__aside {
        & .dln-orderSumm {
          &__headPriceLbl {
            font-size: 1.188rem;
          }
        }
        &__content {
          & .dln-accordion {
            &--access-information {
              & .dln-accordion__content {
                & .dln-checkout__innerCol {
                  & .dln-checkout__innerColTitle {
                    line-height: 1.25;
                    font-size: 1.125rem;
                  }
                }
              }
            }
          }
        }
        &__aside {
          &--secondary {
            & .dln-checkout__noticeBox {
              & > p {
                font-size: 1rem;
                line-height: 1.5;
              }
            }
          }
        }
      }
    }
  }
}

.dln-delonghi {
  & .dln-checkout.dln-checkout--editing {
    & .dln-accordion {
      &--access-information {
        & .dln-accordion__title {
          & > .dln-icn {
            line-height: 1;
            font-size: 1.063rem;
          }
        }
      }
      & .dln-checkout__dataResult {
        & > p {
          line-height: 1.125;
          font-size: 0.875rem;

          & > a {
            line-height: 1.125;
            font-size: 0.875rem;
          }
        }
      }
      & .dln-checkout__formData {
        & > p {
          line-height: 1.125;
          font-size: 0.875rem;
        }
      }
      &--shipping-method {
        & .dln-checkout__dataResult {
          & > p {
            & .dln-checkout__formValue {
              line-height: 1.125;
              font-size: 0.875rem;
            }
          }
        }
      }
      &--payment {
        & .dln-checkout__formField {
          &--billingAddress {
            & #dln-checkbox__checkboxbillingaddress--wrapper {
              & .dln-checkbox__label {
                line-height: 1.5;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-checkout--express {
      & .dln-wrapper {
        & .dln-checkout__content {
          & .dln-accordion {
            &--access-information {
              & .dln-checkout__dataResult {
                & > p {
                  font-size: 0.875rem;
                  line-height: 1.125;
                }
              }
            }
            &--shipping-information {
              & .dln-checkout__dataResult {
                & .dln-chips {
                  &__wrapper {
                    & .dln-chips__content span {
                      font-size: 0.813rem;
                      line-height: 1;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.dln-delonghi {
  & .dln-thankYou {
    &__content {
      & .dln-thankYou__successMess {
        & .dln-thankYou__title {
          line-height: 2.125;
          font-size: 1.75rem;
        }
        & .dln-thankYou__paragraph {
          line-height: 1.5;
          font-size: 1rem;
        }
      }
      & .dln-thankYou__orderNr {
        & .dln-thankYou__orderInfo {
          line-height: 1.813rem;
          font-size: 1.313rem;

          & > span {
            line-height: 1.125;
            font-size: 0.875rem;
          }
          &--user {
            line-height: 1.125;
            font-size: 0.875rem;
          }
        }
      }
    }
    & .dln-wrapper {
      &#mainContent {
        & .dln-thankYou__innerWrap {
          & .dln-thankYou__orderDetails {
            & .dln-thankYou__content {
              &--orderSummary {
                & .dln-thankYou__subTitle {
                  line-height: 1.5;
                  font-size: 1.125rem;
                }
                & .dln-thankYou__subTitleInnerWrap {
                  & a {
                    font-size: 0.875rem;
                  }
                }
              }
              &--delivery {
                & .dln-thankYou__subTitle {
                  line-height: 1.5;
                  font-size: 1rem;
                }
                & .dln-addressBox {
                  &__data {
                    line-height: 1.125;
                    font-size: 0.875rem;
                  }
                }
              }
              &--payment {
                & .dln-thankYou__subTitle {
                  line-height: 1.5;
                  font-size: 1rem;
                }
              }
              &--aside {
                & .dln-thankYou__subTitle {
                  line-height: 1.5;
                  font-size: 1rem;
                }
                & .dln-orderSumm {
                  &__contentRow {
                    &--total {
                      & .dln-orderSumm__rowTitle {
                        font-size: 1.125rem !important;
                      }
                    }
                    & .dln-orderSumm__rowTitle {
                      font-size: 0.875rem;
                    }
                    & .dln-price {
                      font-size: 1.125rem;

                      &--secondary {
                        font-size: 0.875rem;
                      }
                      &--oldPrice {
                        font-size: 1rem;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    &__shareContent {
      & .dln-thankYou__paragraph {
        & > a {
          line-height: 1.125;
          font-size: 0.875rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-thankYou {
      & .dln-wrapper {
        & .dln-thankYou__content {
          & .dln-thankYou__successMess {
            & .dln-icn {
              line-height: 2rem;
            }
            & .dln-thankYou__title {
              line-height: 1.813rem;
              font-size: 1.313rem;
            }
            & .dln-thankYou__paragraph {
              line-height: 1.125;
              font-size: 0.938rem;
            }
          }
          & .dln-thankYou__orderNr {
            & .dln-thankYou__orderInfo {
              line-height: 1.5;
              font-size: 1.125rem;

              & span {
                font-size: 0.875rem;
              }
              &--user {
                line-height: 1.125;
                font-size: 0.875rem;
              }
            }
          }
        }
      }
      & .dln-wrapper#mainContent {
        & .dln-thankYou__innerWrap {
          & .dln-thankYou__orderDetails {
            & .dln-thankYou__content {
              &--orderSummary {
                & .dln-thankYou__subTitle {
                  font-size: 1rem !important;
                }
                & .dln-orderRev {
                  &--cart-order {
                    & .dln-orderRev__content {
                      & .dln-orderRev__details {
                        & .dln-orderRev__wrap--main {
                          & .dln-price {
                            font-size: 1rem;

                            &--oldPrice {
                              font-size: 0.938rem;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              &--delivery {
                & .dln-thankYou__subTitle {
                  line-height: 1.5;
                  font-size: 0.938rem;
                }
              }
              &--payment {
                & .dln-thankYou__subTitle {
                  font-size: 0.938rem;
                }
                & .dln-addressBox {
                  & .dln-addressBox__data {
                    line-height: 1.125;
                  }
                }
              }
              &--aside {
                & .dln-orderSumm {
                  &__content {
                    &Row {
                      & .dln-price {
                        font-size: 1rem;

                        &--oldPrice {
                          font-size: 0.938rem;
                        }
                        &--secondary {
                          font-size: 0.875rem;
                        }
                      }
                      &--total {
                        & .dln-orderSumm__rowTitle {
                          font-size: 0.875rem !important;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.dln-delonghi {
  & .dln-thankYou--guest {
    & .dln-thankYou__regForm {
      & .dln-thankYou__subTitle {
        line-height: 1.813rem;
        font-size: 1.313rem;
        & + .dln-thankYou__paragraph {
          line-height: 1.125;
          font-size: 0.875rem;
        }
      }
      & .swiper-container {
        & .swiper-wrapper {
          & .dln-advtBox {
            & .dln-advtBox__title {
              line-height: 1.438;
              font-size: 1.313rem;
            }
            & .dln-advtBox__desc {
              line-height: 1.125;
              font-size: 0.875rem;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-thankYou--guest {
      & .dln-thankYou__regForm {
        & .dln-thankYou__subTitle {
          line-height: 1.5;
          font-size: 1.125rem;
        }
      }
    }
  }
}

.dln-delonghi {
  & .dln-prodReg--confirmation {
    & .dln-confirmation-page__wrapper-content {
      & .dln-wrapper {
        & .dln-chips {
          &__wrapper {
            & .dln-chips__content {
              line-height: 1.5;
              font-size: 1.125rem;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-prodReg--confirmation {
      & .dln-confirmation-page__wrapper-content {
        & .dln-wrapper {
          & .dln-chips {
            &__wrapper {
              & .dln-icn {
                line-height: 2.625rem;
                font-size: 2.625rem;
              }
              & .dln-chips__content {
                font-size: 1rem;
              }
            }
          }
        }
      }
    }
  }
}

.dln-delonghi {
  .dln-page.dln-changePssw {
    .dln-wrapper .dln-page__content {
      .dln-changePssw {
        &__section {
          & .dln-title {
            font-size: 1.75rem !important;
          }
          &--deleteAccount {
            & .dln-changePssw__paragraph {
              font-size: 0.875rem;
              line-height: 1.125;
            }
            ul {
              li {
                font-size: 0.875rem;
                line-height: 1.125;
              }
            }
          }
        }
        &__confirmDeleting {
          .dln-changePssw__middleTitle {
            font-size: 1.125rem;
            line-height: 1.188;
          }
        }
      }
    }
  }
}

.dln-delonghi {
  .dln-registration {
    &__subtitle {
      font-size: 1.313rem;
      line-height: 1.12;
    }
    &__paragraph {
      font-size: 1rem;
      line-height: 1.5;
    }
    .dln-title {
      font-size: 3.125rem;
      line-height: 1.04;
    }
    .dln-loginForm__paragraph {
      font-size: 1rem;
      line-height: 1.5;
    }
    .dln-regAdv {
      &__text {
        font-size: 0.875rem;
        line-height: 1.3;
      }
    }
  }
  @media only screen and (max-width: 599px) {
    .dln-registration {
      &__subtitle {
        font-size: 1.125rem;
        line-height: 1.11rem;
      }
      &__paragraph {
        font-size: 0.938rem;
        line-height: 1.53rem;
      }
      .dln-title {
        font-size: 2.313rem;
        line-height: 1.08rem;
      }
    }
  }
}

.dln-delonghi {
  .dln-profile {
    &__banner {
      &Title {
        font-size: 2.313rem;
        line-height: 2.313rem;

        @media only screen and (max-width: 599px) {
          font-size: 1.75rem;
          line-height: 1.875;
        }
      }
      &Copy {
        font-size: 1rem;
        line-height: 1.5;
      }
      &SubTitle {
        font-size: 1.313rem;
        line-height: 1.438;
      }
    }

    &__recipes {
      &Wrap {
        .dln-profile__recipesSubtitle {
          font-size: 1.313rem;
          line-height: 1.438;
        }
        .dln-profile__recipesTitle {
          font-size: 1.75rem;
          line-height: 2.125;
        }
        .dln-profile__recipesLink {
          font-size: 1.313rem;
          letter-spacing: -0.5rem;
        }
      }
    }

    .dln-carousel {
      .dln-profile__banner {
        &SubTitle {
          font-size: 1.125rem;
          line-height: 1.188;
        }
        &Copy {
          font-size: 0.875rem;
          line-height: 1.125;
        }
      }
    }

    .dln-wrapper--carousel {
      .dln-profile__itemsCarouselTitle {
        font-size: 1.75rem;
        line-height: 2.125;

        @media only screen and (max-width: 599px) {
          font-size: 1.313rem;
          line-height: 1.438;
        }
      }
      .dln-btnTextIcon {
        .dln-btnTextIcon__text {
          font-size: 1.125rem;
          line-height: 1.5;
        }
      }
    }

    .dln-profile__sectionHead {
      &Title {
        font-size: 2.313rem;
        line-height: 2.313rem;

        &.dln-profile__sectionHeadTitle--smallMobile {
          @media only screen and (max-width: 599px) {
            font-size: 1.75rem;
            line-height: 1.875;
          }
        }
      }
      .dln-btn--link {
        font-size: 0.875rem;
        line-height: 1.125;

        @media only screen and (max-width: 599px) {
          font-size: 0.875rem;
          line-height: 1.125;
        }
      }
    }
  }
}

.dln-delonghi {
  .dln-registration {
    .dln-registrationForm__formFoot {
      .dln-registrationForm__paragraph--secondary {
        font-size: 1rem;
      }
    }
  }
}
.dln-delonghi {
  & .dln-wishlist {
    & .dln-wrapper {
      & .dln-wishlist__content {
        & .dln-title {
          line-height: 3.25rem;
          font-size: 3.125rem;
        }
        & .dln-wishlist__subtitle {
          line-height: 2.313rem;
          font-size: 2.313rem;
        }
        & > .dln-wishlist__text {
          line-height: 1.5;
          font-size: 1rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-wishlist {
      & .dln-wrapper {
        & .dln-wishlist__content {
          & .dln-title {
            line-height: 2.5rem;
            font-size: 2.313rem;
          }
          & .dln-wishlist__subtitle {
            line-height: 1.875;
            font-size: 1.75rem;
          }
        }
      }
    }
  }
}

.dln-delonghi {
  .dln-order {
    &--cancellation {
      .dln-order {
        &__cancellationTitle {
          font-size: 3.125rem;
        }

        &__cancellationData {
          strong {
            font-size: 1.125rem;
          }
        }

        &__cancellationMessage {
          font-size: 1rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    .dln-order {
      &--cancellation {
        .dln-order {
          &__cancellationTitle {
            font-size: 2.313rem;
            line-height: 1.08rem;
          }
        }
      }
    }
  }
}

.dln-delonghi {
  .dln-order--detail {
    .dln-order__detailProducts {
      &--cancelled {
        .dln-order__detailTitle {
          font-size: 1.5rem;
        }
        .dln-order__prodCancelledBox {
          &Text {
            font-size: 0.875rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 599px) {
  .dln-delonghi {
    .dln-order--detail {
      .dln-order__detailProducts {
        &--cancelled {
          .dln-order__detailTitle {
            font-size: 1.125rem;
          }
        }
      }
    }
  }
}

.dln-delonghi {
  .dln-order {
    &--detail {
      .dln-order {
        &__detailTitle {
          font-size: 1.313rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    .dln-order {
      &--detail {
        .dln-order {
          &__content {
            .dln-orderDetailHead__number {
              font-size: 1.313rem !important;
            }
          }
        }
      }
    }
  }
}

.dln-delonghi {
  .dln-order--detail {
    .dln-order {
      &__detailProducts--shipped {
        .dln-orderRev {
          &__productCode {
            font-size: 0.875rem;
          }

          &__wrap--main {
            .dln-price--oldPrice {
              font-size: 1rem;
            }

            .dln-price--discounted {
              font-size: 1.188rem;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    .dln-order--detail {
      .dln-order {
        &__detailProducts--shipped {
          .dln-order {
            &__detailTitle {
              font-size: 1.125rem;
            }
          }
        }
      }
    }
  }
}

.dln-delonghi {
  .dln-order {
    &--status {
      .dln-order {
        &__text {
          font-size: 1rem !important;
        }

        &__title {
          font-size: 1.313rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    .dln-order {
      &--status {
        .dln-order {
          &__text {
            font-size: 0.938rem !important;
          }
          &__title {
            font-size: 1.125rem;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-popup {
      & .dln-popup__content {
        & .dln-popup__wrapper {
          & .dln-addAccessories {
            &__content {
              .dln-cartItem__title {
                font-size: 1.188rem;
              }
            }
          }
        }
      }
    }
  }
}

.dln-delonghi {
  & .dln-countrySelection--modal {
    & .dln-popup__head {
      &Title {
        font-size: 3.125rem;
      }
    }
    & .dln-country-list {
      & .dln-accordion {
        &__head {
          & .dln-accordion__title {
            font-size: 1.75rem;
          }
        }
        &__content {
          & .dln-flag__list {
            & .dln-flag__listItem {
              & .dln-flag__languages {
                font-size: 1rem;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-countrySelection--modal {
      & .dln-popup__head {
        &Title {
          font-size: 2.313rem;
        }
      }
    }
  }
}

.dln-delonghi {
  & .dln-popup {
    &__content {
      & .dln-popup__wrapper {
        & .dln-faqAnnouncement {
          & .dln-popup__head {
            & .dln-popup__headTitle {
              line-height: 2.313rem;
              font-size: 2.313rem;
            }
          }
          &__content {
            & .dln-faqAnnouncement__text {
              line-height: 1.5;
              font-size: 1rem;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-popup {
      &__content {
        & .dln-popup__wrapper {
          & .dln-faqAnnouncement {
            & .dln-popup__head {
              & .dln-popup__headTitle {
                line-height: 1.875;
                font-size: 1.75rem;
              }
            }
            &__content {
              & .dln-faqAnnouncement__text {
                font-size: 0.938rem;
              }
            }
          }
        }
      }
    }
  }
}

.dln-delonghi {
  & .dln-popup {
    & .dln-popup__content {
      & .dln-popup__wrapper {
        & .dln-faqShare {
          & .dln-popup__head {
            & .dln-popup__headTitle {
              line-height: 2.313rem;
              font-size: 2.313rem;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-popup {
      & .dln-popup__content {
        & .dln-popup__wrapper {
          & .dln-faqShare {
            & .dln-popup__head {
              & .dln-popup__headTitle {
                line-height: 1.875;
                font-size: 1.75rem;
              }
            }
          }
        }
      }
    }
  }
}

.dln-delonghi {
  .dln-filtersPopup {
    &__head {
      .dln-btn {
        line-height: 1.29rem;
        font-size: 0.875rem;
      }
    }
    &__title {
      line-height: 1.19rem;
      font-size: 1.313rem;
    }
    .dln-accordion {
      &__title {
        line-height: 1.19rem;
        font-size: 1.313rem;
      }
    }
  }
}

.dln-delonghi {
  & .dln-findProductNumber {
    &__description,
    &__note {
      font-size: 1rem;
      line-height: 1.5;
    }
  }
}

.dln-delonghi {
  .dln-popup__content {
    .dln-forgPsw {
      .dln-popup__headTitle {
        line-height: 1.25;
        font-size: 1.063rem;
      }
    }
  }
}

.dln-delonghi {
  & .dln-popup {
    &__content {
      & .dln-popup__wrapper {
        & .dln-addedItem {
          .dln-popup__head.dln-popup__head--circleClose.dln-popup__head--support02 {
            .dln-popup__headTitle {
              font-size: 1rem;

              .dln-icn {
                font-size: 0.813rem;
              }
            }
          }
          &__content {
            .dln-cartItem {
              &__price {
                .dln-price {
                  font-size: 1.188rem;
                }
              }

              .dln-csmDropdown {
                &__selected {
                  font-size: 0.875rem;
                }
              }
            }
            &--extra {
              .dln-addedItem__subTitle {
                font-size: 1rem;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-popup {
      &__content {
        & .dln-popup__wrapper {
          & .dln-addedItem {
            &__content {
              .dln-cartItem {
                &__price {
                  .dln-price {
                    font-size: 0.938rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.dln-delonghi {
  .dln-orderCancellationConfirm {
    &__text {
      font-size: 1.125rem;
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    .dln-orderCancellationConfirm {
      &__text {
        font-size: 1rem;
      }
    }
  }
}

.dln-delonghi {
  & .dln-ordReturnHowItWorks {
    & .dln-popup__head {
      &Title {
        font-size: 2.313rem;
        line-height: 2.313rem;
      }
    }
  }
}
@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-ordReturnHowItWorks {
      & .dln-popup__head {
        &Title {
          font-size: 1.75rem;
          line-height: 1.875;
        }
      }
    }
  }
}

.dln-delonghi {
  & .dln-verification {
    & .dln-popup__head {
      &Title {
        line-height: 1.5;
        font-size: 1.125rem;
      }
    }
    &__text {
      line-height: 1.5;
      font-size: 1rem;
    }
  }
}

.dln-delonghi {
  .dln-search {
    .dln-popup__headTitle {
      font-size: 2.313rem;
      line-height: 1;
    }

    &--noResults {
      &__subTitle {
        font-size: 1.313rem;
        line-height: 1.12;
      }

      &__paragraph {
        font-size: 1rem;
        line-height: 1.5;
      }
    }

    @media only screen and (max-width: 599px) {
      .dln-search--noResults__subTitle {
        font-size: 1.125rem;
      }

      .dln-search-results__view-all {
        font-size: 0.875rem;
      }

      .simple-list__title {
        font-size: 0.813rem;
      }

      .simple-list__item {
        b {
          font-size: 1rem;
        }
        font-size: 1rem;
      }
    }
  }
}

.dln-delonghi {
  & .dln-shipDelivery {
    &__title,
    &__text {
      font-size: 0.875rem;
    }

    .dln-popup {
      &__head {
        &Title {
          font-size: 1rem;
        }
      }
    }
  }
}
.dln-delonghi {
  .dln-singProductReview {
    &__title {
      font-size: 1.75rem;
      line-height: 2.125;

      @media only screen and (max-width: 599px) {
        font-size: 1.313rem;
        line-height: 1.438;
      }
    }

    .dln-singProductReview__detailsTitle {
      font-size: 0.875rem;
      line-height: 1.125;
    }

    .dln-singProductReview__desc {
      font-size: 0.875rem;
      line-height: 1.125;
    }
  }
}

.dln-delonghi {
  & .dln-popup {
    &__content {
      & .dln-popup__wrapper {
        & .dln-subscription-flow {
          &__column-two {
            & .dln-title {
              line-height: 2.125rem !important;
              font-size: 1.75rem !important;
            }
            & .dln-subscription-flow__sub-title {
              line-height: 1.5;
              font-size: 1.125rem;
            }
            & .dln-subscription-flow__service-container {
              & > div {
                & .dln-icn {
                  font-size: 1.5rem;
                }
                & > span {
                  font-size: 1.125rem;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-popup {
      &__content {
        & .dln-popup__wrapper {
          & .dln-subscription-flow {
            &__column-two {
              & .dln-title {
                line-height: 2.5rem !important;
                font-size: 2.313rem !important;
              }
              & .dln-subscription-flow__sub-title {
                line-height: 1.438;
                font-size: 0.938rem;
              }
            }
          }
        }
      }
    }
  }
}

.dln-delonghi {
  .dln-writeReview {
    &__subTitle {
      line-height: 1.188;
      font-size: 1.125rem;
    }
    &__form {
      .dln-writeReview__subTitle {
        line-height: 1.188;
        font-size: 1.125rem;
      }
    }

    &__item {
      @media only screen and (max-width: 599px) {
        .dln-orderRev {
          .dln-orderRev__content {
            .dln-orderRev__detailsTitle {
              line-height: 1.188;
              font-size: 1.188rem;
            }
          }
        }
      }
    }
  }
}

.dln-delonghi {
  & .dln-prodReg {
    &__content {
      & .dln-prodReg__chips {
        & .dln-chips {
          & .dln-chips__content {
            line-height: 1.5;
            font-size: 1.125rem;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-prodReg {
      &__content {
        & .dln-prodReg__chips {
          & .dln-chips {
            & .dln-chips__content {
              font-size: 1rem;
            }
          }
        }
      }
    }
  }
}

.dln-delonghi {
  & .dln-prodReg--productDetails {
    & .dln-prodReg__head {
      & .dln-title {
        line-height: 3.25rem;
        font-size: 3.125rem;
      }
    }
    & .dln-prodReg__content {
      & .dln-prodReg__subTitle {
        line-height: 2.125;
        font-size: 1.75rem;
      }
      & .dln-prodReg__lbl {
        line-height: 1;
        font-size: 0.813rem;
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-prodReg {
      &--productDetails {
        & .dln-prodReg__head {
          & .dln-title {
            font-size: 2.313rem;
            line-height: 2.5rem;
          }
        }
        & .dln-prodReg__content {
          & .dln-prodReg__subTitle {
            font-size: 1.313rem;
            line-height: 1.438;

            & .dln-prodReg__lbl {
              line-height: 1.438;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-prodReg {
      & .dln-prodReg__subTitle {
        line-height: 1.438;
        font-size: 1.313rem;
      }
    }
  }
}

.dln-delonghi {
  .dln-prodReg {
    &__subTitle {
      font-size: 1.75rem;
      line-height: 1.21rem;
    }

    &__paragraph {
      font-size: 1rem;
      line-height: 1.5;
    }

    .dln-prodReg__subTitle {
      font-size: 1.75rem;
      line-height: 1.21rem;
    }

    .dln-tooltip__text {
      font-size: 0.813rem;
      line-height: 1.23rem;
    }

    @media only screen and (max-width: 599px) {
      &__subTitle {
        font-size: 1.313rem;
        line-height: 1.1rem;
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-prodReg {
      & .dln-wrapper {
        & .dln-prodReg__subTitle {
          font-size: 1.313rem;
          line-height: 1.1rem;
        }
      }
    }
  }
}

.dln-delonghi {
  .dln-errorPage {
    &__title {
      font-size: 1.125rem;
      line-height: 1.33;
    }

    &__mainTitle {
      font-size: 3.125rem;
      line-height: 1.04;
      span {
        font-size: 2.313rem;
        line-height: 1;
      }
    }
    &__mainText {
      font-size: 1rem;
      line-height: 1.5;
    }

    @media only screen and (min-width: 599px) and (max-width: 900px) {
      .dln-wrapper {
        .dln-errorPage__mainTitle {
          span {
            font-size: 1.75rem;
            line-height: 1.07;
          }
        }
      }
    }

    @media only screen and (max-width: 599px) {
      .dln-wrapper {
        .dln-errorPage__mainTitle {
          font-size: 2.313rem;

          span {
            font-size: 1.75rem;
            line-height: 1.07;
          }
        }

        &.dln-wrapper--content {
          .dln-errorPage__title {
            font-size: 1.125rem;
          }
        }
      }
    }
  }
}

.dln-delonghi {
  .dln-contactUs {
    &__head {
      &Title {
        line-height: 1.04;
        font-size: 3.125rem;
      }
    }

    .dln-accordion {
      &__title {
        line-height: 2.125;
        font-size: 1.75rem;
      }
      &__icon {
        font-size: 2rem;
      }
    }

    &__title {
      line-height: 1.07;
      font-size: 1.125rem;
    }
    &__text {
      line-height: 1.5;
      font-size: 1rem;
    }

    &__callUs {
      &Title {
        line-height: 1.07;
        font-size: 1.125rem;
      }
      &Text {
        line-height: 1.3;
        font-size: 0.875rem;
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-contactUs {
      &__head {
        &Title {
          line-height: 1.08rem;
          font-size: 2.313rem;
        }
      }
      & .dln-accordion {
        &__title {
          line-height: 1.1rem;
          font-size: 1.313rem;
        }
      }
      &__title {
        line-height: 1.13;
        font-size: 1rem;
      }
      &__text {
        line-height: 1.3;
        font-size: 0.875rem;
      }

      &__callUs {
        &Text {
          line-height: 1.3;
          font-size: 0.875rem;
        }
      }
    }
  }
}

.dln-delonghi {
  .dln-contactUs--thankYou {
    & .dln-btn--backToContactUs {
      line-height: 1.25;
      font-size: 0.875rem;
    }
    & .dln-wrapper {
      &--thankYouMessage {
        & .dln-contactUs__title {
          line-height: 2.125;
          font-size: 1.75rem;
        }
        & .dln-contactUs__thankYouMessage {
          &Text {
            line-height: 1.5;
            font-size: 1.125rem;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-contactUs--thankYou {
      & .dln-wrapper {
        &--thankYouMessage {
          & .dln-contactUs__title {
            line-height: 1.438;
            font-size: 1.313rem;
          }
          & .dln-contactUs__thankYouMessage {
            &Text {
              line-height: 1.5;
              font-size: 1rem;
            }
            & .dln-btn--backToContactUs {
              line-height: 1.313;
              font-size: 0.938rem;
            }
          }
        }
      }
    }
  }
}

.dln-delonghi {
  .dln-customerSupport {
    &__head {
      &Title {
        font-size: 3.125rem;
        line-height: 1.04;
      }
    }
    &__title,
    &__searchTitle {
      font-size: 1.75rem !important;
      line-height: 1.21rem;
    }
    &__subtitle {
      font-size: 1.313rem;
      line-height: 1.12;
    }

    .dln-list .dln-listItem {
      .dln-listLink,
      .dln-icn {
        font-size: 0.875rem;
        line-height: 1.43rem;
      }
    }

    .dln-quickLinks--slider .dln-wrapper {
      .dln-quickLinks__title {
        font-size: 1.75rem;
        line-height: 1.21rem;
      }

      .dln-quickLink {
        &__title {
          font-size: 1.125rem;
          line-height: 1.11rem;
        }
      }
    }

    @media only screen and (max-width: 599px) {
      &__head {
        &Title {
          font-size: 2.313rem;
          line-height: 1.08rem;
        }
      }
      &__title,
      &__searchTitle {
        font-size: 1.313rem !important;
        line-height: 1.1rem;
      }

      .dln-quickLinks--slider .dln-wrapper {
        .dln-quickLinks__title {
          font-size: 1.313rem;
          line-height: 1.1rem;
        }
      }
    }
  }
}

.dln-delonghi {
  & .dln-faq {
    &__main {
      & > .dln-wrapper {
        & .dln-faq__mainTitle {
          line-height: 3.25rem;
          font-size: 3.125rem;
        }
        & .dln-faq__mainSubtitle {
          line-height: 2.125;
          font-size: 1.75rem;
        }
      }
    }
    & .dln-category-browser__wrapper {
      & > .dln-wrapper {
        & .dln-category-browser__header {
          & .dln-category-browser__header--title {
            line-height: 1.438;
            font-size: 1.313rem;
          }
        }
      }
    }
    & .dln-quickLinks--slider {
      & .dln-wrapper {
        & .dln-quickLinks__inner {
          & .dln-quick-links-slider__carousel {
            & .swiper-wrapper .swiper-slide {
              & .dln-quickLink {
                & .dln-quickLink__wrap {
                  & .dln-quickLink__title {
                    line-height: 1.125;
                    font-size: 1rem;
                  }
                }
              }
            }
          }
        }
      }
    }
    &--alert {
      & .dln-faq__chipsWrap {
        & > .dln-wrapper--fullWidth {
          & .dln-wrapper {
            & .dln-chips {
              &__content {
                & .dln-chips__title {
                  line-height: 1.5;
                }
                & .dln-chips__description {
                  line-height: 1.25;
                }
                & .dln-btn--link {
                  line-height: 1.125;
                  font-size: 0.875rem;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-faq {
      &__main {
        & .dln-wrapper {
          & .dln-faq__mainTitle {
            line-height: 2.5rem;
            font-size: 2.313rem;
          }
          & .dln-faq__mainSubtitle {
            line-height: 1.438;
            font-size: 1.313rem;
          }
        }
      }
      & .dln-category-browser__wrapper {
        & > .dln-wrapper {
          & .dln-category-browser__header {
            & .dln-category-browser__header--title {
              line-height: 1.25;
              font-size: 1.125rem;
            }
          }
        }
      }
    }
  }
}

.dln-delonghi {
  & .dln-faq {
    &--alert {
      & .dln-faq__chipsWrap {
        & > .dln-wrapper--fullWidth {
          & .dln-wrapper {
            & .dln-chips {
              &__content {
                & .dln-chips__info {
                  line-height: 1.188;
                  font-size: 1.125rem;
                }
                & .dln-chips__title {
                  line-height: 1.5;
                }
                & .dln-chips__description {
                  line-height: 1.125;
                }
                & .dln-btn {
                  line-height: 1.125;
                  font-size: 0.875rem;
                }
              }
            }
          }
        }
      }
      & .dln-category-browser__wrapper {
        & .dln-wrapper {
          & .dln-category-browser__breadcrumbs {
            & .dln-select {
              & .dln-select__container {
                & .dln-select__head {
                  & .dln-select__value {
                    line-height: 1.25;
                  }
                }
              }
            }
            & .dln-breadCrumbSel {
              &__text {
                font-size: 1rem;
              }
              & .dln-breadCrumbSel__arrow {
                line-height: 1.25;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-faq {
      &--alert {
        & .dln-faq__chipsWrap {
          & .dln-wrapper {
            & .dln-wrapper {
              & .dln-chips {
                &__wrapper {
                  & .dln-btnIcon {
                    & .dln-icn {
                      font-size: 1rem;
                    }
                  }
                  & .dln-chips__content {
                    & .dln-chips__info {
                      line-height: 1.125;
                      font-size: 1rem;
                    }
                    & .dln-chips__title {
                      font-size: 1rem;
                    }
                  }
                }
              }
            }
          }
        }
        & .dln-faq__main {
          & .dln-wrapper {
            & .dln-faq__mainTitle {
              line-height: 2688rem;
              font-size: 2.188rem;
            }
          }
        }
      }
    }
  }
}

.dln-delonghi {
  .dln-instruction-manuals {
    &__main {
      & > .dln-wrapper {
        & .dln-btn.dln-btn--link {
          font-size: 0.688rem;
        }
      }
    }
    &__mainTitle {
      line-height: 1.04;
      font-size: 3.125rem;
    }
    &__mainSubtitle,
    &__helpSectionTitle {
      line-height: 1.21rem;
      font-size: 1.75rem;
    }
    & .dln-category-browser {
      & .dln-category-browser__header {
        &--title {
          font-size: 1.313rem;
        }
      }
    }
    &__spellCheck {
      line-height: 1.33;
      font-size: 1.125rem;
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-instruction-manuals {
      &__mainTitle {
        line-height: 1.08rem;
        font-size: 2.313rem;
      }
      &__mainSubtitle {
        line-height: 1.1rem;
        font-size: 1.313rem;
      }
      & .dln-category-browser {
        & .dln-category-browser__header {
          &--title {
            font-size: 1.125rem;
          }
        }
      }
      &__helpSectionTitle {
        line-height: 1.11rem;
        font-size: 1.125rem;
      }
      &__spellCheck {
        line-height: 1.5;
        font-size: 1rem;
      }
    }
  }
}

.dln-delonghi {
  .dln-serviceLocator {
    &__title {
      font-size: 3.125rem;
      line-height: 1.04;
    }
    &__aside {
      .dln-accordion {
        &__title {
          font-size: 1rem;
        }
      }
    }
    &__resultCounter {
      font-size: 0.875rem;
    }
  }
}

@media only screen and (min-width: 599px) and (max-width: 900px) {
  .dln-delonghi {
    & .dln-serviceLocator {
      &__mobileBnt {
        .dln-btnIcon__checkoutCounter {
          font-size: 8rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-serviceLocator {
      &__mobileBnt {
        .dln-btnIcon__checkoutCounter {
          font-size: 8rem;
        }
      }
      & .dln-quickLinks--slider {
        & .dln-quickLink {
          &__title {
            font-size: 1rem;
          }
          &__link {
            font-size: 0.875rem;
          }
        }
      }
    }
  }
}

.dln-delonghi {
  .dln-terms {
    &__note {
      font-size: 1rem;
      line-height: 1.5;
    }
    &__subTitle {
      font-size: 1.125rem;
      line-height: 1.07;
    }
    &__paragraph {
      font-size: 0.875rem;
      line-height: 1.3;
    }
    .dln-title--delonghi {
      font-size: 3.125rem;
    }
  }
}

.dln-delonghi {
  .dln-page--imageHeader__header {
    .dln-mainTitle {
      font-size: 3.125rem;
      line-height: 1.04;
    }
  }
  .dln-unsubscribePage {
    .dln-page__title {
      font-size: 1.313rem;
      line-height: 1.12;
    }

    .dln-page__paragraph {
      font-size: 1.125rem;
      line-height: 1.07;
    }
    .dln-page__text {
      font-size: 1.125rem;
      line-height: 1.33;
    }
  }

  @media only screen and (max-width: 599px) {
    .dln-unsubscribePage {
      .dln-page__title {
        font-size: 1.125rem;
        line-height: 1.11rem;
      }

      .dln-page__paragraph {
        font-size: 1rem;
        line-height: 1.13;
      }
      .dln-page__text {
        font-size: 1rem;
        line-height: 1.5;
      }

      .dln-wrapper {
        &.dln-wrapper--content {
          .dln-page__title {
            font-size: 1.125rem;
            line-height: 1.11rem;
          }

          .dln-page__actions {
            .dln-btn {
              font-size: 1.125rem;
              line-height: 1.33;
            }
          }
        }
      }
    }
  }
}

.dln-delonghi {
  & .dln-wishlist.dln-wishlist--public {
    & .dln-wrapper {
      & .dln-wishlist__content {
        & .dln-wishlist__signInNotice {
          line-height: 1.5;
          font-size: 1rem;

          & > .dln-btn--link {
            line-height: 1.5;
            font-size: 1rem;
          }
        }
      }
    }
  }
}

.dln-delonghi {
  .dln-recipe-by-ingredients {
    &__title {
      font-size: 2.313rem;
      line-height: 1;
    }

    @media only screen and (max-width: 599px) {
      &__title {
        font-size: 1.75rem;
        line-height: 1.07;
      }

      .dln-recipe__title {
        a {
          font-size: 1.125rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    .dln-recipe-by-ingredients {
      &__title {
        font-size: 1.75rem;
        line-height: 1.07;
      }
    }
  }
}

.dln-delonghi {
  .dln-recipe-by-products {
    &__title {
      font-size: 2.313rem;
      line-height: 1;
    }

    &__browse-product {
      font-size: 1.125rem;
      line-height: 1.33;
    }

    @media only screen and (max-width: 599px) {
      &__title {
        font-size: 1.75rem;
        line-height: 1.07;
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    .dln-recipe-by-products {
      &__title {
        font-size: 1.75rem;
        line-height: 1.07;
      }
    }
  }
}

.dln-delonghi {
  .dln-recipe-page {
    @media only screen and (max-width: 599px) {
      &__title {
        line-height: 1.08rem;
        font-size: 2.313rem;
      }
      .dln-recipe-details {
        .dln-wrapper {
          .dln-recipe-details__title-recipe {
            font-size: 1.75rem;
          }
        }
      }
    }
    &__title {
      line-height: 1.04;
      font-size: 3.125rem;
    }
    &__title-filters {
      line-height: 1.07;
      font-size: 1.125rem;
    }
    .dln-recipe-details__info-button-desktop {
      a {
        font-size: 1.125rem;
      }
    }
    .dln-filterDropdown__triggerText {
      font-size: 1rem;
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-recipe-page {
      &__title {
        line-height: 1.08rem;
        font-size: 2.313rem;
      }
      & .dln-recipe-details {
        .dln-wrapper {
          .dln-recipe-details__title-recipe {
            font-size: 1.75rem;
          }
        }
      }
    }
  }
}

.dln-delonghi {
  & .dln-recipe-single {
    @media only screen and (max-width: 599px) {
      .dln-recipe-single__title-content {
        font-size: 1.313rem;
      }

      .dln-recipe-single__servings-col {
        font-size: 1.125rem;
      }
    }
    & .dln-recipe-details {
      & .dln-wrapper {
        & .dln-recipe-details__grid {
          & .dln-recipe-details__col {
            & .dln-recipe-details__info {
              & .dln-recipe-details__title-recipe {
                line-height: 3.25rem;
                font-size: 3.125rem;
              }
            }
          }
        }
      }
    }
    & .dln-recipe-single__grid-info {
      & .dln-recipe-single__col {
        &-gray {
          & .dln-recipe-single__col-content {
            & .dln-recipe-single__accordion {
              &__content {
                & .dln-recipe-single__servings-section {
                  & .dln-recipe-single__servings-col {
                    & .dln-select {
                      & .dln-select__value {
                        font-size: 1.313rem;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    &__col-content {
      p {
        line-height: 1.5;
        font-size: 1rem;
      }
    }
    &__title-content {
      line-height: 1.21rem;
      font-size: 1.75rem;
    }
    &__step-title {
      line-height: 1.33;
      font-size: 1.125rem;
      span {
        line-height: 1.33;
        font-size: 1.125rem;
      }
    }
    &__products-links {
      &-title {
        line-height: 1.12;
        font-size: 1.313rem;
      }
      &-description {
        line-height: 1.3;
        font-size: 0.875rem;
      }
    }
    &__col-3.padding {
      a {
        line-height: 1.29rem;
        font-size: 0.875rem;
      }
    }

    .dln-recipe-single__servings-col {
      font-size: 1.125rem;
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-recipe-single {
      & .dln-recipe-single__product-attachment {
        & > .dln-recipe-single__col {
          & .dln-recipe-single__product {
            & .dln-recipe-single__col {
              & > .dln-recipe-single__product-content {
                line-height: 1.25;
                font-size: 1.125rem;
              }
            }
          }
        }
      }
    }
  }
}

.dln-delonghi {
  .dln-return-authorization {
    .dln-wrapper {
      .dln-registration {
        &__content {
          .dln-title {
            + .dln-registration__paragraph {
              font-size: 1rem;
            }
          }
        }
      }
    }
  }
}

.dln-delonghi {
  .dln-return-full-account {
    &__title {
      font-size: 3.125rem;
    }

    &__label {
      font-size: 1rem;
    }

    &__value {
      font-size: 1.125rem;
    }

    &__text {
      font-size: 1rem;
    }

    &__product-list {
      .dln-orderRev-input-wrapper {
        &__select-quantity {
          span {
            font-size: 0.875rem;
          }
          .dln-inputIncr {
            &__input {
              font-size: 1.125rem;
            }
          }
        }

        .dln-select__value,
        .dln-orderRev__notReturnSel {
          font-size: 0.875rem;
        }

        .dln-orderRev__notReturnText {
          font-size: 1rem;
          line-height: 1.5;
        }
      }
    }

    &__total-row {
      span {
        font-size: 1.125rem;
      }
    }

    &__button-wrap {
      .dln-btn,
      span {
        font-size: 1.125rem;
      }
    }
  }

  .dln-return-full-account-modal__thankYouMessageText {
    font-size: 1.125rem;
  }
}

@media screen and (max-width: 599px) {
  .dln-delonghi {
    .dln-return-full-account {
      &__title {
        line-height: 1.08rem;
        font-size: 2.313rem;
      }
    }

    .dln-popup__content.dln-return-full-account-modal__popup-content {
      .dln-return-full-account-modal__thankYouMessageText {
        font-size: 1rem;
      }
    }
  }
}

.dln-delonghi {
  .dln-return-order {
    &__title {
      font-size: 3.125rem;
    }

    .dln-totalSect,
    .dln-return-order-receipt,
    .dln-return-order-info {
      .dln-return-order-receipt__text,
      .dln-return-order-info__title {
        font-size: 1rem;
      }

      h3 {
        font-size: 1.313rem;
      }

      .dln-return-order-info__description,
      .dln-btn--link {
        font-size: 0.875rem;
      }
    }

    &-returned {
      &__box {
        &-title {
          font-size: 1rem;
        }
        &-description {
          font-size: 0.875rem;
        }
      }

      .dln-return-order {
        &__header {
          & .dln-return-order__section-title {
            font-size: 1.313rem;
            line-height: 1.5;
          }
        }
      }
      & .dln-return-order-returned__box {
        &-title {
          line-height: 1.5;
        }
      }
    }
  }
}

.dln-delonghi {
  .dln-site-search {
    .dln-title,
    &__no-results-title {
      font-size: 3.125rem;
      line-height: 1.04;
    }

    &__results {
      font-size: 1rem;
      line-height: 1.5;
    }

    .dln-tabs__list.swiper-wrapper {
      .dln-tabs__btn {
        font-size: 1.125rem;
      }
    }

    .dln-icn.dln-icn--iconfont.dln-icn--check-circle.dln-icn--T {
      font-size: 1.25rem;
    }

    .dln-site-search__no-results-paragraph {
      font-size: 1.125rem;
    }

    @media only screen and (max-width: 599px) {
      .dln-site-search__no-results-title {
        font-size: 2.313rem;
      }

      .dln-site-search__no-results-paragraph {
        font-size: 1rem;
      }
    }
  }
}

.dln-delonghi {
  & .dln-radioBtn {
    &__lbl {
      line-height: 1.375rem;
      font-size: 0.875rem;
    }
  }
}

.dln-delonghi {
  .dln-breadcrumbs {
    font-size: 0.938rem;
    line-height: 1.313;

    &__li {
      a,
      span {
        font-size: 0.938rem;
        line-height: 1.313;
      }
    }
  }
}

.dln-delonghi {
  & .dln-chips {
    &--extraLight {
      & .dln-chips__content {
        font-size: 0.875rem;
      }
    }
  }
}

.dln-delonghi {
  & .dln-filterDropdown {
    &__label {
      font-size: 1rem;
    }
    &__actions {
      & .dln-btn--reset {
        line-height: 1.125;
        font-size: 0.875rem;
      }
      & .dln-btn--apply {
        line-height: 2rem;
        font-size: 0.938rem;
      }
    }
  }
}

.dln-delonghi {
  & .dln-list {
    &Item {
      & .dln-icn--check-circle {
        font-size: 1.25rem;
      }
      & .dln-listLink {
        line-height: 1.25;
      }
    }
  }
}

.dln-delonghi {
  .dln-search-result {
    &__category {
      font-size: 0.875rem;
      line-height: 1;
    }
    &__detailsTitle {
      font-size: 1.188rem;
      line-height: 1;
    }
    & .dln-ratingStars .dln-icn {
      &:before {
        font-size: 0.938rem;
      }
    }
    &__priceWrapper {
      .dln-price {
        font-size: 1rem;
        line-height: 1.13;

        &--discounted {
          font-size: 1.188rem;
          line-height: 0.95;
        }
      }
    }
  }
}

.dln-delonghi {
  .dln-accordion {
    &--item-review {
      & .dln-accordion__head {
        & .dln-accordion__title {
          line-height: 1.188;
          font-size: 1.188rem;
        }
      }
      & .dln-accordion__content {
        & .dln-price {
          line-height: 1;
          font-size: 0.813rem;
        }
      }
    }
    & .dln-accordion__title {
      line-height: 1.5;
      font-size: 1.125rem;
    }
  }
}

@media only screen and (max-width: 900px) {
  .dln-delonghi {
    .dln-accordion {
      &--item-review {
        .dln-accordion__head {
          .dln-accordion__title {
            line-height: 1;
            font-size: 1rem;
          }
        }
      }
    }
  }
}

.dln-delonghi {
  .dln-addBox {
    .dln-icn {
      &::before {
        font-size: 1rem;
      }
    }
    &__title {
      font-size: 1.75rem;
      line-height: 2.125;
    }
  }
}

.dln-delonghi {
  .dln-orderRev {
    &__productCode {
      font-size: 0.875rem;
    }
    .dln-orderRev__detailsTitle {
      font-size: 1.188rem;
      line-height: 1.3;
    }
    .dln-orderRev__sku {
      font-size: 0.875rem;
      line-height: 0.875rem;
    }
    .dln-orderRev__desc {
      font-size: 0.875rem;
      line-height: 1.125;
    }

    .dln-orderRev__wrap {
      .dln-price--oldPrice {
        font-size: 1rem;
        line-height: 1.125;
      }
      .dln-price--discounted {
        font-size: 1.188rem;
        line-height: 1.125;
      }
      &.dln-orderRev__wrap--delivery {
        font-size: 0.813rem;
        line-height: 1;
      }
    }
    .dln-orderRev__actions {
      .dln-btn--action-done {
        font-size: 0.875rem;
        line-height: 1.125;
      }
    }

    &--registeredproduct {
      .dln-orderRev__details {
        .dln-orderRev__wrap {
          .dln-orderRev__purchaseDate {
            font-size: 0.813rem;
            line-height: 1;
          }
        }
      }
      .dln-orderRev__actions {
        @media only screen and (max-width: 599px) {
          .dln-btn {
            &:not(.dln-btn--stroked) {
              font-size: 0.875rem;
              line-height: 1.125;
            }
          }
        }
      }
    }

    &--productDescription {
      .dln-orderRev {
        &__desc {
          font-size: 0.875rem;
          line-height: 1.3;
        }
        &__sku {
          font-size: 0.875rem;
          line-height: 1.3;
        }
      }
    }

    @media only screen and (max-width: 599px) {
      &--cart-order {
        .dln-orderRev__productCode {
          font-size: 0.813rem;
        }
      }
      .dln-orderRev__detailsTitle {
        font-size: 1rem;
      }
      .dln-orderRev__sku {
        font-size: 0.813rem;
      }
    }
  }
}

.dln-delonghi {
  .dln-recipe {
    &__title {
      a {
        font-size: 1.313rem;
        line-height: 1.14rem;
      }

      @media only screen and (max-width: 599px) {
        font-size: 1.125rem;
        line-height: 1.25;
      }
    }
  }
}

.dln-delonghi {
  .dln-marketingPreferences {
    .dln-page__sectionHeadTitle {
      font-size: 2.313rem !important;
      line-height: 1 !important;
    }
    .dln-page__sectionHeadInfoText {
      font-size: 1rem !important;
      line-height: 1.5rem !important;
    }

    .dln-page__content .dln-title.dln-title--delonghi {
      font-size: 3.125rem;
      line-height: 1.04;
    }

    .dln-checkbox--interactive {
      .dln-checkbox {
        &__tooltipWrap {
          font-size: 0.813rem;
          line-height: 1.23rem;
        }
        &__confirmWrap .dln-checkbox__confirmText {
          font-size: 0.875rem;
          line-height: 1.3;
        }
      }
    }

    .dln-page__sectionFooter {
      .dln-btn {
        font-size: 1.125rem;
      }
    }

    @media only screen and (max-width: 599px) {
      .dln-page__sectionHeadTitle {
        font-size: 1.75rem !important;
        line-height: 1.07 !important;
      }
      .dln-page__sectionHeadInfoText {
        font-size: 0.938rem !important;
        line-height: 1.6rem !important;
      }

      .dln-checkbox--interactive {
        .dln-checkbox {
          &__tooltipWrap {
            font-size: 0.688rem;
            line-height: 1.45;
          }
        }
      }

      .dln-marketingPreferences {
        &__sub-title {
          font-size: 1.313rem;
          line-height: 1.1rem;
        }
      }

      .dln-page__content .dln-title.dln-title--delonghi {
        font-size: 2.313rem;
        line-height: 1.08rem;
      }
    }
  }
}

.dln-delonghi {
  .dln-savedCart {
    &__cart {
      &Title {
        font-size: 1.75rem;
        line-height: 2.125;
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .dln-delonghi {
    .dln-savedCart {
      &__cart {
        &Title {
          font-size: 1.313rem;
          line-height: 1.438;
        }
      }
    }
  }
}

.dln-delonghi {
  .dln-page .dln-wrapper .dln-page__content .dln-savedRec__subtitle {
    font-size: 1rem;
    line-height: 1.5;

    @media only screen and (max-width: 599px) {
      font-size: 1rem;
      line-height: 1.5;
    }
  }
}

.dln-delonghi {
  .dln-popup {
    &__head {
      &Title {
        font-size: 1.75rem;
        line-height: 2.125;

        @media only screen and (max-width: 599px) {
          font-size: 1.313rem;
          line-height: 1.438;
        }
      }

      &--bigTitle {
        .dln-popup__headTitle {
          @media only screen and (max-width: 599px) {
            font-size: 2.313rem;
            line-height: 2.5rem;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-popup {
      & .dln-popup__content {
        & .dln-popup__wrapper {
          & .dln-addAccessories {
            &__content {
              .dln-cartItem__title {
                font-size: 1.188rem;
              }
            }
          }
        }
      }
    }
  }
}

.dln-delonghi {
  & .dln-popup {
    &__content {
      & .dln-popup__wrapper {
        & .dln-faqAnnouncement {
          & .dln-popup__head {
            & .dln-popup__headTitle {
              line-height: 2.313rem;
              font-size: 2.313rem;
            }
          }
          &__content {
            & .dln-faqAnnouncement__text {
              line-height: 1.5;
              font-size: 1rem;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-popup {
      &__content {
        & .dln-popup__wrapper {
          & .dln-faqAnnouncement {
            & .dln-popup__head {
              & .dln-popup__headTitle {
                line-height: 1.875;
                font-size: 1.75rem;
              }
            }
            &__content {
              & .dln-faqAnnouncement__text {
                font-size: 0.938rem;
              }
            }
          }
        }
      }
    }
  }
}

.dln-delonghi {
  .dln-login-wishlist {
    .dln-popup__headTitle {
      font-size: 1rem;
      line-height: 1.5;
    }

    .dln-loginForm {
      &__paragraph {
        font-size: 0.875rem;
        line-height: 1.3;
      }

      &__subtitle {
        font-size: 1.125rem;
        line-height: 1.11rem;
      }

      .dln-btn {
        &--secondary {
          font-size: 0.875rem;
          line-height: 1.3;
        }
      }
    }
  }
}

.dln-delonghi {
  & .dln-shareWishList {
    & .dln-popup__head {
      &Title {
        line-height: 2rem;
        font-size: 1.625rem;
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-shareWishList {
      & .dln-popup__head {
        &Title {
          line-height: 1.438;
          font-size: 1.313rem;
        }
      }
    }
  }
}

.dln-delonghi {
  .dln-checkbox {
    &__label {
      font-size: 0.813rem;
      line-height: 1;
    }
  }
}

.dln-delonghi {
  .dln-inputs {
    &--active {
      & .dln-inputs__lbl {
        line-height: 0.75rem;
        font-size: 0.75rem;
      }
    }
    &--disabled {
      & .dln-inputs__input {
        &[value] {
          line-height: 1.125;
          font-size: 0.875rem;
        }
      }
    }
    &__input {
      line-height: 1.125;
      font-size: 0.875rem;
    }
    &__lbl {
      line-height: 1;
      font-size: 0.875rem;
    }
    &__extraLbl {
      font-size: 0.75rem;
    }
    &--searchBar {
      & .dln-inputs__button {
        & .dln-icn {
          line-height: 1.563rem;
          font-size: 0;

          &::before {
            font-size: 1.563rem;
          }
        }
      }
    }
    &--selectInside {
      .dln-inputs__lbl {
        line-height: 0.75rem;
        font-size: 0.75rem;
      }
    }
  }
}

.dln-delonghi {
    .dln-alert {
        &--success, &--error {
            .dln-popup__headTitle {
                font-size: 1.063rem;
                line-height: 1.25rem;
            }
        }
    }
}
