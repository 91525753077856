.del-product-moodboard {
  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include bp('del-breakpoint-s') {
      flex-direction: row;
    }

    &.--hidden {
      display: none;
    }

    &__item {
      @include bp('del-breakpoint-s') {
        width: calc(50% - 10px);
      }

      &.--primary {
        margin-bottom: 20px;
        @include bp('del-breakpoint-s') {
          margin-bottom: 0;
        }
      }

      &.--secondary {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        > .del-product-moodboard__wrapper__item__secondary-section {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          &:first-child {
            margin-bottom: 20px;
          }
        }
      }
    }

  }
}
