// mobile carousel
.del-product-moodboard {
  &__navigation {
    margin-bottom: 16px;

    @include bp('del-breakpoint-m') {
      position: absolute;
      left: -9999px;
      width: 0;
      height: 0;
    }

    .swiper-slide {
      width: auto !important;
      font-size: REM(21px);
      line-height: normal;
      color: $del-color-text-60;
      cursor: pointer;
      @include del-font-medium;
      &-active {
        color: $del-color-red; // $del-color-text;
        border-bottom: 3px solid $del-color-red; // 3px $del-color-text;
      }
    }
  }
}

.del-product-moodboard {
  &__products {
    // override
    & .swiper-pagination-bullets-dynamic .swiper-pagination-bullets,
    &.swiper-container-horizontal>.swiper-pagination-bullets,
    &.swiper-container-horizontal>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
      z-index: 0;

      .swiper-pagination-bullet {
        margin: 0 15px;
        border-bottom: 3px solid transparent;
        transform: scale(1);

        &-active {
          color: $del-color-red;
          border-bottom-color: $del-color-red;
        }

        &:focus {
          outline: 3px solid $del-color-red;
        }
      }

      .swiper-pagination-bullet-active-prev,
      .swiper-pagination-bullet-active-prev-prev,
      .swiper-pagination-bullet-active-next,
      .swiper-pagination-bullet-active-next-next {
        transform: scale(1);
      }
    }

    .swiper-pagination {
      position: relative;
      margin: 10px 0 17px;
      display: block;
      cursor: grab;
      overflow: visible;

      @include bp('del-breakpoint-m') {
        margin-bottom: 22px;
      }

      @include bp('del-breakpoint-l') {
        margin-bottom: 24px;
      }

      &-bullet {
        width: auto;
        height: auto;
        background: transparent;
        font-size: REM(18px);
        line-height: normal;
        padding-bottom: 3px !important;
        color: $del-color-text-60;
        cursor: pointer;
        opacity: 1;
        border-radius: 0;
        @include del-font-medium;

        @include bp('del-breakpoint-m') {
          font-size: REM(21px);
        }

        @include bp('del-breakpoint-l') {
          font-size: REM(24px);
        }
      }
    }
  }
}
