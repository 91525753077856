
.del-plp-promo-banner {
  &-container-smartslot {
    display: none;

    @include bp('del-breakpoint-m') {
      display: flex;
      width: 50%;
      border: 1px dotted transparent;

      &:hover {
        border-color: $del-color-grey;
      }
    }
  }

  &-doublebox {
    display: none;

    @include bp('del-breakpoint-m') {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 50%;
      padding: 15px;
      background-color: white;

      .del-plp-promo-image-box {
        $promobox-height-ratio: 0.23571428571;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-height: calc(100vw * #{$promobox-height-ratio});
        @media (min-width: 1400px) {
          max-height: calc(1400px * #{$promobox-height-ratio});
        }
        overflow: hidden;

        img.del-plp-promo-image {
          width: 100%;
        }
      }
    }

    .del-plp-promo-link {
      @include del-button;
      @include del-button--big;
      @include del-button--arrow-right;
      @include del-button--arrow-right-big;
      padding-top: 20px;
      font-size: 1.188rem;
      letter-spacing: normal;
    }

  }

  &-smartwrapper,
  &-smartwrapper &-doublebox {
    width: 100%;
  }
}

