.del-moodboard__texts {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 30px;
  padding-inline: 10px;

  &.--w-cta {
    @include bp('del-breakpoint-xl') {
      transform: translateY(40px);
      transition: transform 0.2s linear;
      .del-extra-button {
        opacity: 0;
        transition: opacity 0.2s 0.2s linear;
      }
      &:hover {
        transform: translateY(0);
        .del-extra-button {
          opacity: 1;
        }
      }
    }
  }

  .del-textgroup__subtitle {
    @include del-font-medium;
    font-size: REM(14px);
    opacity: 0.6;
    letter-spacing: 2px;
  }

  .del-textgroup__title {
    @include del-font-medium;
    font-size: REM(26px);
    line-height: 1.12;

    @include bp('del-breakpoint-m') {
      line-height: 1.38;
    }

    @include bp('del-breakpoint-xl') {
      font-size: REM(32px);
      line-height: 1.13;
    }
  }
  .del-textgroup__content {
  }

  .del-textgroup__cta--medium {
    @include del-font-medium;
    font-size: REM(18px);
    line-height: 1.17;

    &:before {
      width: 18px;
      height: 18px;
      background-size: 14px;
    }
  }

  .del-extra-button {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
