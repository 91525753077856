$closeSize: 24px;

.del-modal {
  &__header {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-bottom: 1px solid $del-color-grey;
    padding: 0 10px;
  }

  &__close {
    border: none;
    background: none;
    width: $closeSize;
    height: $closeSize;
    cursor: pointer;
    i {
      width: $closeSize;
      height: $closeSize;
    }
  }
}
