@mixin textgroup-subtitle--primary {
  font-size: 0.688rem;
  letter-spacing: 2.24px;
  margin: 4px 0;
  width: 100%;

  @include bp('del-breakpoint-xl') {
    font-size: 0.875rem;
    letter-spacing: 2.88px;
  }
}
@mixin textgroup-title--primary {
  font-size: 2rem;
  letter-spacing: -0.656px;
  line-height: 1.875rem;
  margin: 4px 0 7px;

  @include bp('del-breakpoint-xs') {
    font-size: 2.75rem;
    letter-spacing: -0.88px;
    line-height: 2.5rem;
    margin: 5px 0 12px;
  }

  @include bp('del-breakpoint-l') {
    font-size: 3.625rem;
    letter-spacing: -1.168px;
    line-height: 3.375rem;
    margin: 10px 0 17px;
  }

  @include bp('del-breakpoint-xl') {
    font-size: 4.5rem;
    letter-spacing: -1.44px;
    line-height: 4.125rem;
    margin: 12px 0 23px;
  }
}
@mixin textgroup-content--primary {
  font-size: 1rem;
  line-height: 1.188rem;
  margin: 5px 0 10px;

  @include bp('del-breakpoint-xs') {
    font-size: 1.063rem;
    line-height: 1.313rem;
  }

  @include bp('del-breakpoint-xl') {
    font-size: 1.438rem;
    line-height: 1.75rem;
    margin: 8px 0 18px;
  }
}


.textgroup-subtitle--primary {
  @include textgroup-subtitle--primary;
}
.textgroup-title--primary {
  @include textgroup-title--primary;
}
.textgroup-content--primary {
  @include textgroup-content--primary;
}

@mixin textgroup--primary {
  .del-textgroup {
    &__subtitle {
      @extend .textgroup-subtitle--primary;
      p {
        @extend .textgroup-subtitle--primary;
      }
    }

    &__title {
      @extend .textgroup-title--primary;
      p {
        @extend .textgroup-title--primary;
      }
    }

    &__content {
      @extend .textgroup-content--primary;
      p {
        @extend .textgroup-content--primary;
      }
    }
  }
}
