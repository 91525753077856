.del-pdp__compare {
  padding: 0;

  &__title {
    @include del-font-medium;
    font-size: REM(28px);
    line-height: 28px;
    letter-spacing: 0;
    margin-bottom: 10px;
    text-align: center;

    @include bp('del-breakpoint-l') {
      font-size: REM(37px);
      line-height: 37px;
    }
  }

  &__table {

    &-row,
    &-key,
    &-value {
      display: flex;
      justify-content: center;
    }

    &-row {
      flex-wrap: wrap;
      border-top: 1px solid $del-color-grey;
      @include bp('del-breakpoint-m') {
        &:nth-child(odd) {
          background-color: $del-color-white-smoke;
        }
        &:nth-child(even) {
          background-color: $del-color-white;
        }
      }
    }

    &-key,
    &-value {
      padding: 5px;
      text-align: center;
      color: $del-color-text;
      min-height: 40px;
      @include bp('del-breakpoint-m') {
        min-height: 55px;
      }
    }

    &-key {
      @include del-font-medium;
      font-size: 1rem;
      line-height: 1rem;
      letter-spacing: .5px;
      width: 100%;
      justify-content: flex-start;
      padding-left: 5px;
      background-color: $del-color-white-smoke;
      align-items: center;
      @include bp('del-breakpoint-m') {
        width: 34%;
        padding-left: 0;
        justify-content: center;
        background-color: transparent;
      }
    }

    &-value {
      @include del-font-book;
      font-size: 0.875rem;
      line-height: 1rem;
      letter-spacing: normal;
      align-items: center;

      width: 50%;
      background-color: $del-color-white;

      @include bp('del-breakpoint-m') {
        background-color: transparent;
        font-size: 1rem;
        width: 33%;
      }

      &.--current {
        border-right: 1px solid $del-color-grey;
        @include bp('del-breakpoint-m') {
          order: -1;
          border-right: 0;
        }
      }

      &[hidden] {
        display: none;
      }

      .icon-ok-blue {
        width: 22px;
        height: 22px;
      }

      .del-pdp-spec-class {
        width: 62px;
        height: 28px;
      }
    }

    @include bp('del-breakpoint-max-m', 'max') {
      &__container {
        position: relative;
        border-bottom: 1px solid $del-color-grey;
        &.--toggle {
          max-height: 115px;
          overflow: hidden;

          &.--open {
            max-height: unset;
          }

          &:not(.--open) {
            .del-pdp__compare__table__mask {
              position: absolute;
              bottom: 0;
              width: 100%;
              height: 60px;
              background-image: linear-gradient(to bottom, rgba($del-color-white, .3), rgba($del-color-white, .8));
            }
          }
        }
      }
    }

    &__show-cta {
      @include del-font-medium;
      display: block;
      margin: 10px auto 0;
      @include bp('del-breakpoint-m') {
        display: none;
      }
    }

  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;

    > .del-product-box,
    > .del-pdp__compare__slider {
      width: 50%;
      padding: 0 3px;
      @include bp('del-breakpoint-m') {
        width: 23.57%;
        order: 1;
      }
    }

    > .del-product-box {
      border-right: 1px solid $del-color-grey;
      @include bp('del-breakpoint-m') {
        border-right: 0;
      }
    }

    > .del-pdp__compare__slider {
      @include bp('del-breakpoint-m') {
        order: 3;
      }

      .swiper-slide {
        padding: 0;

        .del-button {
          display: none;
          margin: 10px auto;
          width: 100%;
          @include bp('del-breakpoint-m') {
            width: 55%;
          }
        }
      }

      .swiper-button-next,
      .swiper-button-prev {
        &::after {
          font-size: 1.125rem;
        }
      }

      .swiper-button-disabled {
        display: none;
      }
    }

    > .del-pdp__compare__table {
      width: 100%;
      @include bp('del-breakpoint-m') {
        width: 52.86%;
        order: 2;
        align-self: center;
      }
    }

  }
}
