.del-pdp__info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 0;

  @include bp('del-breakpoint-m') {
    flex-direction: row;
  }

  &__text {
    width: 100%;
    padding: 0 15px;

    @include bp('del-breakpoint-s') {
      padding: 0 40px;
    }
    @include bp('del-breakpoint-m') {
      padding: 0 16px;
    }

    &__wrapper {
      border-bottom: 0;

      @include bp('del-breakpoint-max-m', 'max') {
        max-height: 100px;
        overflow: hidden;
        position: relative;
        transition: max-height 0.5s ease-in-out;
      }

      &:after {
        @include bp('del-breakpoint-max-m', 'max') {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 30px;
          background: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 1)
          );
        }
      }
    }
  }

  &__title,
  &__content {
    color: $del-color-text;
  }

  &__title {
    @include del-font-medium;
    font-size: REM(14px);
    line-height: 15px;
    letter-spacing: 1.7px;
    padding: 20px 0;

    @include bp('del-breakpoint-m') {
      @include del-font-heavy;
      font-size: REM(13px);
    }
  }

  &__content {
    &.--description,
    &.--description p,
    &.--summary,
    &.--summary p {
      @include del-font-book;
    }

    &.--description,
    &.--description p {
      font-size: REM(16px);
      line-height: 28px;

      @include bp('del-breakpoint-m') {
        font-size: REM(20px);
      }
    }

    &.--summary,
    &.--summary p {
      font-size: REM(16px);
      line-height: 28px;

      @include bp('del-breakpoint-m') {
        font-size: REM(20px);
      }
    }

    ul {
      padding: 0 0 0 35px;
      margin: 0;
    }
  }

  &__image {
    display: block;
    max-width: 100%;
    margin-top: 20px;
    @include bp('del-breakpoint-s') {
      padding: 0 40px;
    }
    @include bp('del-breakpoint-m') {
      width: 57.14%;
      padding: 0;
      margin-top: 0;
    }
  }
  &__toggle-read-more {
    display: none;

    &:checked + .del-pdp__info__text__wrapper {
      max-height: 1000px;
    }
    &:checked + .del-pdp__info__text__wrapper::after {
      display: none;
    }
    &:checked
      + .del-pdp__info__text__wrapper
      + .del-pdp__info__read-more-button {
      display: none;
    }

    &:checked
      + .del-pdp__info__text__wrapper
      ~ .del-pdp__info__close-more-button {
      display: block;
    }
  }

  &__read-more-button,
  &__close-more-button {
    cursor: pointer;
    color: $del-color-dark-blue;
    text-decoration: underline;
    display: block;
    margin-top: 15px;
    text-align: start;
    width: 100%;
    padding: 0 15px;

    @include bp('del-breakpoint-m') {
      display: none !important;
    }
  }

  &__close-more-button {
    display: none;
  }
}

// simple-css-accordion customization
.del-pdp__info {
  .del-simple-css-accordion {
    &__title {
      background-color: $del-color-white;
    }

    &__plus {
      display: block;
      @include bp('del-breakpoint-l') {
        display: none;
      }
    }

    &__content {
      position: absolute;
      visibility: hidden;
      z-index: 1;
      transform: translateY(-100px);
      @include bp('del-breakpoint-l') {
        position: relative;
        visibility: visible;
        transform: none;
        transition: all 300ms ease;
      }
    }
  }
}
