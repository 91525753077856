.del-loadMore {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px 0;

  &__text {
    margin: 15px 0;

    @include del-font-book;
    font-size: 1rem;
  }
}
