.del-plp-filters {
  width: 100%;
  padding: 15px 0;
  margin: 10px 0;

  &__header {
    @include del-font-medium;
    text-align: center;
    margin-bottom: 15px;
    padding: 0 15px;
    font-size: 1.75rem;

    @include bp('del-breakpoint-l') {
      text-align: left;
    }
  }

  &__header-count {
    color: $del-color-text-60;
  }

  &__inner-wrapper {
    display: flex;
    flex-direction: column;

    @include bp('del-breakpoint-l') {
      border-bottom: 1px solid $del-color-grey;
    }
  }

  &__buttons-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid $del-color-grey;

    @include bp('del-breakpoint-l') {
      border-bottom: none;
    }
  }

  &__selected-filters-menu {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    overflow-x: scroll;
    white-space: nowrap;
    padding: 15px 0 15px 15px;
    border-bottom: 1px solid $del-color-grey;

    @include bp('del-breakpoint-l') {
      border-bottom: none;
    }
  }

  &__selected-filter-item {
    background-color: $del-color-light-grey;
    padding: 5px 10px;
    margin-right: 10px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 1rem;

    @include del-font-book;
  }

  &__selected-filter-text {
    display: flex;
    align-items: center;
  }

  &__selected-filter-close-icon {
    width: 16px;
    height: 16px;
    margin: 0 5px;
  }

  &__selected-filter-clear-all {
    font-size: 1rem;
    text-decoration: underline;
    cursor: pointer;
    margin: 0 5px;
  }

  &__filter-wrapper,
  &__sort-wrapper,
  &__view-wrapper {
    position: relative;
    width: 33%; // needed to align the sort menu
    display: flex;

    @include bp('del-breakpoint-l') {
      width: auto;
    }
  }

  &__sort-wrapper {
    justify-content: center;
    @include bp('del-breakpoint-s') {
      justify-content: flex-end;
    }
  }

  &__view-wrapper {
    justify-content: flex-end;

    @include bp('del-breakpoint-s') {
      display: none;
    }
  }

  &__filter-button,
  &__sort-button,
  &__view-button {
    display: flex;
    align-items: center;
    cursor: pointer;

    @include del-font-medium;
    font-size: 1rem;
  }

  &__filter-button {
    @include bp('del-breakpoint-l') {
      display: none;
    }
  }

  &__filter-button-icon,
  &__sort-button-icon,
  &__view-button-icon {
    margin: 0 10px;
  }

  &__filter-button-icon {
    width: 25px;
    height: 25px;
  }

  &__sort-button-icon {
    width: 16px;
    height: 16px;
  }

  &__view-button-icon {
    width: 36px;
    height: 22px;
  }

  &__sort-mobile-label {
    @include bp('del-breakpoint-l') {
      display: none;
    }
  }

  &__sort-desktop-label {
    display: none;
    @include bp('del-breakpoint-l') {
      display: inline;
    }
  }

  &__sort-desktop-label-selected {
    @include del-font-medium;
  }

  &__sort-menu {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 40px;
    width: 100vw;
    padding: 20px;
    background-color: $del-color-white;
    z-index: 1;
    box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.07);

    @include bp('del-breakpoint-s') {
      left: auto;
      right: 0;
      transform: none;
      top: 30px;
      width: auto;
      min-width: 220px;
      border: 1px solid $del-color-light-grey;
      border-radius: 4px;
      box-shadow: 1px 1px 4px 2px rgba(0,0,0,0.07);
    }

    &--hidden {
      display: none;
    }
  }

  &__sort-menu-row {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px 0;
    font-size: 1.125rem;

    @include bp('del-breakpoint-l') {
      font-size: 1rem;

      &:hover {
        color: $del-color-text-60;
      }
    }

    &--active {
      @include del-font-medium;

      &:hover {
        color: $del-color-black;
      }
    }
  }

  &__sort-menu-row-icon {
    width: 25px;
    height: 25px;
    margin: 0 10px;

    @include bp('del-breakpoint-l') {
      display: none;
    }
  }

  &__sort-menu-close-row {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    @include bp('del-breakpoint-l') {
      display: none;
    }
  }

  &__sort-menu-close {
    cursor: pointer;
  }

  &__sort-menu-close-icon {
    width: 24px;
    height: 24px;
  }

  &__sort-menu-shadow {
    display: none; // to remove
    height: 100vh;
    position: absolute;
    bottom: -100vh;
    left: -20px;
    right: -20px;
    background-color: rgba(0,0,0,.5);

    @include bp('del-breakpoint-l') {
      display: none;
    }
  }

  &__filters-menu-mobile {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    min-height: 100%;
    max-height: 100%;
    width: 100%;
    overflow-y: scroll;
    background-color: $del-color-white;

    @include bp('del-breakpoint-l') {
      display: none;
    }
  }

  &__filters-menu-mobile-close-row {
    display: flex;
    justify-content: flex-end;
    padding: 10px 15px;
    border-bottom: 1px solid $del-color-light-grey;

    &--with-filters {
      justify-content: space-between;
    }
  }

  &__filters-menu-mobile-close {
    cursor: pointer;
  }

  &__filters-menu-mobile-close-icon {
    height: 24px;
    width: 24px;
  }

  &__filters-menu-desktop {
    display: none;

    @include bp('del-breakpoint-l') {
      display: flex;
      align-items: center;
    }
  }

  &__filters-menu-desktop-label {
    font-size: 1rem;
  }

  &__filters-clear-all {
    font-size: 1.125rem;
    text-decoration: underline;

    @include del-font-medium;
  }

  &__filters-apply-row {
    display: flex;
    justify-content: center;
    padding: 15px;
  }

  &__filters-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__filter {
    border-bottom: 1px solid $del-color-light-grey;

    @include bp('del-breakpoint-l') {
      position: relative;
      border: none;
    }
  }

  &__filter-title {
    width: 100%;
    padding: 15px;
    text-align: left;
    cursor: pointer;
    font-size: 1.313rem;

    @include del-font-medium;

    @include bp('del-breakpoint-l') {
      display: flex;
      align-items: center;
      font-size: 1rem;
      padding: 0 15px;
      border-right: 1px solid $del-color-light-grey;
    }
  }

  &__filter-menu {
    @include bp('del-breakpoint-l') {
      position: absolute;
      padding: 10px 0;
      background-color: $del-color-white;
      min-width: 330px;
      border: 1px solid $del-color-light-grey;
      border-radius: 4px;
      box-shadow: 1px 1px 4px 2px rgba(0,0,0,0.07);
      z-index: 1;
    }
  }

  &__filter-item {
    padding: 15px;
    display: flex;
    align-items: center;
  }

  &__filter-label {
    margin: 0 5px;
    font-size: 1.125rem;
    cursor: pointer;

    @include del-font-book;

    &--selected {
      @include del-font-medium;
    }
  }

  &__color-preview {
    height: 24px;
    width: 24px;
    border: 1px solid $del-color-white;
    border-radius: 50%;
    box-shadow: 0px 0px 0px 1px $del-color-text-80;

    @include bp('del-breakpoint-l') {
      height: 15px;
      width: 15px;
    }
  }

  &__filter-count {
    color: $del-color-text-60;
  }

  &__filter-item-apply-row {
    display: none;

    @include bp('del-breakpoint-l') {
      display: flex;
      justify-content: center;
      padding: 15px 0;
    }
  }
}
