.del-contentSlider {
  position: relative;

  &__labels {
    padding-top: 26px !important;
    padding-bottom: 20px !important;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;

    &.swiper-container-initialized {
      opacity: 1;
    }

    @include bp('del-breakpoint-l') {
      position: absolute !important;
      bottom: 35px;
      z-index: 2 !important;
      width: 100%;
      padding-block: 0 !important;
    }

    .--pagination-light & {
      .swiper-slide {
        color: $del-color-white;
        &-active {
          border-bottom-color: $del-color-white;
        }
      }
    }

    .swiper-container {
      margin-inline: auto;
    }

    .swiper-wrapper {
      &.--force-centered {
        justify-content: center;
        transform: none !important;
      }
    }

    .swiper-slide {
      @include del-font-medium;
      font-size: REM(21px);
      color: $del-color-text;
      opacity: 0.6;
      width: auto;
      padding-bottom: 7px;
      margin-inline: 21.5px;
      border-bottom: 2px solid transparent;
      cursor: pointer;

      @include bp('del-breakpoint-m') {
        margin-inline: 33.835px;
      }

      @include bp('del-breakpoint-l') {
        color: $del-color-white;
        font-size: REM(19px);
        margin-inline: 50px;
      }

      &-active {
        opacity: 1;
        border-bottom-color: $del-color-text;
        @include bp('del-breakpoint-l') {
          border-bottom-color: $del-color-white;
        }
      }
    }
  }

  &__heroes {
    .swiper-wrapper {
      position: relative;
      transform: translateX(calc((100% - 360px) / 2));
      padding-bottom: 30px;

      @include bp('del-breakpoint-s') {
        transform: translateX(calc((100% - 768px) / 2));
      }

      @include bp('del-breakpoint-m') {
        transform: translateX(calc((100% - 1024px) / 2));
      }

      @include bp('del-breakpoint-l') {
        transform: translateX(7.34375%); // 141/1920
        padding-bottom: 0;
      }
    }

    .swiper-slide {
      max-width: 360px;
      padding-inline: 16px;

      @include bp('del-breakpoint-s') {
        max-width: 768px;
        padding-inline: 40px;
      }

      @include bp('del-breakpoint-m') {
        max-width: 1024px;
        padding-inline: 70px;
      }

      @include bp('del-breakpoint-l') {
        max-width: 85.3125%;
        padding-inline: 0;
      }
    }
  }
}
