.del-pdp__main-info__promo {
  display: flex;
  gap: 4px;

  @include bp('del-breakpoint-m') {
    width: 385px;
    margin: 0 auto;
    margin-bottom: 10px;
  }

  @include bp('del-breakpoint-l') {
    max-width: 493px;
  }

  @include bp('del-breakpoint-xl') {
    width: 600px;
  }

  span {
    @include del-font-demi;
    background-color: var(--support-01--light);
    color: var(--brand-01);
    font-size: REM(12px);
    padding: 5px;
  }

  .percentage-discount-span {
    background-color: var(--brand-01);
    color: var(--brand-03);
  }
}
