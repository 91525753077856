$thumbs-wrapper-desktop-width: 94px;

.del-product-gallery {
  display: flex;
  flex-direction: column;
  width: 100%;
  opacity: 0;
  transition: opacity 0.3s ease;

  @include bp('del-breakpoint-m') {
    flex-direction: row;
    align-items: center;
    align-self: center;
    width: 66.0633484163%;
  }
}

// #################################
// MAIN SLIDER
// #################################
.del-product-gallery {
  .--main-wrapper {
    @include bp('del-breakpoint-m') {
      order: 1;
      width: calc(100% - #{$thumbs-wrapper-desktop-width});
      padding-inline: 30px;
    }

    @include bp('del-breakpoint-l') {
      padding-inline: 20px;
    }

    .--main-images-wrapper {
      max-width: 300px;
    }

    .--main-images-wrapper,
    .--main-video-wrapper {
      position: relative;
      margin-inline: auto;
      @include bp('del-breakpoint-s') {
        max-width: 100%;
      }
      @include bp('del-breakpoint-m') {
        max-width: 632px;
      }
    }

    .swiper-slide {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 260px;
        height: auto;
        object-fit: cover;
        cursor: pointer;
        @include bp('del-breakpoint-s') {
          width: 230px;
        }
        @include bp('del-breakpoint-m') {
          width: 391px;
        }
        @include bp('del-breakpoint-l') {
          width: 493px;
        }
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      z-index: 4;
      @include bp('del-breakpoint-m') {
        width: 32px;
        height: 32px;
        border: 1px solid var(--ui-02);
        border-radius: 50%;
        background-color: var(--brand-03);
      }
    }

    .swiper-button-prev {
      left: 0;
    }

    .swiper-button-next {
      right: 0;
    }
  }
}

// #################################
// THUMBS SLIDER
// #################################
.del-product-gallery {
  .--thumbs-wrapper {
    display: flex;
    margin-inline: auto;
    padding-block: 24px;
    position: relative;
    @include bp('del-breakpoint-m') {
      order: 0;
      padding: 0 7px;
      width: $thumbs-wrapper-desktop-width;
      flex-direction: column;
    }

    .--thumbs-wrapper-inner {
      position: relative;
    }

    .swiper-container {
      max-width: 190px;
      position: relative;

      // white transparent shadow only 0-1023 px
      @include bp('del-breakpoint-max-m', 'max') {
        &::before,
        &::after {
          position: absolute;
          top: 0;
          width: 54px;
          height: 54px;
          z-index: 2;
          pointer-events: none;
        }

        &[data-begin='false'] {
          &::before {
            content: '';
            background: linear-gradient(
              to right,
              $del-color-white,
              transparent
            );
            left: 0;
          }
        }

        &[data-end='false'] {
          &::after {
            content: '';
            background: linear-gradient(to left, $del-color-white, transparent);
            right: 0;
          }
        }
      }

      @include bp('del-breakpoint-m') {
        max-height: 344px;
        margin-block: 26px;
      }
    }

    .swiper-wrapper {
      @include bp('del-breakpoint-m') {
        flex-direction: column;
      }
    }

    .swiper-slide {
      max-width: 54px;
      max-height: 54px;
      margin-right: 14px;
      cursor: pointer;

      &:last-child {
        margin: 0;
      }

      img {
        display: block;
        max-width: 100%;
        height: auto;
        border: 2px solid var(--ui-01);
      }
      &.--active img {
        border-color: var(--brand-06);
      }

      @include bp('del-breakpoint-m') {
        max-width: 80px;
        max-height: 80px;
        margin-right: 0;
        margin-bottom: 8px;
      }
    }

    .--video-thumb {
      position: relative;
      width: 54px;
      height: 54px;

      @include bp('del-breakpoint-m') {
        width: 80px;
        height: 56px;
      }

      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
      }

      &.--border {
        margin-left: 14px;
        padding-left: 14px;
        width: calc(54px + 14px);
        border-left: 2px solid var(--ui-01);

        @include bp('del-breakpoint-m') {
          margin: 0;
          padding: 0;
          margin-top: 14px;
          padding-top: 14px;
          border: 0;
          width: 80px;
          height: calc(56px + 15px);
          border-top: 1px solid var(--ui-01);
        }
      }

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 54px;
        height: 54px;
        left: 14px;

        @include bp('del-breakpoint-m') {
          width: 80px;
          height: 56px;
          left: 0px;
          top: 42px;
        }
      }

      &::after {
        background-color: rgba($del-color-text, 0.3);
      }

      &::before {
        background-image: url('#{$pathAssets}/images/delonghiIcons/pdp-gallery/play-mobile.svg');
        width: 30px;
        height: 30px;
        z-index: 1;
        left: 26px;
        @include bp('del-breakpoint-m') {
          background-image: url('#{$pathAssets}/images/delonghiIcons/pdp-gallery/play-desktop.svg');
          width: 44px;
          height: 44px;
          left: 18px;
        }
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      display: none;
      @include bp('del-breakpoint-m') {
        display: block;
        width: 80px;
        height: 26px;
        left: 0;
        z-index: 1;
        &::after {
          width: 16px;
          height: 9px;
          background-image: url('#{$pathAssets}/images/delonghiIcons/pdp-gallery/thumbs.svg') !important;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    .swiper-button-prev {
      top: 0;
      &:after {
        top: -8px;
        transform: rotate(180deg);
        transform-origin: 25% 50%;
      }
    }

    .swiper-button-next {
      bottom: 0;
      top: auto;
      &:after {
        top: 8px;
      }
    }
  }
}

// #################################
// ZOOM WRAPPER
// #################################
.del-product-gallery.--zoom {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  background-color: var(--brand-03);
  z-index: 99999998;
  overflow: auto;

  @include bp('del-breakpoint-m') {
    padding-inline: 30px;
  }

  .--close-cta {
    position: absolute;
    top: 14px;
    right: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    background-color: var(--brand-03);
    height: 45px;
    width: 45px;
    cursor: pointer;
    z-index: 99999999;
    border-radius: 50%;
  }

  .--main-images-wrapper,
  .--main-video-wrapper {
    @include bp('del-breakpoint-m') {
      max-width: 100%;
      width: 100%;
    }
  }

  .--main-images-wrapper {
    max-width: 100%;
    .swiper-slide {
      img {
        cursor: default;
        width: 260px;
        @include bp('del-breakpoint-m') {
          width: auto;
        }
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      width: 45px !important;
      height: 45px !important;
      background-color: var(--brand-03);
      border-radius: 50%;
    }

    .swiper-button-prev {
      left: 14px;
    }

    .swiper-button-next {
      right: 14px;
    }
  }

  .--main-wrapper {
    display: flex;
    align-items: center;
  }

  .--main-wrapper,
  .--main-wrapper .swiper-container {
    height: calc(100vh - 102px);
    @include bp('del-breakpoint-m') {
      height: auto;
    }
  }
}

// #################################
// SHARED STUFF
// #################################
.del-product-gallery {
  .swiper-slide {
    img {
      display: block;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    border: none;
    background: none;
    position: absolute;

    &:after {
      position: relative;
      content: '' !important;
      display: block;
      width: 9px;
      height: 16px;
      @include bp('del-breakpoint-m') {
        width: 10px;
        height: 20px;
      }
    }
  }

  .swiper-button-prev {
    &:after {
      background-image: url('#{$pathAssets}/images/delonghiIcons/pdp-gallery/sx-mobile.svg') !important;
    }
    @include bp('del-breakpoint-m') {
      &:after {
        background-image: url('#{$pathAssets}/images/delonghiIcons/pdp-gallery/sx-desktop.svg') !important;
      }
    }
  }

  .swiper-button-next {
    &:after {
      background-image: url('#{$pathAssets}/images/delonghiIcons/pdp-gallery/dx-mobile.svg') !important;
    }
    @include bp('del-breakpoint-m') {
      &:after {
        background-image: url('#{$pathAssets}/images/delonghiIcons/pdp-gallery/dx-desktop.svg') !important;
      }
    }
  }
}

/* little hack for 3rd party issue with z-index */
body.dln-popup--open + mavenoid-assistant {
  z-index: -1 !important;
}
