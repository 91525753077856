.del-header {
  .del-stripe {
    display: flex;
    justify-content: center;
    align-items: stretch;
    height: 100%;
    padding: 8px 0;

    &__container {
      display: flex;
      justify-content: center;
      width: 100%;
      background-color: $del-color-green;

      &.del-stripe--hidden {
        display: none;
      }
    }

    &__link {
      display: flex;
      justify-content: center;
      width: auto;
      padding: 0 24.5px;

      @include bp('del-breakpoint-s') {
        border-right: 1px solid rgba($del-color-white, 0.2);

        &:last-child {
          border-right: none;
        }
      }

      &,
      & a {
        @include del-font-medium;
        color: $del-color-white;
        font-size: 0.875rem;
        line-height: 1.138rem;
        letter-spacing: 0;
        padding-top: 1px;
        padding-bottom: 1px;
      }

      & a {
        &:hover {
          text-decoration: none;
        }
      }
    }

    .swiper-button {
      &-prev,
      &-next {
        display: flex;
        justify-content: center;
        align-items: center;
        $size: 16px;
        width: $size;
        height: $size;
        top: 8px;

        &:after {
          content: '';
        }

        @include bp('del-breakpoint-s') {
          display: none;
        }

        .del-icon {
          $size: 13px;
          width: $size;
          height: $size;
        }
      }

      &-prev {
        left: 16px;
        right: auto;
      }

      &-next {
        right: 16px;
        left: auto;
      }
    }
  }

  @include bp('del-breakpoint-s') {
    &.isCLSession {
      margin-top: 33px;

      .del-stripe__container {
        top: 0;
        position: fixed;
        z-index: 10;

        &.del-stripe--hidden {
          display: flex;
        }
      }
    }
  }

  &__stripe-gap {
    width: 100%;
    height: 34px;
  }
}
