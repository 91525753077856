.del-text-h1 {
  @include del-font-medium;

  font-size: 2.313rem;
  line-height: 105%;
  letter-spacing: -0.02em;

  @include bp('del-breakpoint-s') {
    font-size: 3.125rem;
  }
}

.del-text-h2 {
  @include del-font-medium;

  font-size: 1.75rem;
  line-height: 105%;
  letter-spacing: -0.01em;

  @include bp('del-breakpoint-s') {
    font-size: 2.313rem;
  }
}

.del-text-h3 {
  @include del-font-medium;

  font-size: 1.313rem;
  line-height: 110%;

  @include bp('del-breakpoint-s') {
    font-size: 1.75rem;
  }
}

.del-text-h4 {
  @include del-font-medium;

  font-size: 1.125rem;
  line-height: 110%;

  @include bp('del-breakpoint-s') {
    font-size: 1.313rem;
  }
}

.del-text-h5 {
  @include del-font-medium;

  font-size: 1rem;
  line-height: 120%;

  @include bp('del-breakpoint-s') {
    font-size: 1.125rem;
  }
}

.del-text-h6 {
  @include del-font-medium;

  font-size: 0.813rem;
  line-height: 130%;
}

.del-text-p1 {
  @include del-font-book;

  font-size: 1.125rem;
  line-height: 140%;

  @include bp('del-breakpoint-s') {
    font-size: 1.313rem;
  }
}

.del-text-p2 {
  @include del-font-book;

  font-size: 1rem;
  line-height: 140%;

  @include bp('del-breakpoint-s') {
    font-size: 1.125rem;
  }
}

.del-text-p3 {
  @include del-font-book;

  font-size: 0.938rem;
  line-height: 140%;

  @include bp('del-breakpoint-s') {
    font-size: 1rem;
  }
}

.del-text-p4 {
  @include del-font-book;

  font-size: 0.875rem;
  line-height: 140%;
}

.del-text-p5 {
  @include del-font-book;

  font-size: 0.813rem;
  line-height: 140%;
}
