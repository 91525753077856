/* Colors */
$del-color-pure-black: #686868; // #414141;
$del-color-black: #686868; // #414141;
$del-color-black-70op: #686868;
$del-color-text: #686868; // #414141; // #00865e
$del-color-text-80: #686868; // #414141;
$del-color-text-60: #686868;
$del-color-text-40: #979797;
$del-color-grey: #e5e5e5;
$del-color-light-grey: #efefef;
$del-color-white-smoke: #fff; // #f7f7f7;
$del-color-white: #fff;
$del-color-deep-blue: #2a55ba;
$del-color-deeper-blue: #004ea8;
$del-color-blue: #015bbc; // footer
$del-color-bright-blue: #015bbc;
$del-color-icon-blue: #006eca; // #2A55BA : sign me up for emails // #266BBF : icon star
$del-color-icon-light-blue: #99d6ea;
$del-color-light-blue: #41b6e6;
$del-color-lighter-blue: #99d6ea;
$del-color-dark-blue: #0c2340;
$del-color-pale-blue: #c9e3f9;
$del-color-red: #e71d34;
$del-color-green: #00865e; // #007A53;
$del-color-none: transparent;

$del-color-white-light-mask: rgba($del-color-white, 0.75);
$del-color-white-mask: rgba($del-color-white, 0.5);
$del-color-black-dark-mask: rgba($del-color-pure-black, 0.7);
$del-color-black-mask: rgba($del-color-pure-black, 0.5);
$del-color-black-light-mask: rgba($del-color-pure-black, 0.1);
$del-color-grey-mask: rgba($del-color-pure-black, 0.03);
$del-color-blue-mask: rgba($del-color-deep-blue, 0.5);
$del-color-deeper-blue-dark-mask: rgba($del-color-deeper-blue, 0.6);
$del-color-deeper-blue-mask: rgba($del-color-deeper-blue, 0.3);
$del-color-deeper-blue-darkest-mask: rgba($del-color-deeper-blue, 0.7);

$bg-color-list: (
  none: $del-color-none,
  white: $del-color-white,
  white-smoke: $del-color-white-smoke,
  light-grey: $del-color-light-grey,
  text: $del-color-text,
  blue-del: $del-color-blue,
  black: $del-color-black,
  full-black: $del-color-pure-black,
);
