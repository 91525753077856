.del-header {
  &__wrapper {
    .del-btnIcon {
      &--account {
        display: none;

        @include bp('del-breakpoint-m') {
          position: relative;
          display: block;
        }

        &.touch {
          &::before {
            content: none;
          }
        }

        &.hover,
        &:hover {
          &:before {
            height: 80px;
            transform: translate(-50%, 0%);
            top: 0;
          }

          .dln-drawer {
            display: block;
            visibility: visible;
            transform: none;

            position: absolute;
            left: auto;
            right: -55px;

            &::after {
              content: none;
            }
          }
        }

        .dln-drawer {
          top: 100%;

          &--account {
            width: 280px;
            padding: 16px 0;

            .user {
              width: 100%;
              padding: 0 16px;

              .del-btn {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $del-color-red;
                padding: 8px;
                @include del-font-medium;
                color: $del-color-white;
                font-size: 1.125rem;
                line-height: 1.5rem;

                &--rounded {
                  border-radius: 50px;
                }
              }

              .text {
                padding: 22px 0;
                @include del-font-medium;
                color: $del-color-text-60;
                font-size: 1rem;
                line-height: 1.313rem;
                letter-spacing: -0.3px;
                text-align: left;

                .del-link {
                  @include del-font-medium;
                  color: $del-color-text;
                  text-decoration: underline;

                  &:hover {
                    color: $del-color-red;
                  }
                }
              }

              .greetingMessage {
                padding: 8px 0 20px;
                @include del-font-heavy;
                color: $del-color-text;
                letter-spacing: unset;
              }
            }

            hr {
              border: none;
              border-bottom: 1px solid $del-color-grey;
            }

            .utilityLinks {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              padding: 19px 16px 0;

              a {
                @include del-font-medium;
                font-size: 1rem;
                line-height: 1.75rem;
              }
            }
          }
        }
      }
      &--search {
        background: none;
        border: none;
        cursor: pointer;
      }
    }

    .del-button {
      &__wishlist {
        cursor: pointer;
      }

      span.del-btnIcon__wrap {
        display: block;
        position: relative;
      }

      span.del-btnIcon__checkoutCounter {
        display: flex;
        align-items: center;
        align-content: center;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: $del-color-red;
        position: absolute;
        top: -15px;
        right: -8px;
      }

      i.del-btnIcon__checkoutCounterText {
        display: inline-block;
        width: auto;
        height: 10px;
        line-height: 1rem;
        color: $del-color-white;
        text-align: center;
        padding: 0;
        margin: 0 auto;
        font-size: 0.688rem;
        @include del-font-heavy
      }
    }

    .del-button.displayNotificationDrawer .dln-drawer {
      display: block;
      visibility: visible;
      z-index: 2000;
      left: unset;
      top: 100%;
    }

    .dln-drawer:before {
      border-bottom-color: $del-color-grey;
    }
  }

  .del-button__cart span.del-btnIcon__checkoutCounter {
    top: 0;
  }
}
