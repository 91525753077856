@mixin textgroup-subtitle--small {
  font-size: 0.75rem;
  letter-spacing: 2.4px;
  line-height: 0.625rem;
  margin: 3px 0;
}
@mixin textgroup-title--small {
  @include del-font-medium;
  font-size: 25px;
  letter-spacing: -0.24px;
  line-height: 1.625rem;

  @include bp('del-breakpoint-l') {
    font-size: 2.125rem;
    letter-spacing: -0.34px;
    line-height: 2.313rem;
    margin: 7px 0;
  }

  @include bp('del-breakpoint-xl') {
    font-size: 2.313rem;
    letter-spacing: -0.37px;
    line-height: 2.544rem;
  }
}
@mixin textgroup-content--small {
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin: 7px 0 12px;
}


.textgroup-subtitle--small {
  @include textgroup-subtitle--small;
}

.textgroup-title--small {
  @include textgroup-title--small;
}

@mixin textgroup--small {
  .del-textgroup {
    &__subtitle {
      @include textgroup-subtitle--small;
      p {
        @include textgroup-subtitle--small;
      }
    }

    &__title {
      @include textgroup-title--small;
      p {
        @include textgroup-title--small;
      }
    }

    &__content {
      @include textgroup-content--small;
      p {
        @include textgroup-content--small;
      }
    }
  }
}
