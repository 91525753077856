.del-pdp__bundle {
  background-color: rgba($del-color-black, 0.03);
  padding: 24px 15px;
  @include bp('del-breakpoint-m') {
    padding: 60px 30px;
    margin-top: -79px;
  }

  &__wrapper {
    max-width: 1400px;
    margin: 0 auto;
  }

  &__title {
    @include del-font-medium;
    font-size: REM(20px);
    line-height: 23px;
    margin-bottom: 24px;
    @include bp('del-breakpoint-m') {
      @include del-font-heavy;
      font-size: REM(32px);
      line-height: 36px;
      margin-bottom: 40px;
    }
  }

  &__info-wrapper {
    @include bp('del-breakpoint-m') {
      display: inline-block;
    }
  }

  &__products {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid $del-color-grey;
    position: relative;
    @include bp('del-breakpoint-m') {
      border-bottom: none;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 33px;
    }

    .--plus {
      display: block;
      position: relative;
      width: 20px;
      height: 20px;
      margin-bottom: 16px;
      margin-inline: auto;

      &:last-child {
        display: none;
      }

      @include bp('del-breakpoint-m') {
        margin-inline: unset;
      }

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 8.5px;
        left: 50%;
        transform: translate(-50%, 0);
        background-color: $del-color-black;
        width: 20px;
        height: 3px;
      }
      &::after {
        top: 0;
        width: 3px;
        height: 20px;
      }
    }
  }

  &__product:first-child {
    .--image {
      @include bp('del-breakpoint-m') {
        max-width: 290px !important;
      }
    }

    .--title {
      @include bp('del-breakpoint-m') {
        font-size: REM(24px);
        line-height: REM(22px);
      }
    }
  }

  &__product {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2px;
    background-color: $del-color-white;
    padding: 4px 16px 4px 4px;
    margin-bottom: 16px;
    border-radius: 4px;
    @include bp('del-breakpoint-m') {
      flex-direction: column;
      gap: 20px;
      padding: 51px 40px;
      border-radius: 8px;
    }

    .--image {
      display: block;
      width: 84px;
      @include bp('del-breakpoint-m') {
        width: 100%;
        max-width: 212px;
      }
    }

    .--title {
      @include del-font-medium;
      font-size: REM(16px);
      line-height: REM(18px);
      text-align: left;
      width: calc(100% - 84px - 90px);
      @include lineClamp(2);
      @include bp('del-breakpoint-m') {
        width: 100%;
        flex-direction: column;
        font-size: REM(18px);
        text-align: center;
        max-width: 333px;
      }
    }

    .--price {
      @include del-font-book;
      font-size: REM(16px);
      line-height: 16px;
      width: 90px;
      text-align: right;
      @include bp('del-breakpoint-m') {
        flex-direction: column;
        font-size: REM(20px);
        line-height: 18px;
        text-align: center;
        width: auto;
      }
    }

    .--free-gift {
      display: flex;
      align-items: center;
      @include del-font-heavy;
      font-size: REM(12px);
      line-height: 12px;
      padding: 5px;
      padding-top: 7px;
      background-color: var(--brand-01);
      color: var(--brand-03);
      text-align: center;
      border-radius: 2px;
    }
  }

  &__totals {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    margin-top: 24px;

    @include bp('del-breakpoint-m') {
      flex-direction: row;
      margin-top: 40px;
      gap: 32px;
    }

    .--prices-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
      @include bp('del-breakpoint-m') {
        margin-bottom: 0;
        gap: 15px;
      }
    }

    .--subtotal {
      @include del-font-heavy;
      font-size: REM(20px);
      line-height: 20px;
      align-self: flex-end;

      @include bp('del-breakpoint-m') {
        @include del-font-medium;
        font-size: REM(23px);
        line-height: 21px;
        align-self: unset;
      }
    }

    .--prices {
      display: flex;
      align-items: center;
      gap: 10px;
      flex-wrap: wrap;
      justify-content: flex-end;
    }

    .--old-price {
      @include del-font-book;
      font-size: REM(20px);
      line-height: 18px;
      color: var(--ui-06);
      text-decoration: line-through;
    }

    .--price {
      @include del-font-medium;
      font-size: REM(23px);
      line-height: 21px;
    }

    .--savings-wrapper {
      display: flex;
      width: 100%;
      order: -1;
      justify-content: flex-end;
      @include bp('del-breakpoint-m') {
        order: unset;
        width: auto;
        justify-content: unset;
      }
    }

    .--savings {
      display: flex;
      align-items: center;
      @include del-font-heavy;
      font-size: REM(12px);
      line-height: 12px;
      padding: 5px;
      padding-top: 7px;
      background-color: var(--support-03--light);
      text-align: center;
      border-radius: 2px;
    }

    button {
      @include del-font-heavy;
      width: 100%;
      font-size: REM(18px);
      line-height: REM(24px);
      @include bp('del-breakpoint-m') {
        max-width: 226px;
      }
    }
  }
}
