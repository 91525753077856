.del-beveragesslider {
  padding-top: 83px;
  padding-bottom: 96px;
  background-color: $del-color-dark-blue;

  @include bp('del-breakpoint-s') {
    padding-top: 83px;
    padding-bottom: 86px;
  }

  @include bp('del-breakpoint-xl') {
    padding-top: 93px;
    padding-bottom: 143px;
  }

  &__texts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 26px;
    padding-inline: 19px;

    @include bp('del-breakpoint-s') {
      padding-inline: unset;
      max-width: 545px;
      margin-inline: auto;
    }

    @include bp('del-breakpoint-xl') {
      max-width: 758px;
    }
  }

  &__title,
  &__title p {
    @include del-font-medium;
    color: $del-color-white;
    font-size: REM(26px);
    line-height: normal;
    @include bp('del-breakpoint-xl') {
      font-size: REM(37px);
    }
  }

  &__description,
  &__description p {
    @include del-font-book;
    color: $del-color-white;
    font-size: REM(18px);
    line-height: 21px;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
    object-fit: cover;
    margin-inline: auto;
  }

  &__slide-caption {
    display: block;
    @include del-font-medium;
    font-size: REM(18px);
    line-height: 21px;
    color: $del-color-white;
    text-align: center;
    padding-inline: 3px;
  }
}
