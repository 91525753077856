.del-header {
  &__button-container {
    .del-btnIcon {
      .del-header__user-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        $size: 24px;
        width: $size;
        height: $size;

        margin: 4px;

        border: none;
        border-radius: $size / 2;
        background-color: $del-color-text;

        @include del-font-medium;
        font-size: 0.875rem;
        color: $del-color-white;
      }
    }
  }

  &--inverted {
    .del-header__button-container {
      .del-btnIcon {
        .del-header__user-icon {
          background-color: $del-color-white;
          color: $del-color-text;
        }
      }
    }
  }

  &--standard,
  &--menuopen {
    .del-header__button-container {
      .del-btnIcon {
        .del-header__user-icon {
          background-color: $del-color-text;
          color: $del-color-white;
        }
      }
    }
  }
}
