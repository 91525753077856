@mixin del-button {
  display: inline-block;
  text-decoration: none;
  transition: $del-transition-button-hover;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  background-color: transparent;

  &:disabled {
    opacity: .5;
    cursor: not-allowed;
  }
}

@mixin base_del-button--primary($color: $del-color-white, $bgColor: $del-color-text) {
  border-radius: 50px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  opacity: 1;
  color: $color;
  background-color: $bgColor;
  border: 1px solid $bgColor;
  width: auto;
  padding: 8px 25px;
  font-size: 1.125rem;
  line-height: 1.5rem;

  &:not(:disabled):hover {
    opacity: 0.8;
  }
}

@mixin base_del-button--secondary($color: $del-color-white, $bgColor: $del-color-text, $colorHover: $del-color-text, $bgColorHover: $del-color-white) {
  @include base_del-button--primary($color, $bgColor);
  border: 1px solid $color;

  &:not(:disabled):hover {
    color: $colorHover;
    background-color: $bgColorHover;
  }
}

@mixin del-button--primary-black {
  @include base_del-button--primary($del-color-white, $del-color-red);
}

@mixin del-button--primary-blue {
  @include base_del-button--primary($del-color-white, $del-color-red);
}

@mixin del-button--primary-darkBlue {
  @include base_del-button--primary($del-color-white, $del-color-red);
}

@mixin del-button--primary-white {
  @include base_del-button--primary($del-color-white, $del-color-red);
}

@mixin del-button--secondary {
  @include base_del-button--secondary($del-color-red, $del-color-white, $del-color-white, $del-color-red);
}

@mixin del-button--secondary-white {
  @include base_del-button--secondary($del-color-red, $del-color-white, $del-color-white, $del-color-red);
}

@mixin del-button--big {
  @include del-font-demi;
}

@mixin del-button--mid {
  @include del-font-demi;
}

@mixin del-button--small {
  @include del-font-demi;
}

@mixin base_del-button--arrow-right($color: $del-color-text, $arrow: 'right-black') {
  color: $color;
  text-align: center;
  cursor: pointer;
  opacity: 1;

  &:not(:disabled):hover {
    opacity: 0.8;
  }

  &::after {
    content: '';
    display: inline-block;
    position: relative;
    background-image: url("#{$pathAssets}/images/delonghiIcons/#{$arrow}.svg");
    transition: all 0.3s ease-in-out;
  }
}

@mixin del-button--arrow-right {
  @include base_del-button--arrow-right($del-color-text, 'right-black');
}

@mixin del-button--arrow-right--light {
  @include base_del-button--arrow-right($del-color-white, 'right-white');
}

@mixin base_del-button--arrow-left($color: $del-color-text, $arrow: 'right-white', $arrowBgColor: $del-color-text) {
  text-align: center;
  cursor: pointer;
  opacity: 1;
  color: $color;

  &:not(:disabled):hover {
    opacity: 0.8;
  }

  &:before {
    content: "";
    display: inline-block;
    position: relative;
    background-repeat: no-repeat;
    background-color: $arrowBgColor;
    background-image: url("#{$pathAssets}/images/delonghiIcons/#{$arrow}.svg");
    transition: all 0.2s ease-in-out;
  }
}

@mixin del-button--plus--bw($blackwhite: 'black', $rotatedeg: '0', $hoverOpacity: 0.8) {
  text-align: center;
  cursor: pointer;
  opacity: 1;

  &:not(:disabled):hover {
    opacity: #{$hoverOpacity};
  }

  &:before {
    content: "";
    display: inline-block;
    position: relative;
    width: 26px;
    height: 26px;
    background-repeat: no-repeat;
    background-color: transparent;
    background-image: url("#{$pathAssets}/images/icons/icon-plus-#{$blackwhite}.svg");
    transform: rotate(#{$rotatedeg}deg);
    transition: all 0.2s ease-in-out;
  }
}

@mixin del-button--plus-dark {
  @include del-button--plus--bw();
}

@mixin del-button--plus-light {
  @include del-button--plus--bw('white');
}

@mixin del-button--plus {
  @include del-button--plus-dark;
}

@mixin del-button--arrow-left {
  @include base_del-button--arrow-left($del-color-text, 'right-white', $del-color-text);
}

@mixin del-button--arrow-left--light {
  @include base_del-button--arrow-left($del-color-white, 'right-black', $del-color-white);
}

@mixin del-button--arrow-left--dark-blue {
  @include base_del-button--arrow-left(
    $del-color-red,
    'right-white',
    $del-color-red
  );
}

@mixin del-button--arrow-big {
  height: 28px;
  line-height: 1.75rem;
  font-size: 1.125rem;

  @include del-font-demi;
}
@mixin del-button--arrow-left-big {
  &:not(:disabled):hover::before {
    margin-right: 8px;
    left: 4px;
  }
  &:before {
    height: 22px;
    width: 22px;
    top: 5px;
    left: 0;
    margin-right: 8px;
    border-radius: 11px;
    background-position: 3px center;
    background-size: 18px;
  }
}
@mixin del-button--arrow-right-big {
  &:not(:disabled):hover::after {
    left: 4px;
  }
  &::after {
    height: 18px;
    width: 18px;
    top: 5px;
    left: 1px;
  }
}
@mixin del-button--arrow-mid {
  height: 26px;
  line-height: 1.625rem;
  font-size: 1rem;

  @include del-font-demi;
}
@mixin del-button--arrow-small {
  height: 20px;
  line-height: 1.25rem;
  font-size: 0.875rem;

  @include del-font-demi;
}
@mixin del-button--line {
  color: $del-color-text;
  border-bottom: 1px solid $del-color-text;
  text-align: center;
  cursor: pointer;
  padding: 0 1px;

  &:not(:disabled):hover {
    color: $del-color-red;
    border-bottom: 2px solid $del-color-red;
  }
}
@mixin del-button--line-big {
  height: 28px;
  line-height: 1.75rem;
  font-size: 1.125rem;

  @include del-font-demi;
}
@mixin del-button--line-mid {
  height: 26px;
  line-height: 1.625rem;
  font-size: 1rem;

  @include del-font-demi;
}
@mixin del-button--line-small {
  height: 20px;
  line-height: 1.25rem;
  font-size: 0.875rem;

  @include del-font-demi;
}
@mixin del-button--link {
  color: $del-color-text;
  border-bottom: 1px solid $del-color-text;
  padding-bottom: 1px;
  font-size: 0.938rem;

  @include del-font-book;

  &:not(:disabled):hover {
    border-bottom: 1px solid $del-color-text-80;
  }
}

.del-button {
  @include del-button;

  /* Primary and Secondary Buttons style and sizes */
  &--primary-black {
    @include del-button--primary-black;
  }

  &--primary-blue {
    @include del-button--primary-blue;
  }

  &--primary-darkBlue {
    @include del-button--primary-darkBlue;
  }

  &--primary-white {
    @include del-button--primary-white;
  }

  &--secondary {
    @include del-button--secondary;
  }

  &--secondary-white {
    @include del-button--secondary-white;
  }

  &--big {
    @include del-button--big;
  }

  &--mid {
    @include del-button--mid;
  }

  &--small {
    @include del-button--small;
  }

  /* Arrow buttons style and sizes */
  &--arrow-right {
    @include del-button--arrow-right;
  }

  &--arrow-right--light {
    @include del-button--arrow-right--light;
  }

  &--arrow-right-big {
    @include del-button--arrow-right-big;
  }

  &--arrow-left {
    @include del-button--arrow-left;
  }

  &--arrow-left--light {
    @include del-button--arrow-left--light;
  }

  &--arrow-left--darkBlue {
    @include del-button--arrow-left--dark-blue;
  }

  &--arrow-big {
    @include del-button--arrow-big;
  }

  &--arrow-left-big {
    @include del-button--arrow-left-big;
  }

  &--arrow-mid {
    @include del-button--arrow-mid;
  }

  &--arrow-small {
    @include del-button--arrow-small;
  }

  /* Line buttons style and sizes */
  &--line {
    @include del-button--line;
  }

  &--line-big {
    @include del-button--line-big;
  }

  &--line-mid {
    @include del-button--line-mid;
  }

  &--line-small {
    @include del-button--line-small;
  }

  /* Link buttons style and sizes */
  &--link {
    @include del-button--link;
  }
}
