.del-textgroup {
  position: relative;
  color: $del-color-text;
  opacity: 1;
  transform: translateY(0);
  transition: opacity .3s ease, transform .7s ease;

  &.--hidden {
    opacity: 0;
    transform: translateY(50px);
  }

  &__texts,
  &__appLinks {
    display: flex;
    flex-direction: column;

    &--light,
    &--light *,
    &--light-mobile,
    &--light-mobile * {
      color: $del-color-white;
    }

    @include bp('del-breakpoint-l') {
      &--light-mobile,
      &--light-mobile * {
        color: $del-color-text;
      }
      &--light-desktop,
      &--light-desktop * {
        color: $del-color-white;
      }
    }

  }

  &__badge {
    display: block;
    width: 100%;
    max-width: 150px;
    height: auto;
    margin:0 auto 7px;
    @include bp('del-breakpoint-l') {
      margin: 0 0 7px;
      .del-textgroup--center & {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  &__subtitle {
    @include del-font-heavy;
    opacity: .7;
  }

  &__title,
  &__content {
    @include del-font-book;
    strong, b {
      @include del-font-heavy;
    }
  }

  &__title {
    &--desktop {
      display: none;
      @include bp('del-breakpoint-l') {
        display: block;
      }
    }

    &--mobile {
      display: block;
      @include bp('del-breakpoint-l') {
        display: none;
      }
    }
  }

  &__separator {
    background-color: $del-color-red;
    width: 32px;
    height: 4px;
    border-radius: 4px;
    margin: 5px auto;
    display: none;

    @include bp('del-breakpoint-m') {
      display: block;
      margin: 0 0 10px;
      .del-textgroup--center & {
        margin-left: auto;
        margin-right: auto;
      }
    }

    body:not(.page-dlgDelonghiPDP) & {
      display: block;
    }

  }

  &--primary,
  &--secondary,
  &--countdown {
    @include textgroup--primary;
  }

  &--medium {
    @include textgroup--medium;
  }

  &--small {
    @include textgroup--small;
  }

  &--generic {
    @include textgroup--generic;
  }

  &--divider {
    @include textgroup--divider;
  }

  &__cta {
    margin: 10px 0 0;
  }

}
