@include bp('del-breakpoint-m') {
  .del-stories {
    &__wrapper {
      width: 100%;
      padding-top: 50%;
    }

    &__screen {
      .bra-image {
        padding-bottom: 0;
        height: 100%;

        .bra-image__image {
          position: relative;

          min-width: 100%;
          min-height: 100%;
        }
      }

      &--start {
        & .buttonTap.tapToStart {
          bottom: 60px;
        }
      }
    }
  }

  .swiper-container.stories-slider {
    .swiper-slide {
      .del-stories__slide--content {
        margin-left: 8.5%;
        max-width: 35%;
      }

      .del-stories__slide--visual {
        .mobileVersion {
          display: none;
        }

        .desktopVersion {
          display: block;
          height: 100%;
        }
      }
    }

    .swiper-footer {
      .swiper-button-next,
      .swiper-button-prev {
        display: block;
        position: relative;
        background: none;
        top: auto;
        left: auto;
        right: auto;
        bottom: auto;
        width: auto;
        height: auto;
        margin: 30px;
        opacity: 1;

        &.swiper-button-disabled {
          opacity: 0;
        }
      }

      .swiper-button-prev {
        order: 1;
        margin: 30px auto 30px 30px;
      }

      .swiper-button-next {
        order: 3;
        margin: 30px 30px 30px auto;
      }

      .swiper-button-close {
        padding: 25px;
      }
    }

    .swiper-pagination {
      bottom: 30px;
      width: calc(80% + 8px);
    }
  }
}

@include bp-range('del-breakpoint-m', 'del-breakpoint-max-l') {
  .del-stories {
    &__container {
      padding: 0 70px;
    }

    &__wrapper {
      height: 443px;
    }

    &__screen {
      &--content {
        display: block;
      }

      .del-subtitle {
        max-width: 471px;
        margin-top: 196px;
        padding-bottom: 0;
      }

      .buttonTap {
        width: 94px;
        left: calc(50% - 47px);
        border-bottom: 2px solid;
        padding: 5px 0;
        bottom: 20px !important;

        &::before {
          width: 32px;
          height: 32px;
          left: calc(50% - 16px);
          border-radius: 50%;
          top: -50px;
        }

        &::after {
          top: -50px;
        }
      }
    }

    .swiper-container.stories-slider {
      .swiper-slide {
        .del-stories__slide--content {
          margin-left: 36px;
          margin-bottom: 66px;
        }
      }

      .swiper-pagination {
        bottom: 18px;
        width: calc(100% - 105px);
      }

      .swiper-footer {
        .swiper-button-close {
          padding: 29px;
        }

        .del-btn-close__icon {
          width: 20px;
          height: 20px;
        }

        .swiper-button-next,
        .swiper-button-prev {
          display: none;
        }
      }
    }
  }
}
