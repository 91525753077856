/*
FONT SIZE & LINE HEIGHT
8.32px -> 0.52rem
10px -> 0.625rem
10.24px -> 0.64rem
11px -> 0.688rem
12px -> 0.75rem
13px -> 0.813rem
14px -> 0.875rem
15px -> 0.938rem
16px -> 1rem
17px -> 1.063rem
18px -> 1.125rem
18.2px -> 1.138rem
19px -> 1.188rem
20px -> 1.25rem
21px -> 1.313rem
22px -> 1.375rem
22.4px -> 1.4rem
23px -> 1.438rem
23.6px -> 1.475rem
24px -> 1.5rem
25px -> 1.563rem
26px -> 1.625rem
27px -> 1.688rem
27.3px -> 1.706rem
28px -> 1.75rem
29px -> 1.813rem
29.4px -> 1.838rem
30px -> 1.875rem
31px -> 1.938rem
32px -> 2rem
32.4px -> 2.025rem
33.6px -> 2.1rem
34px -> 2.125rem
34.6px -> 2.163rem
35px -> 2.188rem
36px -> 2.25rem
37px -> 2.313rem
38px -> 2.375rem
39.2px -> 2.45rem
40px -> 2.5rem
40.7px -> 2.544rem
42px -> 2.625rem
43px -> 2.688rem
44px -> 2.75rem
44.6px -> 2.788rem
45px -> 2.813rem
47px -> 2.938rem
48px -> 3rem
50px -> 3.125rem
52px -> 3.25rem
54px -> 3.375rem
58px -> 3.625rem
66px -> 4.125rem
67px -> 4.188rem
72px -> 4.5rem
*/

html {
  font-size: 12.8px; // 80% or 0.8 rem of default browser 16px
  letter-spacing: -0.05rem;
  line-height: 12.8px; // 80% of or 0.8 rem of default browser 16px
}

.dln-tabs__list.swiper-wrapper .swiper-slide {
  width: auto;
}

.dln-delonghi {
  .dln-addItem {
    max-width: none;
  }

  .dln-cart__services .dln-checkServiceBox .dln-btn,
  .dln-cart--empty .dln-cart__btnGroup .dln-btn,
  .dln-account .gigya-input-submit,
  .dln-btn:not(.dln-btn--link):not(.dln-btn--social):not(.dln-btn--paypal):not(.dln-btn--secondary),
  .dln-btn:not(.dln-btn--link):not(.dln-btn--social):not(.dln-btn--paypal):not(.dln-btn--secondary):not(.dln-btn--stroked) {
    @include base_del-button--primary(var(--brand-03), var(--brand-01));

    &.dln-btn--stroked {
      border: 0 !important;
    }
  }

  // secondary cta in review
  .dln-writeReview {
    .dln-btn:not(.dln-btn--link):not(.dln-btn--stroked):not(.dln-btn--social):not(.dln-btn--paypal):not(.dln-btn--secondary) {
      @include base_del-button--secondary(var(--brand-01), var(--brand-03), var(--brand-03), var(--brand-01));
      text-decoration: none;
      min-width: 185px;
      height: 38px;
      line-height: 38px;
      font-size: 1.4rem;
    }
  }

  // override delonghi-fix.scss for gigya
  .dln-account .gigya-input-submit {
    background-color: inherit !important;
  }

  .dln-cart--empty .dln-cart__emptyMessage {
    max-width: 500px;
  }

  .dln-search {
    .dln-popup {
      &__brandLogo {
        &__image {
          max-width: 100%;
        }
      }
    }
  }

  // Order Detail Receipts
  .dln-order--detail .dln-order__content .dln-return-order-receipt {
    background-color: var(--ui-05);
    padding: 22px 20px;
    margin-top: 24px;
    border: none;

    .dln-return-order__header {
      margin-bottom: 22px;
      padding: 0;
      border: none;

      .dln-return-order__section-title {
        font-family: var(--fontNameDelonghi);
        font-weight: 500;
        font-style: normal;
        font-size: 1.313rem;
      }
    }

    &__content {
      margin: 0 0 40px;

      .dln-return-order-receipt__text {
        font-size: 1rem;
      }
    }
  }

  .dln-drawer {
    color: inherit;
  }

  .del-product-moodboard .swiper-container-horizontal>.swiper-pagination-bullets {
    bottom: 0;
  }

  // Basic gigya buttons
  .dlg-gigya-button-wrapper input[type=button],
  .dlg-gigya-button-wrapper input[type=submit] {
    background-color: var(--brand-01) !important;
    border-radius: 50px;
  }

  @media only screen and (max-width: $braun-s - 1) {
    // hero banner title mobile version
    .del-hero-banner .del-textgroup__title {
      font-size: 30px;
      line-height: 35px;
    }
  }

  // stars with color of images
  .dln-ratingStars {
    &:not(.dln-ratingStars--disabled) .dln-ratingStars__star {
      &:hover .dln-icn::before,
      &:hover ~ .dln-ratingStars__star .dln-icn::before {
        color: $del-color-icon-blue;
      }
    }

    input {
      &:checked,
      &:checked~label {
        .dln-icn::before {
          color: $del-color-icon-blue;
        }
      }
    }
    &__star {
      &--selected,
      &--selected~.dln-ratingStars__star {
        .dln-icn::before {
          color: $del-color-icon-blue;
        }
      }
    }
  }

  // Registration / Login
  .dln-registration {
    .gigya {
      &-composite-control-link {
        font-size: 0.875rem;
        line-height: 1.313rem;
      }

      &-input {
        &-submit {
          font-size: 1.125rem;
          line-height: 1rem;
        }
      }
    }
  }

  .dln-popup {
    // Login popup
    .gigya {
      &-input {
        &-submit {
          font-size: 1.125rem;
          line-height: 1rem;
        }
      }
    }

    .dln-registration__paragraph {
      font-size: 0.875rem;
      line-height: 1.3rem;
    }

    // Subscription popup
    .dln-subscription-flow {
      #gigya-subscribe-with-email-form {
        .gigya-composite-control-checkbox {
          .gigya-label {
            span {
              line-height: 1rem;
            }
          }
        }
      }
    }
  }

  // Checkout
  .dln-checkout {
    &__innerColTitle {
      font-size: 1.438rem !important;
      line-height: 1.313rem !important;
    }

    .gigya {
      &-input {
        &-submit {
          font-size: 1.125rem;
          line-height: 1rem;
        }
      }

      &-forgotPassword {
        font-size: 0.875rem;
        line-height: 1.313rem;
      }
    }
  }

  // Marketing Preferences & Empty Order History
  .dln-account {
    .gigya {
      &-input {
        &-submit {
          font-size: 1.125rem !important;
          line-height: 1rem !important;
        }
      }
    }
    .dln-ordersHist {
      &__subTitle, &__paragraph {
        text-align: center;
      }
    }
  }

  .del-product-moodboard .swiper-container-horizontal>.swiper-pagination-bullets {
    bottom: 0;
  }

  .dln-terms {
    &__paragraph {
      * {
        line-height: 150%;
        color: inherit;
        margin-bottom: 0.5rem;
      }

      td a,
      p a,
      a {
        color: var(--brand-04);
      }

      ul,
      ol {
        padding-inline-start: 1rem;
        margin-block-end: 1rem;
      }

      li {
        line-height: 150%;
        list-style-position: inside;
      }

      p {
        margin-block-start: 1rem;
        margin-block-end: 1rem;
      }

      table,
      td,
      th {
        border: 1px solid var(--ui-01);
        text-align: left;
        padding: 8px;
        border-collapse: collapse;
      }
    }
  }
}

// Basic gigya buttons
.dlg-gigya-button-wrapper input[type=button],
.dlg-gigya-button-wrapper input[type=submit] {
  background-color: var(--brand-01) !important;
  border-radius: 50px;
}

// hero banner title mobile version
@include bp('del-breakpoint-s', 'max') {
  .dln-delonghi .del-hero-banner .del-textgroup__title {
    font-size: 1.875rem;
    line-height: 2.188rem;
  }
}

// loqate dropdown menu
.dln-delonghi {
  .pca {
    position: relative;

    .pcaautocomplete {
      z-index: 1 !important;
      left: 0 !important;
      top: 0 !important;
    }
  }
}
