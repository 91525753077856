/* Responsive breakpoints */

$breakpoints: (
  'del-breakpoint-xs': 360px,
  'del-breakpoint-s': 768px,
  'del-breakpoint-m': 1024px,
  'del-breakpoint-l': 1200px,
  'del-breakpoint-xl': 1440px,

  'del-breakpoint-max-xs': 359px,
  'del-breakpoint-max-s': 767px,
  'del-breakpoint-max-m': 1023px,
  'del-breakpoint-max-l': 1199px,
  'del-breakpoint-max-xl': 1439px
) !default;

@mixin bp($breakpoint, $breakpoint-rule: 'min') {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media ('#{$breakpoint-rule}-width': map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn 'Unfortunately, no value could be retrieved from `#{$breakpoint}`. '
      + 'Available breakpoints are: #{map-keys($breakpoints)}.';
  }
}

@mixin bp-range($breakpoint-min, $breakpoint-max) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint-min) and  map-has-key($breakpoints, $breakpoint-max) {
    // Prints a media query based on the value
    @media (min-width: map-get($breakpoints, $breakpoint-min)) and (max-width: map-get($breakpoints, $breakpoint-max)) {
      @content;
    }
  }
    // If the key doesn't exist in the map
  @else {
    @warn 'Unfortunately, no value could be retrieved from `#{$breakpoint-min}` or `#{$breakpoint-max}`. '
      + 'Available breakpoints are: #{map-keys($breakpoints)}.';
  }
}
