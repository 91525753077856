.del-pdp__prices {
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin-bottom: 20px;
  @include del-font-book;
  letter-spacing: 0.4px;
  color: $del-color-text;

  @include bp('del-breakpoint-max-m', 'max') {
    padding-top: 20px;
    border-top: 1px solid $del-color-grey;
  }

  .--list {
    display: flex;
    align-items: baseline;
    gap: 7px;
    list-style: none;
  }

  .--mainPrice {
    font-size: REM(28px);
  }

  .--strikePrice,
  .--discountPrice,
  .--lastLowerPriceLabel,
  .--lastLowerPrice,
  .--discountLastLowerPrice {
    display: inline-flex;
    font-size: REM(16px);
    color: $del-color-text-60;

    @include bp('del-breakpoint-m') {
      font-size: REM(18px);
    }
  }

  .--strikePrice,
  .--lastLowerPrice.--line-through {
    text-decoration: line-through;
  }

  .--lastLowerPriceLabel,
  .--lastLowerPrice,
  .--discountLastLowerPrice {
    font-size: REM(13px);
  }

  .--discountPrice::before,
  .--discountLastLowerPrice::before {
    content: '(';
  }

  .--discountPrice::after,
  .--discountLastLowerPrice::after {
    content: ')';
  }

  .--icon {
    @include del-font-demi;
    background-color: var(--brand-01);
    color: var(--brand-03);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    top: -3px;
    position: relative;
    padding-top: 2px;
  }
  .del-pdp__main-info__stock-sku__out-of-stock-wrapper {
    min-height: 21px;
    display: flex;
    align-items: center;
    column-gap: 13px;
    color: $del-color-red;
    margin-bottom: 24px;

    .del-pdp__main-info__stock-sku__out-of-stock-icon-red {
      background-color: $del-color-red;
      border-radius: 3px;
      color: white;
      width: 21px;
      height: 21px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    span {
      font-weight: 600;
      font-size: REM(14px);
    }
  }

  .--weeeTax {
    display: flex;
    align-items: center;
    list-style: none;
    gap: 4px;
    margin-top: 5px;
    font-size: 12px;

    .dln-tooltip {
      margin-top: 2px;
    }
  }
}
