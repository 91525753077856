.del-pdp__main {
  border-bottom: 1px solid $del-color-grey;
  padding-bottom: 10px;

  @include bp('del-breakpoint-m') {
    margin-bottom: 79px;
    padding-bottom: 22px;
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 15px;

    @include bp('del-breakpoint-s') {
      padding: 0 40px;
    }

    @include bp('del-breakpoint-m') {
      flex-direction: row;
      padding: 0 70px;
    }

    @include bp('del-breakpoint-xl') {
      padding: 0;
    }

    &__gallery {
      width: 100%;
      align-self: flex-start;

      @include bp('del-breakpoint-s') {
        width: 50%;
      }
      @include bp('del-breakpoint-m') {
        width: 70%;
      }
    }

    &__info {
      width: 100%;
      border-top: 1px solid var(--ui-01);

      @include bp('del-breakpoint-xs') {
        margin-top: 12px;
        padding-top: 20px;
      }

      @include bp('del-breakpoint-m') {
        width: 33.9366515837%;
        border-top: none;
      }
    }
  }
}

.del-pdp__main-info {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__discount {
    font-size: REM(18px);
    top: -3px;
    position: relative;
  }

  &__title-wishlist-wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    @include del-font-medium;
    color: $del-color-text;
    font-size: REM(28px);
    margin-bottom: 5px;

    @include bp('del-breakpoint-m') {
      font-size: REM(26px);
    }
  }

  &__category {
    @include del-font-medium;
    font-size: REM(14px);
    line-height: 16px;
    color: var(--brand-07);
    margin-bottom: 11px;
  }

  &__model-number {
    @include del-font-medium;
    color: $del-color-text-80;
    font-size: REM(10px);
    margin-bottom: 40px;
  }

  &__variant-color {
    font-size: REM(14px);;
    margin-bottom: 24px;
  }

  &__short-desc {
    @include del-font-book;
    font-size: REM(16px);
    line-height: 24px;
    color: $del-color-text;
    margin-bottom: 25px;
    p {
      font-size: inherit;
      line-height: inherit;
    }
  }

  &__reviews {
    display: flex;
    align-items: center;
    text-decoration: none;
    margin-bottom: 20px;

    i {
      width: 16px;
      height: 16px;
    }
    span {
      @include del-font-book;
      font-size: REM(14px);
      color: $del-color-bright-blue;
    }
    &.--link {
      @include del-font-book;
      color: $del-color-text;
      text-decoration: underline;
    }
  }

  &__colors {
    margin-bottom: 20px;

    &__list {
      list-style: none;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      li {
        border: 3px solid transparent;
        padding-bottom: 3px;
        margin-right: 20px;
        margin-bottom: 10px;

        &:hover {
          border-color: $del-color-text;
        }

        &:last-child {
          margin-right: 0;
        }

        a {
          text-decoration: none;
        }

        img {
          display: block;
        }

        &.--current {
          border-color: $del-color-text;
        }
      }
    }
  }

  &__voltage {
    margin-bottom: 20px;

    &__current {
      @include del-font-medium;
      font-size: REM(12px);
      color: $del-color-text-60;
      display: inline-block;
      span {
        color: $del-color-text;
      }
      margin-bottom: 5px;
    }

    &__list {
      list-style: none;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      li {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid $del-color-grey;
        border-radius: 2px;
        margin-right: 10px;
        margin-bottom: 10px;

        &:first-child {
          border-color: $del-color-text;

          span,
          a {
            color: $del-color-text;
          }
        }

        &:last-child {
          margin-right: 0;
        }

        span,
        a {
          @include del-font-medium;
          color: $del-color-text-60;
          font-size: REM(16px);
          text-decoration: none;
          padding: 8px 28px;
        }
      }
    }
  }

  &__prices {
    margin-bottom: 20px;

    &__list {
      list-style: none;
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;

      li {
        margin-right: 10px;
        margin-bottom: 10px;

        &:last-child {
          margin-right: 0;
          margin-bottom: 5px;
        }
      }
    }

    &__current,
    &__strike,
    &__discount {
      @include del-font-book;
      letter-spacing: 0.4px;
      margin-right: 10px;
      color: $del-color-text;
    }

    &__current {
      font-size: REM(30px);
      margin-bottom: 10px;
    }

    &__strike {
      font-size: REM(26px);
      color: $del-color-text-60;
      text-decoration: line-through;
      margin-bottom: 10px;
    }

    &__discount {
      letter-spacing: 0.8px;
      font-size: REM(26px);
    }

    &__vat-included {
      @include del-font-book;
      font-size: REM(14px);
      line-height: 2;
      letter-spacing: -0.2px;
      color: $del-color-text;
    }
  }

  &__stock-sku {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 10px;

    &__in-stock,
    &__low-stock,
    &__out-of-stock,
    &__sku {
      @include del-font-medium;
      font-size: REM(16px);
      width: 50%;
      order: 1;
    }

    &__in-stock {
      display: flex;
      align-items: flex-start;
      color: $del-color-green;
      i {
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }
    }

    &__low-stock {
      color: $del-color-text-60;
    }

    &__out-of-stock {
      color: $del-color-text;

      &__info {
        border-top: 1px solid $del-color-grey;
        padding-top: 20px;
        margin-top: 3px;
        margin-bottom: 15px;
        width: 100%;
        order: 3;

        h6 {
          @include del-font-medium;
          font-size: REM(18px);
          color: $del-color-text;
          margin-bottom: 10px;
        }

        p {
          @include del-font-book;
          font-size: REM(16px);
          color: $del-color-text;
        }
      }
    }

    &__sku {
      @include del-font-book;
      font-size: REM(13px);
      color: $del-color-text-80;
      padding-left: 5px;
      text-align: right;
      order: 2;
    }
  }

  .del-product-info__energy-label {
    margin: 10px 0 20px 0;

    img {
      width: 48px;
      height: 27px;

      @include bp('del-breakpoint-m') {
        width: 52px;
        height: 30px;
      }
    }
  }

  &__actions {

    .del-button {
      width: 100%;
      margin-bottom: 15px;
    }

    &__cart-add {
      height: 49px;
      font-size: REM(18px);
    }

    &__wishlist {
      display: flex;
      align-items: center;
      border: none;
      padding: 0;
      background: none;
      cursor: pointer;

      i {
        width: 27px;
        height: 27px;
        align-self: flex-start;
      }

      span {
        @include del-font-medium;
        font-size: REM(18px);
        color: $del-color-text;
        width: calc(100% - 22px);
        margin-left: 7px;
      }
    }
  }

  &__benefits {
    margin-bottom: 20px;

    &__item {
      margin-bottom: 7px;
      border: none;
      background: $del-color-white-smoke;
      width: 100%;
      min-height: 50px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      column-gap: 20px;
      padding: 17px 11px 17px 20px;

      i {
        width: 22px;
        height: 22px;
        align-self: flex-start;
      }

      span {
        @include del-font-book;
        font-size: REM(16px);
        color: $del-color-text;
        width: auto;

        @include bp('del-breakpoint-m') {
          font-size: REM(14px);
        }
        &.benefit-title {
          text-align: start;
          flex-grow: 1;
        }
      }
      .dln-tooltip {
        &__container {
          flex-wrap: nowrap;
          align-items: center;
          width: 273px;
          max-width: 75dvw;
          text-align: left;
          top: unset;
          bottom: unset;
          left: unset;
          transform: translateX(-105%) translateY(-16px);

          .dln-tooltip__content {
            .dln-tooltip__text {
              p {
                font-size: REM(14px);
                &.title {
                  font-weight: bold;
                }
              }
            }
          }

          .dln-tooltip__arrow {
            height: 10px;
            width: 10px;
            box-shadow: 4px -4px 7px 0px rgba(0, 0, 0, .1);
            background: white;
            transform: rotate(45deg) translate(-4px, 4px);
            margin: unset;
            border: 0;
          }
        }

        &__anchor {
          i {
            &.--icon {
              @include del-font-demi;
              background-color: $del-color-dark-blue;
              color: $del-color-white;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 13px;
              width: 18px;
              height: 18px;
              border-radius: 100%;
              position: relative;
              padding-top: 2px;
            }
          }
        }
      }
    }
  }
  &__fixed-disclaimer {
    background-color: var(--ui-08);
    padding-block: 40px;

    .del-disclaimer {
      @include del-font-medium;
      font-size: REM(16px);
      color: $del-color-black;
      padding-inline: 30px;
    }
  }
}
